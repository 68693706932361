import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/theme.ts";
import { Box, Divider } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useAudits } from "./useAudits.tsx";
import { getAuditsGridColumns } from "./helpers/getAuditsGridColumns.tsx";
import useColumnVisibility from "src/utils/grid/useColumnVisibility.ts";
import CreateAudit from "./CreateAudit.tsx";
import Search from "./Search.tsx";

const Audit = () => {
  useEffect(() => {
    document.title = "Charge Guard | Audits";

    const downloadElement = document.getElementById("downloadDisable");
    if (downloadElement) {
      downloadElement.style.visibility = "hidden";
    }
  }, []);

  const {
    data,
    paginationModel,
    loading,
    callbacks,
    total,
    setSearchQuery,
    triggerFetchAudits,
  } = useAudits();

  const columns = getAuditsGridColumns();

  const { columnVisibilityModel, handleColumnVisibilityChange } =
    useColumnVisibility("audits-grid");

  return (
    <ThemeProvider theme={theme}>
      <Box bgcolor="#fff" p={3}>
        <CreateAudit onAuditCreated={triggerFetchAudits} />

        <Divider />

        <Box pt={4}>
          <Search
            onSearch={(query) => {
              setSearchQuery(query);
            }}
          />
        </Box>

        <Box pt={2}>
          <DataGridPro
            {...callbacks}
            rows={data}
            getRowId={(row) => row.requestId}
            columns={columns}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            pageSizeOptions={[5, 10, 50, 100]}
            loading={loading}
            rowHeight={70}
            autoHeight
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            rowCount={total}
            rowSelection={false}
            disableColumnFilter
            disableColumnSorting
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Audit;
