import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState, type FC } from "react";
import { getOssrReports, ossrStatuses, ossrStatusMapping, type OssrQuery, type OssrResult, type OssrStatus } from "src/queries/ossr";
import useColumnVisibility from "src/utils/grid/useColumnVisibility";

import Box from "@mui/material/Box";
import { DataGridPro, useGridApiRef, type GridCellParams, type GridColDef, type GridSortModel } from "@mui/x-data-grid-pro";
import { OssrDeleteButton } from "./OssrDeleteButton";
import { OssrDownloadCell } from "./OssrDownloadCell";

import css from "./OssrTable.module.css";

const OssrStatusDisplay: FC<GridCellParams<OssrQuery>> = ({ row }) => {
  const statusDisplay = ossrStatusMapping[row.STATUS_ID];
  const text = statusDisplay?.text ?? "Unknown";
  const color = statusDisplay?.color ?? "#808080";

  return (
    <Box display="flex" alignItems="center">
      {!!color && (
        <Box
          width="12px"
          minWidth="12px"
          height="12px"
          bgcolor={color}
          borderRadius="2px"
        />
      )}
      <Box ml={1}>{text}</Box>
    </Box>
  );
};

const columns = [
  {
    field: "STORE_NAME",
    headerName: "Store",
    flex: 3,
  },
  {
    field: "STATUS",
    headerName: "Status",
    type: "singleSelect",
    valueOptions: ossrStatuses,
    width: 120,
    valueGetter: (status: OssrStatus) => ossrStatusMapping[status.ID]?.text ?? "Unknown",
    renderCell: OssrStatusDisplay,
  },
  {
    field: "COMPLETION_DATE",
    headerName: "Completion Date",
    width: 120,
    type: "date",
    valueGetter: (date: string) => date && new Date(date),
    valueFormatter: (date: Date | null) => date && moment(date).format("YYYY-MM-DD"),
  },
  {
    field: "RESULT",
    headerName: "Notes",
    flex: 1,
    valueGetter: (result: OssrResult) => result?.MESSAGE,
  },
  {
    field: "RESULT_2",
    headerName: "Files",
    width: 130,
    display: "flex",
    filterable: false,
    renderCell: OssrDownloadCell,
  },
  {
    field: "Actions",
    headerName: "Actions",
    type: "actions",
    width: 80,
    getActions: () => [], // handled by renderCell instead
    renderCell: OssrDeleteButton,
  },
] satisfies GridColDef<OssrQuery>[];

export const OssrTable: FC = () => {
  const query = useQuery({ queryFn: getOssrReports, queryKey: ["ossr"], staleTime: 0 });
  const rows = query.data?.data ?? [];
  const apiRef = useGridApiRef();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "QUERY_ID", sort: "desc" }]); // hide ID column by default. TODO: make it work with useColumnVisibility
  const { columnVisibilityModel, handleColumnVisibilityChange } = useColumnVisibility("ossr-grid");

  return (
    <Box {...query.isRefetching && { inert: "", style: { opacity: 0.5 } }}>
      <DataGridPro
        className={css.ossrTable}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        getRowId={row => row.QUERY_ID}
        filterMode="client"
        sortingMode="client"
        pagination
        disableRowSelectionOnClick
        loading={query.isPending}
        autoHeight
        headerFilters
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        pageSizeOptions={[25, 50, 100]}
        columnHeaderHeight={44}
      />
    </Box>
  );
};
