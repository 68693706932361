import Services from "../ServicesRoutes";
import { http } from "../axios";


const exportInvoiceBackups = () => {
    return http.get(Services.base + Services.version + Services.invoice_auto + "/" + 'export', {responseType: 'blob'})
}

const InvoicingAutomationServices = {
    exportInvoiceBackups
};

export default InvoicingAutomationServices;
