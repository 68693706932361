import { useState, useEffect } from "react";
import UsageHistoryTable from "./UsageHistoryTable";
import PaginationButtons from "../../../components/Pagination/PaginationButtons";
import MDTAnalytics from "./MDTAnalytics";
import Search from "../../../components/Search/Search";
import TableLoader from "../../../components/Loader/TableLoader";
import TotalServices from "../../../TotalServices";
import ViewModal from "./ViewModal";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import Validations from "../../../Regex";
import { toast } from "react-hot-toast";

const UsageHistory = () => {
  useEffect(() => {
    document.title = "Charge Guard | Usage History";
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [loader, setLoader] = useState(true);
  // Pagination
  const [totalRecords, setTotalRecords] = useState();
  const [searchRecord, setSearchRecord] = useState(0);
  const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [chartsData, setchartsData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("");

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
    // setCurrentTabPage(1);
    // setCurrentSmartTabPage(1);
  };
  const handleShowInfoModal = (id) => {
    setShowInfoModal(true);

    setSelectedId(id);
  };

  const GetUsageHistoryData = async () => {
    if (!Validations.isNumber(searchQuery)) {
      toast.error("Please enter numbers only");
    } else {
      try {
        setLoader(true);
        let response = await TotalServices.GetMDTAnalyticsTableData(
          NumberOfRecordsPerPage,
          (currentPage - 1) * NumberOfRecordsPerPage,

          { query: searchQuery }
        );
        if (response.status === 200) {
          // console.log(response);

          const Data = response.data.Automatic_pipeline.map((obj) => {
            const TimeStamp = obj[0].toString();
            if (TimeStamp.length > 5) {
              const milliseconds = TimeStamp * 1000;
              const dateObject = new Date(milliseconds);

              // Extract the date components
              const month = dateObject.getMonth() + 1; // Add 1 because getMonth() returns values from 0 to 11
              const day = dateObject.getDate();
              const year = dateObject.getFullYear();

              // Format the date in US format (MM/DD/YYYY)
              const date = `${year.toString().padStart(2, "0")}-${month
                .toString()
                .padStart(2, "0")}-${day}`;

              
              return {
                id: obj[0],
                date: date.split(", ")[0].split(":").join("/"),
                total: obj[1],
                failed: obj[2],
                completed: obj[3],
              };
            } else {
              return {
                id: obj[0],
                date: TimeStamp,
                total: obj[1],
                failed: obj[2],
                completed: obj[3],
              };
            }
          });

          setTableData(Data);

          setTotalPages(response?.data?.pages);
          setTotalRecords(response?.data?.total_records);
          setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage);

          setGoto("");

          setLoader(false);
        }
      } catch (e) {
      }
    }
  };

  useEffect(() => {
    GetUsageHistoryData();
  }, [currentPage]);

  return (
    <div className="container border p-3">
      <MDTAnalytics setchartsData={setchartsData}></MDTAnalytics>
      <div className="w-90 justify-content-around mt-5 table_main">
        <h5 className="w-90 text-md-start mt-1">
          <strong>Automatic Pipeline Status</strong>{" "}
        </h5>

        {!loader ? (
          <>
            {tableData.length >= 1 ? (
              <>
                <UsageHistoryTable
                  tableData={tableData}
                  handleShowInfoModal={handleShowInfoModal}
                  GetUsageHistoryData={GetUsageHistoryData}
                />
                <PaginationButtons
                  totalRecords={totalRecords}
                  setRecord={setSearchRecord}
                  record={searchRecord}
                  NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setGoto={setGoto}
                  goto={goto}
                  totalPages={totalPages}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </>
        ) : (
          <TableLoader />
        )}
        {showInfoModal ? (
          <ViewModal
            showInfoModal={showInfoModal}
            GetUsageHistoryData={GetUsageHistoryData}
            setShowInfoModal={setShowInfoModal}
            handleCloseInfoModal={handleCloseInfoModal}
            selectedId={selectedId}
            activeTabKey={activeTabKey}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UsageHistory;
