import type {
  CommandCenterResponse,
  CommandCenterGridRow,
  CommandCenterDataChild,
} from "src/types/work-packets";

export const mapCommandCenterData = (
  response: CommandCenterResponse
): CommandCenterGridRow[] => {
  const rows: CommandCenterGridRow[] = [];
  let idCounter = 0;

  const mapCategories = (
    categories: CommandCenterDataChild,
    hierarchy: string[] = []
  ) => {
    Object.entries(categories).forEach(([key, category]) => {
      const newHierarchy = [...hierarchy, key];

      rows.push({
        hierarchy: newHierarchy,
        openCount: category.count,
        openAmount: category.total_amount,
        redFlags: category.red_flag_count,
        id: idCounter++,
      });
    });
  };

  Object.entries(response.data).forEach(([key, val]) => {
    // Add top-level category row data
    rows.push({
      hierarchy: [key],
      openCount: val.count,
      openAmount: val.total_amount,
      redFlags: val.red_flag_count,
      id: idCounter++,
    });

    if (val.child) {
      mapCategories(val.child, [key]);
    }
  });

  return rows;
};
