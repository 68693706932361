const TableLoader = () => {
    return ( 
      <div className="table-container"> 
        <table className="table table-loader" style={{ overflow: "hidden" }}>
        <thead className="thead-dark">
          <tr>
            <th scope="col">Loading</th>
            <th scope="col">Loading</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "100%" }}>
              <span></span>
            </td>
            <td style={{ width: "100%" }}>
              <span></span>
            </td>
          </tr>
          <tr>
            <td style={{ width: "100%" }}>
              <span></span>
            </td>
            <td style={{ width: "100%" }}>
              <span></span>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
     );
}
 
export default TableLoader;