import { TextField } from "@mui/material";
import { Field } from "formik";
import { type FC } from "react";
import { FormFieldsGroup } from "../components";

export const DisputeInformation: FC = () => (
  <FormFieldsGroup
    title="Dispute Information"
    withDivider
    id="dispute-information"
  >
    <Field
      name="dispute-id"
      as={TextField}
      variant="outlined"
      placeholder="Dispute ID"
      size="small"
      label="Dispute ID"
      disabled />

    <Field
      name="vc-parent-id"
      as={TextField}
      variant="outlined"
      placeholder="VC Parent Invoice ID"
      size="small"
      label="VC Parent Invoice ID"
      disabled />

    <Field
      name="vc-disputed-invoice-id"
      as={TextField}
      variant="outlined"
      placeholder="VC Disputed Invoice ID"
      size="small"
      label="VC Disputed Invoice ID"
      disabled />

    <Field
      name="invoice-date"
      as={TextField}
      variant="outlined"
      placeholder="Invoice Date"
      size="small"
      label="Invoice Date"
      disabled />

    <Field
      name="invoice-due-date"
      as={TextField}
      variant="outlined"
      placeholder="Invoice Due Date"
      size="small"
      label="Invoice Due Date"
      disabled />

    <Field
      name="shortage-lag"
      as={TextField}
      variant="outlined"
      placeholder="Shortage Lag"
      size="small"
      label="Shortage Lag"
      disabled />

    <Field
      name="dispute-by-date"
      as={TextField}
      variant="outlined"
      placeholder="Dispute by Date"
      size="small"
      label="Dispute by Date"
      disabled />

    <Field
      name="dispute-amount"
      as={TextField}
      variant="outlined"
      placeholder="Dispute Amount"
      size="small"
      label="Dispute Amount"
      disabled />

    <Field
      name="dispute-approved-amount"
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Approved Amount"
      size="small"
      label="Dispute - Approved Amount"
      disabled />

    <Field
      name="dispute-created-at"
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Created At"
      size="small"
      label="Dispute - Created At"
      disabled />

    <Field
      name="dispute-resolved-at"
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Resolved At"
      size="small"
      label="Dispute - Resolved At"
      disabled />

    <Field
      name="dispute-payment-id"
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Payment ID"
      size="small"
      label="Dispute - Payment ID"
      disabled />

    <Field
      name="dispute-payment-amount"
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Payment Amount"
      size="small"
      label="Dispute - Payment Amount"
      disabled />

    <Field
      name="dispute-payment-date"
      as={TextField}
      variant="outlined"
      placeholder="Dispute - Payment Date"
      size="small"
      label="Dispute - Payment Date"
      disabled />
  </FormFieldsGroup>
);
