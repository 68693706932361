import { useState, useEffect } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

//Components

import PaginationButtons from "../../../../components/Pagination/PaginationButtons";

import TotalServices from "../../../../TotalServices";
import PhraseTable from "./PhraseTable";
import PhraseModal from "./PhraseModal";
import TableLoader from "../../../../components/Loader/TableLoader";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import Search from "../../../../components/Search/Search";

const ManagePhrase = () => {
  useEffect(() => {
    document.title = "Charge Guard | Manage Phrase";
  }, []);


  const [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  // Pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchRecord, setSearchRecord] = useState(0);
  const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] =
    useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(3);
  const handleShowAddModal = () => setShowAddModal(true);

  
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [regionData, setRegionData] = useState([]);
  const [regionSelected, setRegionSelected] = useState("US");
  const [tableData,setTableData]=useState([])
  //import file state
  //add states




  const handleCloseAddModal = () => setShowAddModal(false);

  const getRegions = async() => {
    try{
        setLoader(true)
        let response=await TotalServices.GetRegion()
    if(response.status===200){
     
    //  console.log(response,"region");
     setRegionData(response.data.data);
          setLoader(false)
    }
    }catch(e){
    }
        
        
    
  };


  const getPhrases= async() => {
   
    try{
        setLoader(true)
        let response=await TotalServices.ListPhrases(NumberOfRecordsPerPage, (currentPage - 1) * NumberOfRecordsPerPage,regionSelected,
        
        {query:searchQuery})
    if(response.status===200){
     
    //  console.log(response);
       setTableData(response.data.data)
          setTotalPages(response?.data?.pages);
          setTotalRecords(response?.data?.total_records);
          setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage)
        
          setGoto("")
          setLoader(false)
    }
    }catch(e){
    }
          };
useEffect(()=>{
    getRegions()
},[])
useEffect(()=>{
    getPhrases()
},[currentPage,NumberOfRecordsPerPage,regionSelected])

 







  return (
    <div className="container">
      <div className="mb-3">
    <Search  setSearchQuery={setSearchQuery} searchQuery={searchQuery} currentPage={currentPage} setCurrentPage={setCurrentPage} GetData={getPhrases} placeholder="Search with issue type"/>

      <br></br>

      <div className=" ">
        <div className="row ">
          <div className="col-md-2 col-4">
            <select
              className="form-select form-select "
              aria-label=".form-select-lg example"
              value={regionSelected}
              onChange={(e) => {
                setRegionSelected(e.target.value);
              }}
            >
              <option value="isMissing">Is Missing</option>
              {regionData &&
                regionData.map((obj) => {
                  return <option value={obj}>{obj}</option>;
                })}
            </select>
          </div>
          {/* <ImpExpModals
              getData={getData}
              // setShowInputModal={setShowInputModal}
              // importEndPoint="/ImportEvents"
              // exportEndPoint="/ExportEvents"
            /> */}
          <div className="col d-flex justify-content-end">
            <button
              className="btn btn-dark  justify-content-end "
              onClick={() => {
                handleShowAddModal();
              }}
            >
              Add Dispute Phrase
            </button>
          </div>
        </div>
      </div>
      </div>
      <div className="container-sm table_main">
        {!loader ? (
          <>
             {tableData.length>=1 ? (
              <>
           <PhraseTable data={tableData} regionData={regionData} getData={getPhrases}/>
         
            <div className="container ">
              <div className="row">
                <div className="col">
                  <PaginationButtons
                    totalRecords={totalRecords}
                    setRecord={setSearchRecord}
                    record={searchRecord}
                    NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setGoto={setGoto}
                    goto={goto}
                    totalPages={totalPages}
                  />
                </div>

                <div className="col-2">
                  <select
                    className="form-select form-select "
                    aria-label=".form-select-lg example"
                    value={NumberOfRecordsPerPage}
                    onChange={(e) => {
                        setNumberOfRecordsPerPage(e.target.value);
                    }}
                  >
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          </>
     
         ) : 
         <NoDataFound />
         }
       </>
     ) : 
     <TableLoader  />}
      </div>

      {/* Add modal */}

    {showAddModal && <PhraseModal showAddModal={showAddModal} handleCloseAddModal={handleCloseAddModal} region={regionSelected} getData={getPhrases}/>}

   
   
    </div>
  );
};

export default ManagePhrase;
