import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { Form, Formik, type FormikProps } from "formik";
import { type FC } from "react";
import toast from "react-hot-toast";
import { type ChargebackWorkPacket, RecoveryStream, type ShortageWorkPacket, type WorkPacket, WorkPacketFilter } from "src/types/work-packets";
import * as WorkPacketsAPI from "../../api/workPacketsAPI";
import { useWorkPacketsContext } from "../../WorkPacketsContext";
import { WorkPacketType } from "../../WorkPacketType";
import { FormSection, SummaryNavigation } from "./components";

import * as ChargebackSections from "./chargeback-sections";
import * as ShortageSections from "./shortage-sections";

interface SummaryProps {
  workPacket: WorkPacket;
  formRef: any;
  onSubmit: () => void;
  setLoading: (loading: boolean) => void;
}

interface UpdateValues {
  currentAction?: string;
  currentPacketOwner?: string;
  notes?: string;
  isValidWorkPacket?: boolean;
}

const getChargebackInitialValues = (packet: ChargebackWorkPacket) => ({
  /* General packet info - Customer Reference */
  "vendor-name": packet.vendorName,
  "vendor-id": packet.vendorId,
  "vendor-code": packet.vendorCode,
  marketplace: "Amazon",
  "store-name": packet.storeName,
  "store-id": packet.storeId,

  /* General packet info - Work Packet info */
  "packet-id": packet.packetId,
  "packet-date": dayjs(packet.packetDate),
  "last-modified-by": packet.lastModifiedBy.title,
  "modified-date": packet.modifiedAt,
  "closed-date": "",
  "vc-po-id": packet.vcPoId,
  [WorkPacketFilter.CurrentAction]: packet.currentAction?.value || "",
  [WorkPacketFilter.RecoveryStream]: packet.recoveryStreamServer,
  "recovery-stream-activation-date": dayjs(packet.recoveryStreamActivationDate),
  "recovery-stream-subtype": packet.recoveryStreamSubtype,
  "vc-filing-user": packet.vcFillingUser,
  [WorkPacketFilter.CurrentPacketOwner]: packet.currentPacketOwner.id,
  "tech-user": packet.techUser,
  isValidWorkPacket: packet.isValidWorkPacket,
  notes: packet.notes,

  /* Packet details - Work Packet info */
  chargebackIssueId: packet.chargebackIssueId,

  /* Packet details - First Dispute */
  "first-dispute-financial-charge": packet.firstDisputeFinancialCharge,
  "first-dispute-chargeback-issue-date": "",

  /* Packet details - Second Dispute */
  "second-dispute-financial-charge": packet.firstDisputeFinancialCharge,
  "second-dispute-chargeback-issue-date": "",
});

const getShortageInitialValues = (packet: ShortageWorkPacket) => ({
  /* Customer Reference */
  "vendor-name": packet.vendorName,
  "vendor-id": packet.vendorId,
  "store-name": packet.storeName,
  "store-id": packet.storeId,
  "marketplace-country": packet.marketplaceCountry,

  /* Work Packet info */
  "packet-id": packet.packetId,
  "packet-date": dayjs(packet.packetDate),
  "closed-date": packet.closedDate,
  "packet-stage": packet.packetStage,
  [WorkPacketFilter.CurrentAction]: packet.currentAction?.value || "",
  [WorkPacketFilter.RecoveryStream]: packet.recoveryStreamServer,
  "recovery-stream-activation-date": dayjs(
    packet.recoveryStreamActivationDate
  ),

  /* Omitted for now - will be implemented later */
  // "vc-po-id": "[missing value]",
  // "vc-vendor-id": "[missing value]",

  "vc-payee-code": packet.payeeCode,
  "manual-filling-user": packet.manualFilingUser,
  [WorkPacketFilter.CurrentPacketOwner]: packet.currentPacketOwner.id,
  "last-modified-by": packet.lastModifiedBy.title,
  "modified-at": packet.modifiedAt,
  isValidWorkPacket: packet.isValidWorkPacket,
  notes: packet.notes,

  /* Dispute information */
  "dispute-id": packet.disputeId,
  "vc-parent-id": packet.vcParentInvoiceId,
  "vc-disputed-invoice-id": packet.vcDisputedInvoiceId,
  "invoice-date": packet.invoiceDate,
  "invoice-due-date": packet.invoiceDueDate,
  "shortage-lag": packet.shortageLag,
  "dispute-by-date": packet.disputeByDate,
  "dispute-amount": packet.disputeAmount,
  "dispute-approved-amount": packet.disputeApprovedAmount,
  "dispute-created-at": packet.disputeCreatedAt,
  "dispute-resolved-at": packet.disputeResolvedAt,
  "dispute-payment-id": packet.disputePaymentId,
  "dispute-payment-amount": packet.disputePaymentAmount,
  "dispute-payment-date": packet.disputePaymentDate,

  /* Invoice Information - omitted for now, will be added in the future */
  // "cg-invoice-id-for-payment": "[missing value]",
  // "date-submitted-to-cg-invoicing": "[missing value]",
  // "cg-invoice-paid-date": "[missing value]",
});

type FormValues = ReturnType<typeof getChargebackInitialValues> | ReturnType<typeof getShortageInitialValues>;

type WorkPacketForm = FC<FormikProps<FormValues>>;

const ChargebackWorkPacketForm: WorkPacketForm = ({ setFieldValue }) => {
  const { currentView } = useWorkPacketsContext();
  return (
    <Form>
      <FormSection title="General Packet Info" id="general-packet-info">
        <ChargebackSections.CustomerReference />
        <ChargebackSections.WorkPacketInfo setFieldValue={setFieldValue} view={currentView} />
      </FormSection>

      <FormSection title="Packet details" id="packet-details">
        <ChargebackSections.DetailsWorkPacketInfo />
        <ChargebackSections.DetailsFirstDispute />
        <ChargebackSections.DetailsSecondDispute />
      </FormSection>
    </Form>
  );
};

const ShortageWorkPacketForm: WorkPacketForm = ({ setFieldValue }) => {
  const { currentView } = useWorkPacketsContext();
  return (
    <Form>
    <FormSection title="General Packet Info" id="general-packet-info">
      <ShortageSections.CustomerReference />
      <ShortageSections.WorkPacketInfo setFieldValue={setFieldValue} view={currentView} />
    </FormSection>

    <FormSection title="Packet details" id="packet-details">
      <ShortageSections.DisputeInformation />
      {/* Omitted for now - will be added in the future */}
      {/* <ShortageSections.InvoiceInformation /> */}
    </FormSection>
  </Form>
  );
};

const WorkPacketFormMap: Record<WorkPacketType, WorkPacketForm> = {
  [WorkPacketType.CHARGEBACKS]: ChargebackWorkPacketForm,
  [WorkPacketType.SHORTAGES]: ShortageWorkPacketForm,
};

export const Summary: FC<SummaryProps> = ({
  workPacket,
  formRef,
  onSubmit,
  setLoading,
}) => {
  const { user } = useAuth0();
  const { currentWorkPacketType } = useWorkPacketsContext();

  const initialValues: FormValues =
    workPacket.recoveryStream === RecoveryStream.Chargebacks
      ? getChargebackInitialValues(workPacket)
      : getShortageInitialValues(workPacket);

  const handleSubmit = async (values: FormValues) => {
    try {
      if (!user?.sub) return;

      setLoading(true);

      const updateValues: UpdateValues = {
        currentAction: values.currentAction,
        currentPacketOwner: values.currentPacketOwner,
        notes: values.notes,
        isValidWorkPacket: values.isValidWorkPacket,
      };

      // Include only the fields that have been updated
      const updatedFields = (
        Object.keys(updateValues) as (keyof UpdateValues)[]
      ).reduce<UpdateValues>((acc, key) => {
        if (
          updateValues[key] !== initialValues[key as keyof FormValues]
        ) {
          acc[key] = updateValues[key] as any;
        }
        return acc;
      }, {});

      if (Object.keys(updatedFields).length === 0) {
        console.log("No fields have been updated");
        toast.success("No fields have been updated");
        return;
      }

      await WorkPacketsAPI.updateWorkPacket({
        workPacketType: currentWorkPacketType,
        workPacketId: workPacket.packetId,
        userId: user.sub,
        updatedFlatRow: values,
        changedFields: Object.keys(updatedFields),
      });

      toast.success("Work packet updated successfully");
      onSubmit();
    } catch (error) {
      console.error("Error in handleSubmit: ", error);
      toast.error("Error updating work packet");
    } finally {
      setLoading(false);
    }
  };

  const FormikChild = WorkPacketFormMap[currentWorkPacketType];

  return (
    <Box display="flex" paddingTop={3} gap={3}>
      <Box flex={1}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formRef}
          children={FormikChild}
        />
      </Box>
      <SummaryNavigation workPacketType={currentWorkPacketType} />
    </Box>
  );
};
