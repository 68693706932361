import { useState, useEffect } from "react";
import CustomerNewServices from "../../../Services/CustomerV1Services.js";
import CustomersGrid from "./CustomersGrid/index.tsx";
import { getReferralData } from "./Api/Shared/SharedApi.js";
import CreateVendor from "./components/CreateVendor/CreateVendor.jsx";
import { exportVendors } from "./Api/Vendors/Vendors.js";
import usePermissions from "../../../components/CustomHooks/usePermissions.js";
import { Box, Button, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/theme.ts";
import { Add, Remove } from "@mui/icons-material";

const Customers = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [referralAll, setReferralAll] = useState([]);
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const { hasPermission } = usePermissions();

  const fetchCustomers = async () => {
    try {
      const response = await CustomerNewServices.listCustomers();

      if (response.status !== 200) {
        throw new Error(
          "Error while fetching customers. Response status is not 200."
        );
      }

      setData(response?.data);
    } catch (e) {
      console.log(`Error while fetching customers: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchReferrals = async () => {
    await getReferralData(setReferralAll);
  };

  useEffect(() => {
    document.title = "Charge Guard | CUSTOMER";
  }, []);

  useEffect(() => {
    fetchCustomers();
    fetchReferrals();
  }, []);

  const toggleNewCustomerModal = () => {
    setShowCreateCustomerModal((prev) => !prev);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box bgcolor="#fff" p={3}>
        <Box display="flex" gap="12px" pb={3}>
          {hasPermission(["admin:*", "user:client_config:write:*"]) && (
            <Button
              startIcon={showCreateCustomerModal ? <Remove /> : <Add />}
              variant="outlined"
              onClick={toggleNewCustomerModal}
            >
              Add Customer
            </Button>
          )}

          {hasPermission(["admin:*", "user:client_config:*"]) && (
            <Button
              variant="outlined"
              disabled={exportLoading}
              onClick={async () => {
                await exportVendors(setExportLoading);
              }}
            >
              <span>Export all</span>
              {exportLoading && (
                <CircularProgress
                  size={14}
                  style={{ marginLeft: "12px", color: "#101828" }}
                />
              )}
            </Button>
          )}
        </Box>

        {showCreateCustomerModal && <CreateVendor getData={fetchCustomers} />}

        <Box>
          <CustomersGrid
            data={data}
            referralAll={referralAll}
            loading={loading}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Customers;
