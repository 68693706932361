import { FC, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getCustomersGridColumns } from "./helpers/getCustomersGridColumns";
import useColumnVisibility from "src/utils/grid/useColumnVisibility";
import { useNavigate } from "react-router-dom";
import GridToolbar from "./GridToolbar";

interface CustomersGridProps {
  loading?: boolean;

  // TODO: define Customer type
  data: any[];

  // TODO: define Referral type
  referralAll: any[];
}

interface PaginationModel {
  page: number;
  pageSize: number;
}

const CustomersGrid: FC<CustomersGridProps> = ({
  data,
  referralAll,
  loading,
}) => {
  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const handlePaginationModelChange = useCallback(
    (model: { page: number; pageSize: number }) => {
      setPaginationModel(model);
    },
    []
  );

  const columns = getCustomersGridColumns({
    onUpdateClick: (customer) => {
      navigate("/user-dashboard/vendors?vendor=" + customer.VENDOR_ID, {
        state: { vendorTableData: customer, referralAll: referralAll },
      });
    },
  });

  const { columnVisibilityModel, handleColumnVisibilityChange } =
    useColumnVisibility("customers-grid");

  const dataWithId = data.map((item, index) => {
    return { id: index, ...item };
  });

  return (
    <Box>
      <DataGridPro
        rows={dataWithId}
        columns={columns}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[5, 10, 25, 100]}
        loading={loading}
        rowHeight={70}
        autoHeight
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        headerFilters
        rowSelection={false}
        slots={{ toolbar: GridToolbar }}
        sx={{
          borderColor: "transparent",
          "& .MuiDataGrid-main": {
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
          },
          "& .MuiDataGrid-columnHeader": {
            borderRight: "1px solid #e0e0e0",

            ".MuiButtonBase-root": {
              marginTop: "0",
              marginBottom: "0",
            },
          },
          "& .MuiFormControl-root": {
            label: {
              display: "none",
            },
            "& .MuiInputBase-root": {
              marginTop: "0",
            },
          },
        }}
      />
    </Box>
  );
};

export default CustomersGrid;
