import { useField } from "formik";
import { Label } from "./Label";
import MaxCharacterCount from "./MaxCharacterCount";
import { useState } from "react";

export const FormikTextArea = ({
  label,
  mandatory = false,
  maxNotesCharacters = 500,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [characterCount, setCharacterCount] = useState(
    field.value?.length ?? 0
  );

  const handleChange = (event) => {
    const { value } = event.target;
    setCharacterCount(value.length);
    field.onChange(event); // Call the onChange method from Formik's useField
  };

  return (
    <>
      <Label label={label} mandatory={mandatory} />
      <textarea
        className={`w-full form-control outline-none p-2 border rounded-xl sm:text-md my-1 disabled:text-gray-500 ${
          props.classes ? props.classes : ""
        } ${meta.touched && meta.error ? "is-invalid" : ""} `}
        {...field}
        {...props}
        onChange={handleChange}
        maxLength={maxNotesCharacters}
      />
      {meta.touched && meta.error && (
        <div className="text-red">{meta.error}</div>
      )}
      <MaxCharacterCount
        characterCount={characterCount}
        maxNotesCharacters={maxNotesCharacters}
      />
    </>
  );
};
