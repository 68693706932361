import React, { useEffect, useState } from "react";
import { Tab, Tabs, Table } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { FaCloudDownloadAlt } from "react-icons/fa";
import PaginationButtons from "../../../components/Pagination/PaginationButtons";
import TotalServices from "../../../TotalServices";
import TableLoader from "./TableLoader";
import ModalTabTable from "./ModalTabTable";
import useUsageHistory from "../../../components/CustomHooks/useUsageHistory";
import Search from "../../../components/Search/Search";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

const ViewModal = ({
  showInfoModal,
  handleCloseInfoModal,
  selectedId,
}) => {
  const [activeTabKey, setActiveTabKey] = useState("regular_cases");

  const [searchQuery, setSearchQuery] = useState("");
  const [cases, setCases] = useState("regular_cases");

  // Pagination
  const [totalRecords, setTotalRecords] = useState();
  const [searchRecord, setSearchRecord] = useState(0);
  const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [loader, setLoader] = useState(true);
  // MDTFilter
  const [tableData, setTableData] = useState([]);

  const handleSelectTab = (key) => {
    setSearchQuery("")
    setCurrentPage(1);
    setCases(key);
    setActiveTabKey(key);
  };

  const GetModalData = async () => {
    try {
      setLoader(true);
      let response = await TotalServices.GetMDTAnalyticsModalTableData(
        selectedId,
        NumberOfRecordsPerPage,
        (currentPage - 1) * NumberOfRecordsPerPage,

        { query: searchQuery, type: cases }
      );
      if (response.status === 200) {
        // console.log(response);

        let tabsData = [];
        response.data.Automatic_pipeline.map((obj) => {
          let date = new Date(obj[0]).toLocaleString();
          return tabsData.push({
            id: obj[0],
            date: date
              .split(", ")[0]
              .split(":")
              .join("/"),
            storeName: obj[1],
            total: obj[2],
            failed: obj[3],
            completed: obj[4],
          });
        });

        setTableData(tabsData);
        setTotalPages(response?.data?.pages);
        setTotalRecords(response?.data?.total_records);
        setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage);
        
        setGoto("")

        setLoader(false);
      }
    } catch (e) {
    }
  };

  const { DownlaodFile } = useUsageHistory();

  useEffect(() => {
    GetModalData();
  }, [currentPage, cases]);

  return (
    <>
      {/* Details Modal starts*/}
      <Modal show={showInfoModal} onHide={handleCloseInfoModal}>
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Tabs defaultActiveKey={activeTabKey} onSelect={handleSelectTab} fill>
            {/* Regular cases table starts here */}
            <Tab eventKey="regular_cases" title="Regular Cases">
              <div class="d-flex justify-content-between align-items-center my-2" >
                <Search
                  setSearchQuery={setSearchQuery}
                  searchQuery={searchQuery}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  GetData={GetModalData}
                  newClass={"w-50"}
                />

                <button
                  className="ms-1 btn btn-success align-items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    DownlaodFile(selectedId, activeTabKey, setLoader , "none");
                    // downloadFile(selectedId, activeTabKey, "none", "all");
                  }}
                >
                  <FaCloudDownloadAlt></FaCloudDownloadAlt> Download
                </button>
              </div>

             

              {!loader ? (
          <>
             {tableData.length>=1 ? (
              <>
             
                  <ModalTabTable
                    setFileLoader={setLoader}
                    activeTabKey={activeTabKey}
                    tableData={tableData}
                    DownlaodFile={DownlaodFile}
                  />
                  <PaginationButtons
                    totalRecords={totalRecords}
                    setRecord={setSearchRecord}
                    record={searchRecord}
                    NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setGoto={setGoto}
                    goto={goto}
                    totalPages={totalPages}
                  />
              </>

) : 
<NoDataFound />
}
</>
) : 
<TableLoader  />}
              <div className="container ">
                <div className="row">
                  <div className="col"></div>
                </div>
              </div>
            </Tab>
            {/* Regular cases table ends here */}

            {/* smart aggregation table starts here */}
            <Tab eventKey="smart-aggregation" title="Smart Aggregation">
            <div class="d-flex justify-content-between align-items-center my-2">
                <Search
                  setSearchQuery={setSearchQuery}
                  searchQuery={searchQuery}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  GetData={GetModalData}
                  newClass={"w-50"}
                />

                <button
                  className="ms-1 btn btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    DownlaodFile(selectedId, activeTabKey, setLoader , "none");
                    // downloadFile(selectedId, activeTabKey, "none", "all");
                  }}
                >
                  <FaCloudDownloadAlt></FaCloudDownloadAlt> Download
                </button>
              </div>
              {!loader ? (
          <>
             {tableData.length>=1 ? (
              <>
                  <ModalTabTable
                  setFileLoader={setLoader}
                    tableData={tableData}
                    activeTabKey={activeTabKey}
                    DownlaodFile={DownlaodFile}
                  />
                  <PaginationButtons
                    totalRecords={totalRecords}
                    setRecord={setSearchRecord}
                    record={searchRecord}
                    NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setGoto={setGoto}
                    goto={goto}
                    totalPages={totalPages}
                  />
                </>

            ) : 
         <NoDataFound />
         }
       </>
     ) : 
     <TableLoader  />}
              <div className="container ">
                <div className="row">
                  <div className="col">
                    <div className="container ">
                      <div className="row">
                        <div className="col"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            {/* Regular aggregation table ends here */}
          </Tabs>
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={{
              color: "white",
              backgroundColor: "#f54242",
              border: "none",
            }}
            onClick={handleCloseInfoModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Details Modal ends here*/}
    </>
  );
};

export default ViewModal;
