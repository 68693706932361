import { TextField } from "@mui/material";
import { Field } from "formik";
import { type FC } from "react";
import { FormFieldsGroup } from "../components";

export const CustomerReference: FC = () => (
  <FormFieldsGroup
    title="Customer Reference"
    withDivider
    id="customer-reference"
  >
    <Field
      name="vendor-name"
      as={TextField}
      variant="outlined"
      placeholder="Vendor Name"
      size="small"
      label="Vendor Name"
      disabled />

    <Field
      name="vendor-id"
      as={TextField}
      variant="outlined"
      placeholder="Enter Vendor ID"
      size="small"
      label="Vendor ID"
      disabled />

    <Field
      name="vendor-code"
      as={TextField}
      variant="outlined"
      placeholder="Enter Vendor Code"
      size="small"
      label="Vendor Code"
      disabled />

    <Field
      name="marketplace"
      as={TextField}
      variant="outlined"
      placeholder="Enter Marketplace"
      size="small"
      label="Marketplace"
      disabled />

    <Field
      name="store-name"
      as={TextField}
      variant="outlined"
      placeholder="Enter Store Name"
      size="small"
      label="Store Name"
      disabled />

    <Field
      name="store-id"
      as={TextField}
      variant="outlined"
      placeholder="Enter Store ID"
      size="small"
      label="Store ID"
      disabled />
  </FormFieldsGroup>
);
