import { Box, Typography } from "@mui/material";

export const FormSection = ({
  title,
  children,
  id,
}: {
  title: string;
  children: React.ReactNode;
  id: string;
}) => {
  return (
    <Box
      id={id}
      boxShadow="0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)"
      borderRadius={2}
      overflow="hidden"
      marginBottom={4}
    >
      <Box bgcolor="#101828">
        <Typography
          variant="h6"
          color="#fff"
          padding="12px 24px"
          textTransform="uppercase"
          fontSize="14px"
        >
          {title}
        </Typography>
      </Box>
      <Box padding="0 32px 32px 32px">{children}</Box>
    </Box>
  );
};
