import { Box, Typography, Divider } from "@mui/material";

export const FormFieldsGroup = ({
  title,
  withDivider,
  children,
  id,
}: {
  title: string;
  withDivider?: boolean;
  children: React.ReactNode;
  id: string;
}) => {
  return (
    <Box id={id}>
      <Box paddingBottom={2} paddingTop={3}>
        <Typography variant="subtitle1" color="#F7104D">
          {title}
        </Typography>
      </Box>
      <Box
        display="grid"
        rowGap={3}
        columnGap={2}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          },
        }}
      >
        {children}
      </Box>

      {withDivider && <Divider sx={{ mt: 4 }} />}
    </Box>
  );
};
