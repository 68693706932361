import { useState, useEffect, useCallback } from "react";

/**
 * Hook to manage column visibility state and persist it to local storage for different tables.
 * @param tableKey The key to identify the specific table's column visibility settings.
 */
const useColumnVisibility = (tableKey: string) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<
    Record<string, boolean>
  >(getColumnVisibilityFromLocalStorage(tableKey));

  useEffect(() => {
    const storedVisibility = getColumnVisibilityFromLocalStorage(tableKey);
    if (storedVisibility) {
      setColumnVisibilityModel(storedVisibility);
    }
  }, [tableKey]);

  const handleColumnVisibilityChange = useCallback(
    (newModel: Record<string, boolean>) => {
      setColumnVisibilityModel(newModel);
      saveColumnVisibilityToLocalStorage(tableKey, newModel);
    },
    [tableKey]
  );

  return {
    columnVisibilityModel,
    handleColumnVisibilityChange,
  };
};

const getColumnVisibilityFromLocalStorage = (
  tableKey: string
): Record<string, boolean> => {
  const storedVisibility = localStorage.getItem(`${tableKey}-columnVisibility`);
  return storedVisibility ? JSON.parse(storedVisibility) : {};
};

const saveColumnVisibilityToLocalStorage = (
  tableKey: string,
  visibility: Record<string, boolean>
): void => {
  // TODO: Consider adding user id to keep track of visibility settings per user
  localStorage.setItem(
    `${tableKey}-columnVisibility`,
    JSON.stringify(visibility)
  );
};

export default useColumnVisibility;
