const autocomplete = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        width: "100%",
        margin: "auto",
        display: "flex",
      },
      option: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        gap: "8px",
        fontSize: "14px",
        padding: "8px 16px",
        cursor: "pointer",

        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },

        "& .autocomplete-menu-item__avatar": {
          minWidth: "32px",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
        },

        "& .autocomplete-menu-item__text": {
          margin: "auto 0",
        },

        "& .autocomplete-menu-item__color-indicator": {
          alignSelf: "stretch",
          width: "12px",
          minWidth: "12px",
          borderRadius: "2px",
        },
      },
    },
  },
};

export default autocomplete;
