import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { FC } from "react";
import { FieldProps } from "formik";

interface SelectFieldProps extends FieldProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

/**
 * SelectField component integrates Material-UI's Select component with Formik.
 *
 * @param {FieldProps} field - Formik field prop, automatically injected by Formik.
 * @param {label} label - The label for the select field.
 * @param {string} label - The label for the select field.
 * @param {boolean} disabled - Whether the select field is disabled.
 * @param {Array<{ value: string, label: string }>} options - The options for the select dropdown.
 */
const SelectField: FC<SelectFieldProps> = ({
  field,
  label,
  disabled,
  options,
  onChange,
}) => {
  return (
    <FormControl size="small" fullWidth disabled={disabled}>
      <InputLabel id={`${field.name}-label`}>{label}</InputLabel>
      <Select
        variant="outlined"
        {...field}
        label={label}
        labelId={`${field.name}-label`}
        onChange={(event) => {
          field.onChange(event);
          if (onChange) {
            onChange(event.target.value);
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
