import React from "react";

const Badge = ({ text }) => {
  return (
    <span className="badge text-bg-primary">
      {text || "N/A"}
    </span>
  );
};

export default Badge;