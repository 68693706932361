import { GridColDef } from "@mui/x-data-grid";
import { Button, Box } from "@mui/material";
import { getColorByCustomerStatus } from "./getColorByCustomerStatus";
import dayjs from "dayjs";
import {
  accountStatuses,
  cadenceOptions,
  categoryOptions,
  dataPullStatusOptions,
  shortageProcessingOptions,
} from "./getStaticFilterOptions";
import { getColorByCustomerTier } from "./getColorByCustomerTier";

interface GridColumnProps {
  onUpdateClick: (customer: any) => void;
}

export const getCustomersGridColumns = ({
  onUpdateClick,
}: GridColumnProps): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "",
      headerName: "",
      width: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      align: "center",
      renderCell: (params: any) => (
        <Button variant="outlined" onClick={() => onUpdateClick(params.row)}>
          Update
        </Button>
      ),
      cellClassName: () => {
        return "bg-gray-100";
      },
    },
    {
      field: "VENDOR_ID",
      headerName: "Vendor Id",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 150,
      renderCell: (params: any) => `V${params.value}`,
    },
    { field: "VENDOR_NAME", headerName: "Vendor Name", width: 150 },
    {
      field: "ACCOUNT_ID",
      headerName: "Account Id",
      width: 150,
      renderCell: (params: any) => (params.value ? `S${params.value}` : null),
    },
    { field: "NAME", headerName: "Name", type: "string", width: 300 },
    {
      field: "STATUS",
      headerName: "Status",
      width: 150,
      type: "singleSelect",
      valueOptions: accountStatuses,
      renderCell: (params: any) => {
        const color = getColorByCustomerStatus(params.value);
        return (
          <Box display="flex" alignItems="center">
            <Box
              width="12px"
              minWidth="12px"
              height="12px"
              bgcolor={color}
              borderRadius="2px"
            />
            <Box ml={1} sx={{ textTransform: "capitalize" }}>
              {params.value}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "TECH_USER",
      headerName: "Tech User",
      width: 150,
    },
    {
      field: "MANUAL_FILING_USER",
      headerName: "Manual Filing User",
      width: 150,
    },
    {
      field: "BILLING_NAME",
      headerName: "Billing Name",
      width: 150,
    },
    {
      field: "BILLING_EMAIL",
      headerName: "Billing Email",
      width: 150,
    },
    {
      field: "OVERALL_SCOPE",
      headerName: "Overall Scope",
      width: 150,
    },

    {
      field: "ACTIVATION_DATE",
      headerName: "Activation Date",
      type: "date",
      width: 200,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "DEACTIVATION_DATE",
      headerName: "Deactivation Date",
      type: "date",
      width: 200,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "DATA_PULL_STATUS",
      headerName: "Data Pull Status",
      type: "singleSelect",
      width: 150,
      valueOptions: dataPullStatusOptions,
    },
    {
      field: "SHORTAGE_LAG",
      headerName: "Shortage Lag",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "GMV",
      headerName: "GMV",
      type: "number",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    { field: "CUSTOMER_NOTES", headerName: "Customer Notes", width: 250 },
    {
      field: "CLIENT_SUCCESS_MANAGER_NAME",
      headerName: "Client Success Manager Name",
      width: 250,
    },
    {
      field: "CATEGORY",
      headerName: "Category",
      type: "singleSelect",
      valueOptions: categoryOptions,
      width: 150,
    },
    { field: "REFERRAL_PARTNER", headerName: "Referral Partner", width: 150 },
    {
      field: "COMMISSIONED_RECOVERY",
      headerName: "Commissioned Recovery",
      type: "number",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "TIER",
      headerName: "Tier",
      type: "string",
      width: 120,
      renderCell: (params: any) => {
        const color = getColorByCustomerTier(params.value);
        return (
          <Box display="flex" alignItems="center">
            <Box
              width="12px"
              minWidth="12px"
              height="12px"
              bgcolor={color}
              borderRadius="2px"
            />
            <Box ml={1} sx={{ textTransform: "capitalize" }}>
              {params.value}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "POST_THRESHOLD_COMMISSION_RATE",
      headerName: "Post Threshold Commission Rate",
      type: "number",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    { field: "BACKUP_USERS", headerName: "Backup Users", width: 150 },
    {
      field: "CADENCE",
      headerName: "Cadence",
      width: 150,
      type: "singleSelect",
      valueOptions: cadenceOptions,
    },
    {
      field: "SHORTAGE_PROCESSING",
      headerName: "Shortage Processing",
      width: 250,
      type: "singleSelect",
      valueOptions: shortageProcessingOptions,
    },
    {
      field: "CB_PROCESSING_OWNER",
      headerName: "CB Processing Owner",
      width: 200,
    },
    {
      field: "WHITE_LABELED_ACCOUNT",
      headerName: "White Labeled Account",
      width: 200,
    },
    {
      field: "SPECIFIC_POINT_OF_CONTACT_NAME",
      headerName: "Specific Point Of Contact Name",
      width: 250,
    },
    {
      field: "SPECIFIC_POINT_OF_CONTACT_EMAIL",
      headerName: "Specific Point Of Contact Email",
      width: 250,
    },
    {
      field: "SHORTAGE_PROCESSOR",
      headerName: "Shortage Processor",
      width: 250,
    },
    {
      field: "ACCRUALS_ACTIVATION_DATE",
      headerName: "Accruals Activation Date",
      type: "date",
      width: 200,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "ACCRUALS_DEACTIVATION_DATE",
      headerName: "Accruals Deactivation Date",
      type: "date",
      width: 200,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "ACCRUALS_HISTORIC",
      headerName: "Accruals Historic",
      type: "boolean",
      width: 150,
    },
    {
      field: "ACCRUALS_INVOICE_END_DATE",
      headerName: "Accruals Invoice End Date",
      type: "date",
      width: 200,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    { field: "ACCRUALS_NOTES", headerName: "Accruals Notes", width: 250 },
    {
      field: "CHARGEBACKS_ACTIVATION_DATE",
      headerName: "Chargebacks Activation Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "CHARGEBACKS_DEACTIVATION_DATE",
      headerName: "Chargebacks Deactivation Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "CHARGEBACKS_HISTORIC",
      headerName: "Chargebacks Historic",
      type: "boolean",
      width: 150,
    },
    {
      field: "CHARGEBACKS_INVOICE_END_DATE",
      headerName: "Chargebacks Invoice End Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    { field: "CHARGEBACKS_NOTES", headerName: "Chargebacks Notes", width: 250 },
    {
      field: "PRICE_CLAIMS_ACTIVATION_DATE",
      headerName: "Price Claims Activation Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "PRICE_CLAIMS_DEACTIVATION_DATE",
      headerName: "Price Claims Deactivation Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "PRICE_CLAIMS_HISTORIC",
      headerName: "Price Claims Historic",
      type: "boolean",
      width: 150,
    },
    {
      field: "PRICE_CLAIMS_INVOICE_END_DATE",
      headerName: "Price Claims Invoice End Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },

    {
      field: "PRICE_CLAIMS_NOTES",
      headerName: "Price Claims Notes",
      width: 250,
    },
    {
      field: "SHORTAGES_ACTIVATION_DATE",
      headerName: "Shortages Activation Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "SHORTAGES_CLAIMS_DEACTIVATION_DATE",
      headerName: "Shortages Claims Deactivation Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "SHORTAGES_CLAIMS_HISTORIC",
      headerName: "Shortages Claims Historic",
      type: "boolean",
      width: 150,
    },
    {
      field: "SHORTAGES_CLAIMS_INVOICE_END_DATE",
      headerName: "Shortages Claims Invoice End Date",
      type: "date",
      width: 250,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : null;
      },
    },
    {
      field: "SHORTAGES_CLAIMS_NOTES",
      headerName: "Shortages Claims Notes",
      width: 250,
    },
  ];

  return columns;
};
