import { toast } from "react-hot-toast";
import TotalServices from "../../TotalServices";
import moment from "moment";

const useUsageHistory = () => {


  const DownlaodFile = async (id , cases, setFileLoader, store) => {
let date=new Date()
 
    try {
      let response = await TotalServices.ShortageModalCasesDownload(id , cases , store)

      if (response.status === 200) {
        var a = document.createElement("a");
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        a.href = downloadUrl
        let stoername=store==="none"?"":(store+"_");
        let fileName =stoername+ cases + "_" + moment(date).format("YYYY-MM-DD")
        a.setAttribute('download', fileName + ".csv");
        document.body.appendChild(a);

        a.click();
     


      }
    }
    catch (error) {
      toast.error("Can't be able to download file");
    };

  }
  return ({ DownlaodFile })
}

export default useUsageHistory;