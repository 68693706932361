import mockData from "./mock-data-50.json";

export const dynamicOptions = {
  storeName: [
    {
      value: "",
      label: "All",
    },
    {
      value: "Botsford, Jast and Kertzmann",
      label: "Botsford, Jast and Kertzmann",
    },
    { value: "Considine Inc", label: "Considine Inc" },
    {
      value: "Gleichner, Grady and Conroy",
      label: "Gleichner, Grady and Conroy",
    },
    { value: "Grant, White and Schaden", label: "Grant, White and Schaden" },
    {
      value: "Harber, Schuster and Corwin",
      label: "Harber, Schuster and Corwin",
    },
    { value: "Kunze - Stark", label: "Kunze - Stark" },
    //
    { value: "Monahan LLC", label: "Monahan LLC" },
    //
    { value: "Pouros and Sons", label: "Pouros and Sons" },
    //
    { value: "Quigley - Terry", label: "Quigley - Terry" },
    //
    {
      value: "Schimmel, Bode and Rutherford",
      label: "Schimmel, Bode and Rutherford",
    },
  ],
  currentPacketOwner: [
    {
      id: 1,
      title: "Adrian Will",
      avatar:
        "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/626.jpg",
    },
    {
      id: 2,
      title: "Antonia Mayert",
      avatar:
        "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/924.jpg",
    },
    {
      id: 3,
      title: "Carlos Bergstrom",
      avatar: "https://avatars.githubusercontent.com/u/55480023",
    },
    {
      id: 4,
      title: "Gail Hirthe",
      avatar: "https://avatars.githubusercontent.com/u/20121559",
    },
    {
      id: 5,
      title: "Holly Sporer",
      avatar:
        "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/255.jpg",
    },
    {
      id: 6,
      title: "Miss Susan Yundt",
      avatar:
        "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/957.jpg",
    },
    {
      id: 7,
      title: "Mr. Wayne Kub-Stokes III",
      avatar:
        "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1186.jpg",
    },
    {
      id: 8,
      title: "Priscilla Johnson",
      avatar: "https://avatars.githubusercontent.com/u/71552913",
    },
    {
      id: 9,
      title: "Tony Legros",
      avatar: "https://avatars.githubusercontent.com/u/25561449",
    },
    {
      id: 10,
      title: "Wendy Considine",
      avatar: "https://avatars.githubusercontent.com/u/61409352",
    },
  ],
};

export const mockWorkPackets = mockData;
