import { useState, useEffect } from "react";
import ClientConfigServices from "../../../../Services/ClientConfigServices";

const useVendorReferrals = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchVendorReferrals = async () => {
      try {
        const response = await ClientConfigServices.GetAllReferral({
          signal: abortController.signal,
        });

        if (response.status !== 200) {
          throw new Error("Failed to fetch referral partners");
        }

        const responseData = await response?.data?.vendor_referral_partners;

        setData(responseData);
        setIsRequestCompleted(true);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request aborted");
        } else {
          setError(error);
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchVendorReferrals();

    return () => {
      abortController.abort();
    };
  }, []);

  return { data, loading, error, isRequestCompleted };
};

export default useVendorReferrals;
