import {useEffect, useState} from "react";
import "./InvoicingAutomation.css";

import Loader from "../../../components/Loader/Loader";
import {exportInvoiceBackups} from "./Api/Invoicing Automation/Invoicing Automation";

const InvoicingAutomation = () => {
  useEffect(() => {
    document.title = "Charge Guard | Invoicing Automation";
  }, []);

  const [exportingAll, setExportingAll] = useState(false);

  const downloadAll = async () => {
    setExportingAll(true);
    await exportInvoiceBackups(setExportingAll)
  };

  return (
    <div className="container">
      <div className=" text-center">
        <div className="row d-inline-flex w-100 justify-content-center">
          <div className="col-md-6 col-12">
            <div className="ms-2 me-2 mt-2 mb-1 ">

            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-center gap-4 mt-2">
          <button
            className="btn btn-danger mb-4"
            onClick={downloadAll}
            disabled={exportingAll}
          >
            {exportingAll ? (
              <Loader style={{}} color="white"></Loader>
            ) : (
              'Download All Backup Files'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicingAutomation;
