import { AuditRequest } from "src/types/audits";

const mapArrayToAuditData = (data: any[][]): AuditRequest[] => {
  return data.map((item) => ({
    requestId: item[5],
    store: item[0],
    market: item[1],
    account: item[2],
    status: item[3],
    requestedDate: item[4].replace(" GMT", ""),
    completedDate: item[9]?.replace(" GMT", ""),
    chargebackMonths: item[8],
    shortageMonths: item[7],
    accuralMonths: item[6],
    poMonths: item[10],
    errorMessage: item[11],
  }));
};

export default mapArrayToAuditData;
