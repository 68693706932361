import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {AuthorisationGuard} from "./AuthorisationGuard.jsx";
export const Auth0 = ({ children }) => {
  const navigate = useNavigate();

  const domain = import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri =import.meta.env.VITE_REACT_APP_AUTH0_CALLBACK_URL;
  const audience = import.meta.env.VITE_REACT_APP_AUTH0_AUDIENCE;


  const onRedirectCallback = (appState) => {
 
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return <h1>500- System error</h1>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthorisationGuard>{children}</AuthorisationGuard>
    </Auth0Provider>
  );
};
