import {withAuthenticationRequired} from "@auth0/auth0-react";
import React, {useState} from "react";
import Loader from "../components/Loader/Loader";

export const AuthenticationGuard = ({component}) => {
  const [loader, setLoader] = useState(false);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader/>,
  });

  return loader ? <Loader/> : <Component/>;
};
