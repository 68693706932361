import { useField } from "formik";
import { Label } from "./Label";

export const FormikSelect = ({
  label,
  children,
  mandatory = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Label label={label} mandatory={mandatory} />
      <select
        className={`w-full form-select form-control outline-none p-2 border rounded-xl sm:text-md my-1 disabled:text-gray-500 ${
          props.classes ? props.classes : ""
        } ${meta.touched && meta.error ? "is-invalid" : ""}`}
        {...field}
        {...props}
      >
        {children}
      </select>
      {meta.touched && meta.error && (
        <div className="text-red">{meta.error}</div>
      )}
    </>
  );
};
