import { type QueryFunction } from "@tanstack/react-query";
import { http } from "../axios";
import { AllPermissionsList, parsePermissionsList } from "src/pages/UserDashboard/Users/UserAddEditDialog/permission-parser";

export interface UsersApiResponse {
  pages: number;
  success: boolean;
  total_records: number;
  users: User[];
}

export interface User {
  EMAIL: string;
  FIRST_SEEN_AT: string;
  ID: string;
  LAST_SEEN_AT: string;
  NAME: string;
  NICKNAME: string;
  PERMISSIONS: UserPermission[];
  PROFILE_PIC: null | string;
}

export interface UserPermission {
  GRANTED_AT: string;
  PERMISSION: string;
}

export const getUsersWithPermissions: QueryFunction<User[], ["users"]> = async ({ signal }) => {
  const data = await http.get<UsersApiResponse>("/api/v2/users", { signal });
  return data.data.users;
}

// keep in sync with back-end!
const permissionsList = [
  "admin:*",
  "user:client_config:read:*",
  "user:client_config:write:*",
  "user:shortages:write:*",
  "user:shortages:read:*",
  "user:chargebacks:write:*",
  "user:chargebacks:read:*",
  "user:accruals:write:*",
  "user:accruals:read:*",
  "user:ossr:read:*",
  "user:ossr:write:*",
  "user:invoicing:read:*",
  "user:invoicing:write:*",
  "user:audit:read:*",
  "user:audit:write:*",
  "user:chargebacks_work_packet:write:*",
  "user:chargebacks_work_packet:read:*",
  "user:shortages_work_packet:write:*",
  "user:shortages_work_packet:read:*",
];

export const getPermissionsList: QueryFunction<string[], ["permissions-list"]> = async () => {
  return permissionsList;
}

export const getParsedPermissionsList: QueryFunction<AllPermissionsList, ["parsed-permissions-list", parsedPermissionsList: string[]]> = async ({ queryKey }) => {
  const [, rawPermissionsList] = queryKey;
  return parsePermissionsList(rawPermissionsList);
}
