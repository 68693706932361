import { Button, Modal } from "react-bootstrap";

const InfoModal = ({handleCloseInfoModal,showInfoModal,infoData}) => {
    return (  
    <Modal
    show={showInfoModal}
    onHide={handleCloseInfoModal}
    backdrop="static"
    keyboard={false}
  >
    <form>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-dark">
          Information
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="p-3">
          <div className=" mb-3">
            <h5 className="fw-bold text-dark">
              Issue Type:
            </h5>
            <p>{infoData[1]}</p>
            <h5 className="fw-bold text-dark">
              Sub Type:
            </h5>
            <p>{infoData[2]}</p>
            <h5 className="fw-bold text-dark">
              Notes:
            </h5>
            <p>{infoData[3]}</p>
            <h5 className="fw-bold text-dark">
              Dispute Phrase:
            </h5>
            <p>{infoData[4]}</p>
            <h5 className="fw-bold text-dark">
              Region:
            </h5>
            <p>{infoData[5]}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            color: "white",
            backgroundColor: "#f54242",
            border: "none",
          }}
          onClick={handleCloseInfoModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </form>
  </Modal>  );
}
 
export default InfoModal;