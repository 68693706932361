import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { SettingsOutlined } from "@mui/icons-material";

import { ChangelogEntry } from "./data.ts";

const TimelineEntry: React.FC<ChangelogEntry> = ({
  time,
  user,
  action,
  packetId,
  to,
  state,
  isSystem,
  avatarUrl,
}) => {
  const avatarComponent = isSystem ? (
    <Avatar sx={{ width: 48, height: 48 }}>
      <SettingsOutlined />
    </Avatar>
  ) : (
    <Avatar src={avatarUrl} alt={user} sx={{ width: 48, height: 48 }} />
  );

  const getChangeString = () => {
    let changeString = action;
    if (packetId) {
      changeString += ` ID <strong>${packetId}</strong>`;
    }

    if (state) {
      changeString += ` ${state}`;
    }

    if (to) {
      changeString += ` to <strong>${to}</strong>`;
    }

    return changeString;
  };

  return (
    <Box
      alignItems="flex-start"
      sx={{
        padding: 0,
        marginBottom: "8px",
        "&:last-child": {
          marginBottom: 0,
        },
      }}
      marginTop={{ xs: 2, md: 0 }}
    >
      <Box display="flex" alignItems="center" width="100%">
        <Box
          width="62px"
          minWidth="62px"
          display={{ xs: "none", md: "flex" }}
          alignContent="center"
          justifyContent="center"
        >
          <Box
            sx={{
              width: "19px",
              height: "19px",
              borderRadius: "50%",
              backgroundColor: "#E2E2E2",
              border: "3px solid #fff",
              position: "relative",
              zIndex: 1,
            }}
          />
        </Box>
        <Box
          display="flex"
          gap="18px"
          alignItems={{ xs: "flex-start", md: "center" }}
          width="100%"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Typography variant="body2" color="textSecondary" component="span">
            {time}
          </Typography>

          <Box
            display="flex"
            boxShadow="0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)"
            flex="1"
            padding={1.5}
            borderRadius={2}
            gap={2}
            alignItems="center"
            width="100%"
          >
            {avatarComponent}
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                fontSize: "14px",
                strong: {
                  color: "#101828",
                  fontWeight: 400,
                },
              }}
            >
              <Box sx={{ fontWeight: 500 }}>{user}</Box>
              <Box>
                <p
                  style={{ color: "#667085" }}
                  dangerouslySetInnerHTML={{ __html: getChangeString() }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TimelineEntry;
