import React from "react";
import { Box } from "@mui/material";
import { ChangelogDate } from "./data.ts";
import TimelineEntry from "./TimelineEntry.tsx";

const TimelineDate: React.FC<ChangelogDate> = ({ date, entries }) => (
  <Box mb={4}>
    <Box
      display="inline-block"
      width="62px"
      sx={{
        color: "#667085",
        fontSize: "14px",
        padding: "7px 8px",
        backgroundColor: "#E2E2E2",
        borderRadius: "24px",
        textAlign: "center",
        lineHeight: "1",
        marginBottom: "16px",
      }}
    >
      {date}
    </Box>
    <Box
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "0%",
          left: "30px",
          width: "2px",
          height: "100%",
          backgroundColor: "#EAECF0",
        },
        "@media (max-width: 899px)": {
          "&::before": {
            display: "none",
          },
        },
      }}
    >
      {entries.map((entry, index) => (
        <TimelineEntry key={index} {...entry} />
      ))}
    </Box>
  </Box>
);

export default TimelineDate;
