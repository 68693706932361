import { type QueryFunction } from "@tanstack/react-query";
import { http } from "src/axios";

export interface LkpAccountsResponse {
  accounts: [accountName: string][];
  marketplaces: [countryCode: string][];
  status: number;
}

export const getAuditAccounts: QueryFunction<string[], ["audit-accounts"]> = async ({ signal }) => {
  const response = await http.get<LkpAccountsResponse>("/LKPAccounts", { signal });
  return response.data.accounts.map(account => account[0]);
};
