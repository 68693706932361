import { useState } from "react";
import TotalServices from "../../../TotalServices";
import DeleteModal from "../../../components/Modals/Delete";
import { toast } from "react-hot-toast";
import DeleteOssr from "./DeleteOSSR";
import moment from "moment";
import StatusButton from "../../../components/StatusButton/StatusButton";
import useDownloadNew from "../../../components/CustomHooks/useDownloadNew";

const OssrTable = ({ tableData, GetQuery }) => {
  const [fileLoader, setFileLoader] = useState(false);
  const [id, setId] = useState();

  const { DownloadFileNew } = useDownloadNew();
  return (
    <div className="table-container">
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Store</th>
            <th scope="col">Completion Date</th>
            <th scope="col">Status</th>
            <th scope="col">Notes</th>
            <th scope="col">Files Download</th>
            {/* <th scope="col">Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {(tableData ?? [0]).length !== 0 &&
            tableData?.map((data, index) => (
              <tr key={index}>
                <td>{data[1]}</td>
                <td>
                  {data[3] ? moment(data[3]).format("YYYY-MM-DD") : "Pending"}
                </td>
                <td>
                  <StatusButton status={data[2]} />
                </td>

                <td>{data[4] ? data[4] : "Processing"}</td>

                <td>
                  {data[2] != "Done" && data[2] != "Done (Duplicate Payee)" ? (
                    <span className={"status-button-progress"} role={"button"}>
                      {" "}
                      {data[2]}
                    </span>
                  ) : (
                    <>
                      <div className="btn-group">
                        {fileLoader && data[0] == id ? (
                          <>
                            <div className="btn-group">
                              <button
                                type="button"
                                className="status-button rounded-end"
                              >
                                Downloading
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={(e, id = data[0]) => {
                                setId(id);
                                DownloadFileNew(
                                  "PQV",
                                  id,
                                  setFileLoader,
                                  data[1],
                                  data[3]
                                );
                              }}
                              type="button"
                              className="status-button rounded-end"
                            >
                              PQV
                            </button>
                            <button
                              onClick={(e, id = data[0]) => {
                                setId(id);
                                DownloadFileNew(
                                  "PPV",
                                  id,
                                  setFileLoader,
                                  data[1],
                                  data[3]
                                );
                              }}
                              type="button"
                              className="status-button rounded-start"
                            >
                              PPV
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </td>

                {/* <td>
                  <DeleteOssr deleteId={data[0]} GetQuery={GetQuery} />
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default OssrTable;
