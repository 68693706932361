import ClientConfigServices from "../../../../../Services/ClientConfigServices";

const getReferralData = async (setReferralData) => {
  try {
    let response = await ClientConfigServices.GetAllReferral();
    if (response.status === 200) {
      const referralOptions = response.data.vendor_referral_partners.map(
        (data) => {
          const { ID, NAME } = data; // Destructure the object properties
          return { label: NAME, value: ID.toString() };
        }
      );

      // Create a new array with the default option and existing referralOptions
      const updatedReferralOptions = [...referralOptions];

      setReferralData(updatedReferralOptions);
    }
  } catch (e) {}
};

const getReferrals = async () => {
  try {
    let response = await ClientConfigServices.GetAllReferral();
    if (response.status === 200) {
      return response.data.vendor_referral_partners.map((data) => {
        const { ID, NAME } = data; // Destructure the object properties
        return { label: NAME, value: ID.toString() };
      });
    }
  } catch (e) {}
};

const getShippingData = async (setShippingData) => {
  try {
    let response = await ClientConfigServices.GetAllShipping();
    if (response.status === 200) {
      const referralOptions = response.data.shippings.map((data) => {
        const { ID, NAME } = data; // Destructure the object properties
        return { label: NAME, value: ID.toString() };
      });

      // Adding a default option at the beginning
      referralOptions.unshift({ label: "Select Shipping", value: "" });

      setShippingData(referralOptions);
    }
  } catch (e) {}
};

const getAvcData = async (setAvcData) => {
  try {
    let response = await ClientConfigServices.GetAllAvcUsers();
    if (response.status === 200) {
      const convertedData = response?.data?.avc_users.map((item) => ({
        label: item.NAME,
        value: item.ID,
      }));

      setAvcData(convertedData);
    }
  } catch (e) {}
};

const getShortageProcessor = async (setShortageProcessorData) => {
  try {
    let response = await ClientConfigServices.GetAllShortageProcessor();
    if (response.status === 200) {
      const convertedData = response?.data?.shortage_processors.map((item) => ({
        label: item.NAME,
        value: item.ID,
      }));

      convertedData.unshift({ label: "Select Shortage Processor", value: "" });

      setShortageProcessorData(convertedData);
    }
  } catch (e) {}
};

const getChargebackProcessor = async (setChargebackProcessorData) => {
  try {
    let response = await ClientConfigServices.GetAllChargebackProcessor();
    if (response.status === 200) {
      const convertedData = response?.data?.chargebacks_processors.map(
        (item) => ({
          label: item.NAME,
          value: item.ID,
        })
      );

      convertedData.unshift({
        label: "Select Chargeback Processing",
        value: "",
      });

      setChargebackProcessorData(convertedData);
    }
  } catch (e) {}
};

export {
  getReferralData,
  getShippingData,
  getAvcData,
  getShortageProcessor,
  getChargebackProcessor,
  getReferrals,
};
