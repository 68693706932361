import { useState, useEffect } from "react";
import "./Ossr.css";
//Packages
import toast from "react-hot-toast";

//Components
import PaginationButtons from "../../../components/Pagination/PaginationButtons";

import OssrTable from "./OssrTable";
import Search from "../../../components/Search/Search";
import OssrCreateQuery from "./OssrCreateQuery";
import TableLoader from "../../../components/Loader/TableLoader";
import TotalServices from "../../../TotalServices";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

const OssrNew = () => {
  useEffect(() => {
    document.title = "Charge Guard | OSSR | New";
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  // Pagination
  const [totalRecords, setTotalRecords] = useState();
  const [searchRecord, setSearchRecord] = useState(0);
  const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  //download loader

  // MDTFilter
  const [options, setOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);

  const GetOssrData = async () => {
    try {
      setLoader(true);
      let response = await TotalServices.ListQueriesNew(
        NumberOfRecordsPerPage,
        (currentPage - 1) * NumberOfRecordsPerPage,

        { query: searchQuery }
      );
      if (response.status === 200) {
        //  console.log(response);
        setTableData(response.data.user_queries);
        setTotalPages(response?.data?.pages);
        setTotalRecords(response?.data?.total_records);
        setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage);
        setGoto("");
        setOptions(
          response?.data?.stores?.map((value) => ({
            value: value[0],
            label: value[0],
          }))
        );
        setLoader(false);
      }
    } catch (e) {
    }
  };

  useEffect(() => {
    GetOssrData();
  }, [currentPage]);

  // Download Files ---- End

  return (
    <div className="container">
      <OssrCreateQuery options={options} GetOssrData={GetOssrData} />
      <Search
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        GetData={GetOssrData}
      />

      <div className="container-sm table_main">
        {!loader ? (
          <>
            {tableData.length >= 1 ? (
              <>
                <OssrTable tableData={tableData} GetOssrData={GetOssrData} />
                <PaginationButtons
                  totalRecords={totalRecords}
                  setRecord={setSearchRecord}
                  record={searchRecord}
                  NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setGoto={setGoto}
                  goto={goto}
                  totalPages={totalPages}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </>
        ) : (
          <TableLoader />
        )}
      </div>
    </div>
  );
};

export default OssrNew;
