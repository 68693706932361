import Services from "../ServicesRoutes";
import { http } from "../axios";

const ListAudits = (limit: number, offset: number, data: any) => {
  /* @ts-ignore */
  return http.put(Services.Audit + limit + "/" + offset, data);
};

const CreateAudit = (data: any) => {
  /* @ts-ignore */
  return http.post(Services.Audit + null + "/" + null, data);
};

const UserDeleteAudit = (id: any) => {
  /* @ts-ignore */
  return http.delete(Services.Audit + id + "/" + undefined);
};

const GetAuditAccounts = () => {
  /* @ts-ignore */
  return http.get(Services.AuditAccounts);
};

const GetAuditVCName = (region: any) => {
  /* @ts-ignore */
  return http.get(Services.AuditVCName + region);
};

const AuditServices = {
  ListAudits,
  CreateAudit,
  UserDeleteAudit,
  GetAuditAccounts,
  GetAuditVCName,
};
export default AuditServices;
