import InvoicingAutomationServices from "../../../../../Services/InvoicingAutomationServices";
import FileDownloadServices from "../../../../../Services/FileDownloadServices";

const exportInvoiceBackups = async (setLoader) => {
    setLoader(true)
    try {
        let response = await InvoicingAutomationServices.exportInvoiceBackups();
        if (response.status === 200) {
            FileDownloadServices.downloadFile(response, 'invoice-backups', 'zip')
        }
    } catch (e) {
    }
    setLoader(false)
}

export {exportInvoiceBackups};
