import { toast } from "react-hot-toast";
import TotalServices from "../../TotalServices";

const useShortageDownload = () => {
  const DownlaodFile = async (type, id, setFileLoader, store, date) => {
    setFileLoader(true);
    try {
      let response = await TotalServices.ShotageDisputeDownlaodFile(type, id);

      if (response.status === 200) {
        let file = response.headers["content-disposition"].replace(
          "attachment;filename=",
          ""
        );
        var a = document.createElement("a");
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        a.href = downloadUrl;

        a.setAttribute("download", file);
        document.body.appendChild(a);

        a.click();
        setFileLoader(false);
      }
    } catch (error) {
      setFileLoader(false);
      toast.error("Can't be able to download file");
    }
  };
  return { DownlaodFile };
};

export default useShortageDownload;
