const TextAreaField = ({
  placeholder,
  name,
  handleChange,
  value,
  disabled,
  handleFocus,
  handleBlur,
  classes,
  maxLength,
  rows
}) => {
  return (
    <textarea
      className={`w-full form-control outline-none p-2 border rounded-xl sm:text-md my-1 disabled:text-gray-500 ${
        classes ? classes : ""
      } `}
      placeholder={placeholder}
      name={name}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={value}
      disabled={disabled}
      rows={rows}
      onChange={handleChange}
      maxLength={maxLength}
    />
  );
};

export default TextAreaField;
