import type { QueryFunction } from "@tanstack/react-query";
import { http } from "src/axios";

export interface OssrApiResponse {
  data: OssrQuery[];
  message: string;
  status: number;
  total_count: number;
  statuses: string[];
}

export interface OssrQuery {
  CLIENT_REGION: string;
  COMPLETION_DATE: string | null;
  DATE_CREATED: string | null;
  DATE_UPDATED: null;
  DATE_UPLOADED: string;
  FILENAME: string | null;
  QUERY_ID: number;
  RESULT: OssrResult | null;
  STATUS: OssrStatus;
  STATUS_ID: number;
  STORE_NAME: string;
  TYPE: Type;
  USER_ID: string;
}

export interface OssrResult {
  DISPUTE_STATUS: OssrDisputeStatus;
  ID: number;
  INVOICES: string;
  MESSAGE: OssrResultMessage | string;
  PPV: string;
  PQV: string;
  QUERY_ID: number;
}

export enum OssrDisputeStatus {
  Ready = "READY",
}

export enum OssrResultMessage {
  MultiplePayee = "Multiple Payee",
  NoDataFound = "No Data Found",
  Success = "Success",
}

export interface OssrStatus {
  ID: number;
  TEXT: OssrStatusText | string;
}

export enum OssrStatusId {
  WORKING = 1,
  DONE = 2,
  READY = 3,
  ERROR = 4,
  DONE_DUPLICATE_PAYEE = 5,
}

export enum OssrStatusText {
  Completed = "Completed",
  Error = "Error",
  InProgress = "In-Progress",
  Ready = "Ready",
}

export enum Type {
  ManualPQV = "Manual_PQV",
  Mdt = "MDT",
  Ossr = "OSSR",
  Ppv = "PPV",
  Pqv = "PQV",
}

export interface OssrStatusDisplay {
  text: string;
  color: string;
}

export const ossrStatusMapping: Record<number, OssrStatusDisplay> = {
  1: { text: "Processing", color: "#ff820f" },
  2: { text: "Done", color: "#39d4a5" },
  3: { text: "Queued", color: "#ffed93" },
  4: { text: "Error", color: "#e03741" },
};

export const ossrStatuses = Object.values(ossrStatusMapping).map(statusDisplay => statusDisplay.text);

export const getOssrReports: QueryFunction<OssrApiResponse, ["ossr"]> = async ({ signal }) => {
  const response = await http.get<OssrApiResponse>("/api/v2/ossr", { signal });
  return response.data;
};
