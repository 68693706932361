import { useState, useEffect } from "react";

//Packages
import TotalServices from "../../../../TotalServices";
import Search from "../../../../components/Search/Search";
import DisputeTable from "./DisputeTable";
import TableLoader from "../../../../components/Loader/TableLoader";
import PaginationButtons from "../../../../components/Pagination/PaginationButtons";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import CreateDispute from "./CreateDispute";
import useDisputeToolDownload from "../../../../components/CustomHooks/useDisputeToolDownload";
import { useAuth0 } from "@auth0/auth0-react";

const DisputeTool = () => {
  const { DownlaodFile } = useDisputeToolDownload();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    document.title = "Charge Guard | Dispute Tool";
  }, []);
  useEffect(() => {
    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      localStorage.setItem("access_token", accessToken);
    };

    getMessage();
  }, [getAccessTokenSilently]);

  const [loader, setLoader] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const [options, setOptions] = useState("");

  // Pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchRecord, setSearchRecord] = useState(0);
  const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(3);
  // Get Data
  const GetMDTData = async () => {
    try {
      setLoader(true);
      let response = await TotalServices.ListDisputesVendors(
        NumberOfRecordsPerPage,
        (currentPage - 1) * NumberOfRecordsPerPage,

        { query: searchQuery }
      );
      if (response.status === 200) {
        //  console.log(response);
        setTableData(response.data.user_queries);
        setTotalPages(response?.data?.pages);
        setTotalRecords(response?.data?.total_records);
        setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage);
        setOptions(
          response?.data?.stores?.map((value) => ({
            value: value[0],
            label: value[0],
          }))
        );
        setGoto("");
        setLoader(false);
    }
    }catch(e){
    }
  };

  useEffect(() => {
    GetMDTData();
  }, [currentPage]);

  // Download Files ---- End

  return (
    <div className="container">
      <CreateDispute getData={GetMDTData} options={options} />

      <Search
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        GetData={GetMDTData}
        placeholder={"Search Store"}
      />

      <div className="container-sm table_main">
        {!loader ? (
          <>
            {tableData.length >= 1 ? (
              <>
                <DisputeTable data={tableData} />
                {(tableData ?? [0]).length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => {}}
                      className="btn btn-warning disabled"
                      style={{ padding: "6pt" }}
                      colSpan={"7"}
                    >
                      No record Found
                    </div>
                  </div>
                )}
                <PaginationButtons
                  totalRecords={totalRecords}
                  setRecord={setSearchRecord}
                  record={searchRecord}
                  NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setGoto={setGoto}
                  goto={goto}
                  totalPages={totalPages}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </>
        ) : (
          <TableLoader />
        )}
      </div>
    </div>
  );
};

export default DisputeTool;
