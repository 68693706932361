const NoDataFound = () => {
    return (  <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {}}
          className="btn btn-warning disabled"
          style={{ padding: "6pt" }}
          colSpan={"7"}
        >
          No record Found
        </div>
      </div> );
}
 
export default NoDataFound;