import ApiUrl from "../../../config/config";
import React, { useState, useEffect} from "react";
import "../Login/login.css"
import MainLogo from "../../../assets/logo.png"
import toast, { Toaster } from 'react-hot-toast';
import EmailLogo from "../../../assets/email.png";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader"

const ForgotPassword = () =>{

    let navigate = useNavigate();

    const login = () =>
    {
        navigate("/");
    }


    useEffect(() => {
        document.title = 'Charge Guard | Forgot Password'
      }, [])
    const [email, setEmail] = useState("")
    const [checkEmail, setCheckEmail] = useState(false)
    const [loader, setLoader] = useState(false)
    const sendEmail = () => {
        setLoader(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
            email: email,
          });
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
    
          fetch(ApiUrl + "/forgot-password-user", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
              if (result.status === 200) {
                setCheckEmail(true)
                toast.success('Email send Successfully')
                setEmail("");
              } else {
                setCheckEmail(false)
                toast.error(result.message)
              }
            })
            .catch((error) => {
                setLoader(false)
                setCheckEmail(false)
            });
        
      };



    return(
        <div className="login-container">
             <Toaster />
             {
                checkEmail ?
                <div className="card">
                    <div className="text-left">
                        <div className="flexflex mb-2">
                            <img src={MainLogo} width="250px"/>
                        </div>
                        <div className="flexflex">
                             <img src={EmailLogo} height="70px"/>
                        </div>
                        <p className="below-text text-center mb-0"><b>Email has been Sent </b></p>
                        <p className="text-center text-secondary mb-0">Please Check your email account to reset your password</p>
                        
                </div>
                <form onSubmit={(e)=>(e.preventDefault(),navigate("/"))}>
                    <div className="mt-2 proceed"> 
                        <button type={"submit"} className="btn-primary btn-full mt-3">
                            Go to Login
                        </button> 
                    </div>
                </form>
            </div>:
                <div className="card">
                    <div className="text-left">
                        <div className="flexflex mb-2">
                            <img src={MainLogo} width="250px"/>
                        </div>
                        <p className="below-text text-center mb-0"><b>Reset Your password</b></p>
                        <p className="text-center text-secondary mb-0">Please enter your email address and we will send you a password password link</p>
                        
                </div>
                <form onSubmit={(e)=>(e.preventDefault(),sendEmail())}>
                    <div>
                        <div className="inputbox">
                            <span>Email <span className="text-danger">*</span></span> 
                            <input type="text" value={email} onChange={(e)=> setEmail(e.target.value)} className="form-control" required="required" placeholder="abc@gmail.com"/> 
                        </div>
                    </div>
                    <div className="mt-2 proceed"> 
                    {
                        loader ?
                        <button className="btn-primary btn-loader btn-full mt-3">
                            <PulseLoader size={10} color={"#fff"}/>
                        </button> :
                        <>
                        <button type={"submit"} className="btn-primary btn-full mt-3">
                             Send Reset Link
                        </button>
                        <button type={"submit"} onClick= {login} className="btn-primary btn-full mt-3">
                             Go to Login
                        </button>
                        </>

                    }
                    </div>
                </form>
            </div>

             }
          
        </div>
    )
}
export default ForgotPassword;