import { useMutation } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import moment from "moment";
import { type FC } from "react";
import { http } from "src/axios";
import { OssrStatusId, type OssrQuery } from "src/queries/ossr";

import CheckIcon from '@mui/icons-material/Check';
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { type GridCellParams } from "@mui/x-data-grid-pro";
import { HiDownload } from "react-icons/hi";

import css from "./OssrDownloadCell.module.css";

const PqvType = () => "PQV" as const;
const PpvType = () => "PPV" as const;

const fetchPqvFile = (query: OssrQuery) => http.get<Blob>(`/OSSR/PQV/${query.QUERY_ID}`, { responseType: 'blob' });
const fetchPpvFile = (query: OssrQuery) => http.get<Blob>(`/OSSR/PPV/${query.QUERY_ID}`, { responseType: 'blob' });

const onQueryBlobReceived = (response: AxiosResponse<Blob>, query: OssrQuery, type: "PQV" | "PPV") => {
  const a = document.createElement("a");
  const href = URL.createObjectURL(response.data);
  const filename = query.RESULT?.[type] ?? `${query.STORE_NAME}_${moment(query.COMPLETION_DATE).format("YYYY-MM-DD")}_${type}.xslx`;
  a.href = href;
  a.download = filename;
  a.click();
};

const OssrDownloadButton: FC<{ query: OssrQuery, type: "PQV" | "PPV" }> = ({ query, type }) => {
  const download = useMutation({
    mutationKey: ["ossr-download", query.QUERY_ID, type],
    mutationFn: type === "PQV" ? fetchPqvFile : fetchPpvFile,
    onMutate: type === "PQV" ? PqvType : PpvType,
    onSuccess: onQueryBlobReceived,
  });

  const onClick = () => download.mutate(query);

  return (
    <Button onClick={onClick} disabled={download.isPending} variant="outlined">
      {download.isPending ? (
        <CircularProgress size={12} />
      ) : download.isSuccess ? (
        <CheckIcon />
      ) : (
        /* show normal icon on error, user is already notified with a toast */
        <HiDownload />
      )}
      <span>{type}</span>
    </Button>
  );
};

export const OssrDownloadCell: FC<GridCellParams<OssrQuery>> = ({ row }) => {
  if (!row.RESULT || row.STATUS_ID !== OssrStatusId.DONE) return null;

  return (
    <div className={css.ossrDownloadCell}>
      {row.RESULT.PQV && <OssrDownloadButton query={row} type="PQV" />}
      {row.RESULT.PPV && <OssrDownloadButton query={row} type="PPV" />}
    </div>
  );
};
