/**
 * @file useAudits.ts
 * @description This custom hook manages the fetching, filtering, and paginating of audits.
 *
 * Note: This hook is designed to work with MUI's DataGrid component.
 */

import { useState, useEffect, useCallback } from "react";
import { AuditRequest } from "src/types/audits";
import AuditServices from "src/Services/AuditsServices";
import mapArrayToAuditData from "./utils/mapArrayToAuditData";

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface AuditsServerResponse {
  audits: any[];
  pages: number;
  total_records: number;
}

interface UseAuditsReturnType {
  /** Grid rows */
  data: AuditRequest[];
  /** Pagination model: current page and page size */
  paginationModel: PaginationModel;
  /** Total number of rows (not just the current page) */
  total: number;
  /** Loading state while fetching data */
  loading: boolean;
  /** Function to trigger fetching audits */
  triggerFetchAudits: () => void;
  /** Set search query */
  setSearchQuery: (query: string) => void;
  /** Callbacks for grid events */
  callbacks: {
    /** Triggered on any pagination change */
    onPaginationModelChange: (model: {
      page: number;
      pageSize: number;
    }) => void;
  };
}

/**
 * Custom hook to manage fetching, filtering, and paginating audits.
 */
export const useAudits = (): UseAuditsReturnType => {
  const [data, setData] = useState<AuditRequest[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAudits = useCallback(
    async (paginationModel: PaginationModel, searchQuery: string) => {
      try {
        setLoading(true);

        const response = await AuditServices.ListAudits(
          // Limit
          paginationModel.pageSize,

          // Offset
          paginationModel.page * paginationModel.pageSize,

          // Search query
          { query: searchQuery }
        );

        if (response.status !== 200) {
          throw new Error(
            "Error while fetching audits. Response status is not 200."
          );
        }

        const responseData = response?.data as AuditsServerResponse;
        const { audits: auditsData, total_records } = responseData;

        const audits = mapArrayToAuditData(auditsData);
        setData(audits);
        setTotal(total_records);
      } catch (error) {
        console.error("Error while fetching audits:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchAudits(paginationModel, searchQuery);
  }, [paginationModel, searchQuery]);

  const handlePaginationModelChange = useCallback(
    (model: { page: number; pageSize: number }) => {
      setPaginationModel(model);
    },
    []
  );

  const triggerFetchAudits = useCallback(() => {
    fetchAudits(paginationModel, searchQuery);
  }, []);

  return {
    data,
    paginationModel,
    total,
    loading,
    triggerFetchAudits,
    setSearchQuery,
    callbacks: {
      onPaginationModelChange: handlePaginationModelChange,
    },
  };
};
