type Tier = "Gold" | "Silver" | "Bronze";

export const getColorByCustomerTier = (tier: Tier): string => {
  const colorMap = {
    Gold: "gold",
    Silver: "silver",
    Bronze: "#A5552B",
  };

  return colorMap[tier];
};
