import { Components } from "@mui/material/styles";

const button: {
  MuiButton: Components["MuiButton"];
  MuiIconButton: Components["MuiIconButton"];
} = {
  MuiButton: {
    styleOverrides: {
      root: {
        color: "#101828",
        textTransform: "none",
        "& .MuiSvgIcon-root": {
          fontSize: "16px",
        },
      },

      outlined: {
        backgroundColor: "transparent",
        borderColor: "#98A2B3",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "8px",
        padding: "8px 14px",
        "&:hover": {
          borderColor: "#101828",
          backgroundColor: "#f6f6f626",
        },
        "&.active": {
          color: "#F7104D",
        },
      },

      contained: (props: any) => ({
        backgroundColor: props.theme.palette.primary.light,
        boxShadow: "none",
        color: "white",
        borderRadius: "8px",
        padding: "8px 14px",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: props.theme.palette.primary.light,
        },
        "&.Mui-disabled": {
          backgroundColor: "#E8E9EA",
          color: "white",
        },
        "&.contained-reversed": {
          backgroundColor: "white",
          color: "#F7104D",
        },
      }),
    },
  },

  MuiIconButton: {
    styleOverrides: {
      root: {
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
        },
        "&.outlined": {
          borderColor: "#98A2B3",
          borderWidth: "1px",
          borderStyle: "solid",
          borderRadius: "8px",
          color: "#101828",
          fontSize: "12px",
          padding: "8px 14px",

          "&.Mui-disabled": {
            borderColor: "#E8E9EA",
            color: "#E8E9EA",
          },
        },
      },
    },
  },
};

export default button;
