import { Scope } from "../types";

/**
 * Function to format the dates in the scope data
 */
const formatDatesInScopeData = (data: Scope[]) => {
  if (!data) {
    return [];
  }

  return data.map((scope) => {
    const formattedScope = { ...scope };

    formattedScope.ACTIVATION_DATE = scope.ACTIVATION_DATE
      ? new Date(scope.ACTIVATION_DATE).toISOString().split("T")[0]
      : "N/A";

    formattedScope.DEACTIVATION_DATE = scope.DEACTIVATION_DATE
      ? new Date(scope.DEACTIVATION_DATE).toISOString().split("T")[0]
      : "N/A";

    formattedScope.INVOICE_START_DATE = scope.INVOICE_START_DATE
      ? new Date(scope.INVOICE_START_DATE).toISOString().split("T")[0]
      : "N/A";

    formattedScope.INVOICE_END_DATE = scope.INVOICE_END_DATE
      ? new Date(scope.INVOICE_END_DATE).toISOString().split("T")[0]
      : "N/A";

    return formattedScope;
  });
};

export default formatDatesInScopeData;
