import { useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import CreatableSelect from "react-select";
import TotalServices from "../../../TotalServices";
import { toast } from "react-hot-toast";
import { ClipLoader } from "react-spinners";

const OssrCreateQuery = ({ options, GetOssrData }) => {
  const [selected, setSelected] = useState();
  const [createLoader, setCreateLoader] = useState(false);
  const CreateQuery = async () => {
    if (selected) {
      try {
        setCreateLoader(true);
        let response = await TotalServices.CreateUserQueryNew({
          store: selected.value,
        });
        if (response.status === 200) {
          toast.success(response.data.message);
          setCreateLoader(false);
          GetOssrData();
        }
      } catch (e) {
      }
    } else {
      toast.error("Please select store first");
    }
  };
  return (
    <div className=" text-center">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          CreateQuery();
        }}
      >
        <div className="row d-inline-flex w-100 justify-content-center">
          <div className="col-md-6 col-12">
            <div className="ms-2 me-2 mt-2 mb-1 ">
              <label className="form-label fw-bold">Select Store</label>
              <CreatableSelect
                className="basic-single "
                classNamePrefix="select"
                name="color"
                placeholder="Store"
                isClearable
                value={selected}
                options={options}
                onChange={setSelected}
              />
            </div>
          </div>
        </div>

        <div className="col">
          <button
            className="btn-primary  mt-3 mb-3"
            type="submit"
            disabled={createLoader}
          >
            <span className="me-1">
              {createLoader ? (
                <ClipLoader size={16} color="white" />
              ) : (
                <FaCloudUploadAlt />
              )}
            </span>
            Start Processing
          </button>
        </div>
      </form>
    </div>
  );
};

export default OssrCreateQuery;
