import { FC, useState, FormEvent } from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

interface SearchProps {
  onSearch: (query: string) => void;
  placeholder?: string;
}

const Search: FC<SearchProps> = ({ onSearch, placeholder }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(searchQuery);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" alignItems="center" gap={2}>
        <TextField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          variant="outlined"
          size="small"
          placeholder={placeholder || "Search..."}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Box>
    </form>
  );
};

export default Search;
