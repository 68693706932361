import { useState, useEffect } from "react";
import ClientConfigServices from "../../../../Services/ClientConfigServices";

const useVendorById = (vendorId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchVendor = async () => {
      try {
        const response = await ClientConfigServices.GetVendorsByID(vendorId, {
          signal,
        });

        if (response.status !== 200) {
          throw new Error("Failed to fetch customer");
        }

        const responseData = await response?.data?.vendors[0];

        setData(responseData);
        setIsRequestCompleted(true);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request aborted");
        } else {
          setError(error);
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    };

    if (vendorId) {
      fetchVendor();
    }

    return () => {
      abortController.abort();
    };
  }, [vendorId]);

  return { data, loading, error, isRequestCompleted };
};

export default useVendorById;
