import { Outlet } from "react-router-dom";
import Sidebar from "../../components/UserDashboard/Sidebar/Sidebar";
import { Box } from "@mui/material";
import Header from "components/UserDashboard/Header";
import { useEffect, useState } from "react";

const UserDashboard = () => {
  // const [menuCollapse, setMenuCollapse] = useState(false);
  const [sidebarToggled, setSidebarToggled] = useState(false);

  useEffect(() => {
    const overlay = document.querySelector(".pro-sidebar .overlay");
    overlay?.addEventListener("click", () => {
      setSidebarToggled(false);
    });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ backgroundColor: "#F7F7F7", height: "100%" }}
    >
      <Sidebar sidebarToggled={sidebarToggled} />
      <Box flex="1" overflow="auto" height="100vh" bgcolor="#fff">
        <Header
          sidebarToggled={sidebarToggled}
          setSidebarToggled={setSidebarToggled}
        />
        {/* @ts-ignore */}
        <Outlet style={{ zIndex: "-10" }} />
      </Box>
    </Box>
  );
};

export default UserDashboard;
