import AuditsSvg from "./audits.svg?react";
import CbDisputeSvg from "../icons/cb-dispute.svg?react";
import CustomersSvg from "../icons/customers.svg?react";
import CbDisputeToolSvg from "../icons/cb-dispute-tool.svg?react";
import CbDisputeUsageSvg from "../icons/cb-dispute-usage.svg?react";
import CbDisputeManageSvg from "../icons/cb-dispute-manage.svg?react";
import LogoutSvg from "../icons/logout.svg?react";
import WorkPacketsSvg from "../icons/work-packets.svg?react";
import DisputeSvg from "../icons/dispute.svg?react";
import InvoiceSvg from "../icons/invoice.svg?react";
import OssrSvg from "../icons/ossr.svg?react";
import ObarSvg from "../icons/obar.svg?react";
import UsersSvg from "../icons/users.svg?react";

/**
 * A map for defining SVG icons.
 *
 * Before adding an icon to this map, please make sure to:
 * 1. Replace all `fill` and `stroke` attribute values with `currentColor` to allow for customizing the icon color through the `color` prop;
 * 2. Remove `width` and `height` attributes from the SVG file (leave `viewBox` untouched) to allow for icon size customization.
 */
export const icons = {
  audits: AuditsSvg,
  cbDispute: CbDisputeSvg,
  customers: CustomersSvg,
  cbDisputeTool: CbDisputeToolSvg,
  cbDisputeUsage: CbDisputeUsageSvg,
  cbDisputeManage: CbDisputeManageSvg,
  logout: LogoutSvg,
  workPackets: WorkPacketsSvg,
  dispute: DisputeSvg,
  invoice: InvoiceSvg,
  ossr: OssrSvg,
  obar: ObarSvg,
  users: UsersSvg,
};
