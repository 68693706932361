let Services = {
  login: "/User",
  Ossr: "/OSSR/",
  Ossr_new: "/OSSR_new/",
  DisputeTool: "/MDT/",
  ManagePhrase: "/MDTPhrase/",
  ShortageDispute: "/ShortagesDispute/",
  InvoicingAutomation: "/InvoiceAuto",
  GetRegion: "/GetRegions",
  Configuration: "/Configuration/",
  ConfigurationData: "/ConfigurationData/",
  MDTAnalytics: "/MDTAnalytics/",
  InvoicingAllStores: "/AllStores/",
  Audit: "/LKPAudit/",
  AuditAccounts: "/LKPAccounts",
  AuditVCName: "/GetVcNames/",
  clientConfig: "/ClientsConfig/",
  vendors: "vendors",
  accounts: "accounts",
  spoc: "spoc",
  scope: "scopes",
  referral: "referral_partner",
  shipping: "shipping",
  base: "/api",
  version: "/v1/",
  v2: "/v2",
  avcuser: "avc_user",
  invoice_auto: "invoice_auto",
  obar: "obar/evidence",
  user: "/user",
  users: "/users",
  generate_obar: "obar/generate",
  shortageProcessor: "shortage_processor",
  chargebackProcessor: "chargebacks_processor",
  CustomersNew: "customers",
  CustomBranding: "custom_branding",
};
export default Services;
