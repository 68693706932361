import { Scope } from "../types";

interface InputCellProps {
  dataValue?: string | null;
  isEditableRow: boolean;
  formik: any;
  handleChange: (field: keyof Scope, value: any) => void;
  name: keyof Scope;
}

const InputCell = ({
  dataValue,
  isEditableRow,
  formik,
  handleChange,
  name,
}: InputCellProps) => {
  const getStaticValue = (value?: string | null) => {
    if (value === null) return "N/A";
    return value;
  };

  if (!isEditableRow) return <td>{getStaticValue(dataValue)}</td>;

  return (
    <td>
      <input
        type="date"
        className="form-control"
        name={name}
        value={formik.values[name]}
        onChange={(e) => handleChange(name, e.target.value)}
      />
    </td>
  );
};

export default InputCell;
