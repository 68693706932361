import { SearchOutlined } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Field } from "formik";
import { WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketView } from "../../WorkPacketView";
import { staticFilterOptions } from "../helpers/getStaticFilterOptions";
import { type FiltersForm } from "./WorkPacketsGridFilters";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";

import { AutocompleteField, SelectField } from "src/components/UI/Form";

export const ChargebackFiltersForm: FiltersForm = ({
  props: { dynamicFilterOptions, userNickname, view },
  formApi: { setFieldValue },
}) => (
  <Box
    display="grid"
    sx={{
      gridTemplateColumns: {
        xs: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
        md: "repeat(4, 1fr)",
      },
      gap: 2,
      rowGap: 3,
    }}
  >
    <Field
      name={WorkPacketFilter.StoreName}
      component={AutocompleteField}
      label="Store Name"
      options={dynamicFilterOptions[WorkPacketFilter.StoreName]}
      getOptionLabel={(option: any) => option.title}
      placeholder="Store Name"
      setFieldValue={setFieldValue}
    />

    <Field
      name={WorkPacketFilter.RecoveryStream}
      component={SelectField}
      label="Recovery Stream"
      options={staticFilterOptions[WorkPacketFilter.RecoveryStream]}
    />

    <Field
      name={WorkPacketFilter.PacketStage}
      component={SelectField}
      label="Packet Stage"
      options={staticFilterOptions[WorkPacketFilter.PacketStage]}
    />

    <Field
      name={WorkPacketFilter.CurrentPacketOwner}
      component={AutocompleteField}
      label={getPacketOwnerLabel(view, userNickname)}
      options={dynamicFilterOptions[WorkPacketFilter.CurrentPacketOwner]}
      getOptionLabel={(option: WorkPacketOwner) => option.title}
      placeholder="Current Packet Owner"
      setFieldValue={setFieldValue}
      disabled={view !== WorkPacketView.AllPackets}
    />

    <Field
      name={WorkPacketFilter.CurrentAction}
      component={AutocompleteField}
      label="Current Action"
      options={staticFilterOptions[WorkPacketFilter.CurrentAction]}
      groupBy={(option: any) => option.category}
      getOptionLabel={(option: any) => option.title}
      placeholder="Current Action"
      setFieldValue={setFieldValue}
    />

    <Field
      name={WorkPacketFilter.RecoveryStreamType}
      component={AutocompleteField}
      label="Recovery Stream Type"
      options={dynamicFilterOptions[WorkPacketFilter.RecoveryStreamType]}
      getOptionLabel={(option: any) => option.title}
      placeholder="Recovery Stream Type"
      setFieldValue={setFieldValue}
    />

    <Field
      name={WorkPacketFilter.RecoveryStreamSubtype1}
      component={AutocompleteField}
      label="Recovery Stream Subtype 1"
      options={dynamicFilterOptions[WorkPacketFilter.RecoveryStreamSubtype1]}
      getOptionLabel={(option: any) => option.title}
      placeholder="Recovery Stream Subtype 1"
      setFieldValue={setFieldValue}
    />

    <Field
      name={WorkPacketFilter.RecoveryStreamSubtype2}
      component={AutocompleteField}
      label="Recovery Stream Subtype 2"
      options={dynamicFilterOptions[WorkPacketFilter.RecoveryStreamSubtype2]}
      getOptionLabel={(option: any) => option.title}
      placeholder="Recovery Stream Subtype 2"
      setFieldValue={setFieldValue}
    />

    <Box gridColumn={{ xs: "span 1", sm: "span 2" }}>
      <Field
        name={WorkPacketFilter.VcPoId}
        as={TextField}
        variant="outlined"
        placeholder="VC PO ID"
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
      />
    </Box>

    <Box gridColumn={{ xs: "span 1", sm: "span 2" }}>
      <Field
        name={WorkPacketFilter.AsinId}
        as={TextField}
        variant="outlined"
        placeholder="Filter by ASIN"
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  </Box>
);
