import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Icon } from "components/UI/Icon";

interface SidebarLogoutProps {
  title: string;
  onClick: () => void;
  minimized?: boolean;
}

const SidebarLogout = ({ title, onClick, minimized }: SidebarLogoutProps) => {
  return (
    <Tooltip
      title={title}
      placement="right-end"
      disableHoverListener={!minimized}
    >
      <Button
        onClick={onClick}
        style={{
          color: "black",
          borderRadius: "8px",
          textDecoration: "none",
          padding: "10px 12px",
          width: "100%",
          justifyContent: "flex-start",
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#FFE4E8",
            svg: {
              color: "#F7104D",
            },
          },
        }}
      >
        <Box display="flex" gap="8px" alignItems="center">
          <Icon
            name="logout"
            color="inherit"
            width="24px"
            height="24px"
            style={{ minWidth: "24px" }}
          />
          <Box display="flex" width="100%" textTransform="none">
            <Typography
              variant="body2"
              noWrap
              sx={{
                opacity: minimized ? 0 : 1,
                visibility: minimized ? "hidden" : "visible",
                transition: "opacity 0.3s",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Button>
    </Tooltip>
  );
};

export default SidebarLogout;
