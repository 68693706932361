import { TextField, FormControl } from "@mui/material";
import { FC } from "react";
import { FieldProps } from "formik";

interface TextAreaFieldProps extends FieldProps {
  name: string;
  label: string;
  rows?: number;
  disabled?: boolean;
}

/**
 * TextAreaField component integrates Material-UI's TextField component with Formik.
 *
 * @param {FieldProps} field - Formik field prop, automatically injected by Formik.
 * @param {object} form - Formik form prop, automatically injected by Formik.
 * @param {string} label - The label for the textarea field.
 * @param {number} rows - The number of rows for the textarea.
 * @param {boolean} disabled - Whether the textarea field is disabled.
 */
const TextAreaField: FC<TextAreaFieldProps> = ({
  field,
  label,
  rows = 4,
  disabled,
}) => {
  return (
    <FormControl size="small" fullWidth disabled={disabled}>
      <TextField
        {...field}
        label={label}
        multiline
        rows={rows}
        variant="outlined"
        disabled={disabled}
        fullWidth
      />
    </FormControl>
  );
};

export default TextAreaField;
