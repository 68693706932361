import { useState, useEffect } from "react";

//Packages

import PaginationButtons from "../../../../components/Pagination/PaginationButtons";
import TotalServices from "../../../../TotalServices";
import ConfigurationTable from "./ConfigurationTable";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import TableLoader from "../../../../components/Loader/TableLoader";
import Search from "../../../../components/Search/Search";

const Configuration = () => {
  useEffect(() => {
    document.title = "Charge Guard | Configuration";
  }, []);

  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  //modal
  const [tableData, setTableData] = useState([]);

  // Pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchRecord, setSearchRecord] = useState(0);
  const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(5);

  const [currentPage, setCurrentPage] = useState(1);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(3);

  //offcanvas




  // Get Data
  const GetConfiguration = async () => {
    try {
      setLoader(true);
      let response = await TotalServices.ListConfiguration(
        NumberOfRecordsPerPage,
        (currentPage - 1) * NumberOfRecordsPerPage,

        { query: searchQuery }
      );
      if (response.status === 200) {
        // console.log(response);
        setTableData(response.data.clients);
        setTotalPages(response?.data?.pages);
        setTotalRecords(response?.data?.total_records);
        setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage);

        setLoader(false);
      }
    } catch (e) {
    }
  };

  useEffect(() => {
    GetConfiguration();
  }, [currentPage]);

  // edit Configuration
 

  return (
    <div className="container">
      <Search
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        GetData={GetConfiguration}
        placeholder="Search "
      />
      <br></br>

      <div className="container-sm table_main">
        {!loader ? (
          <>
            {tableData.length >= 1 ? (
              <>
                <ConfigurationTable data={tableData}  setLoader={setLoader} GetData={GetConfiguration}/>

                <PaginationButtons
                  totalRecords={totalRecords}
                  setRecord={setSearchRecord}
                  record={searchRecord}
                  NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setGoto={setGoto}
                  goto={goto}
                  totalPages={totalPages}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </>
        ) : (
          <TableLoader />
        )}
      
      </div>
    </div>
  );
};

export default Configuration;
