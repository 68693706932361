/**
 * @file This file contains the Material UI theme configuration for Work Packets page only.
 */

import { createTheme } from "@mui/material/styles";
import {
  button,
  textField,
  autocomplete,
  dataGrid,
  tabs,
  dialog,
} from "./components/index.ts";
import { typography } from "./typography.ts";
import { palette } from "./palette.ts";

const theme = createTheme({
  components: {
    ...button,
    ...textField,
    ...autocomplete,
    ...dataGrid,
    ...tabs,
    ...dialog,
  },
  palette,
  typography,

  // TODO: shadows
});

export default theme;
