import { WorkPacketAction, WorkPacketActionName } from "src/types/work-packets";

const mapActionNameToWorkPacketActionObject: Record<
  WorkPacketActionName,
  WorkPacketAction
> = {
  [WorkPacketActionName.FirstDisputeIdentified]: {
    value: WorkPacketActionName.FirstDisputeIdentified,
    title: "First Dispute Identified",
    category: "First Dispute",
    color: "#FFD878",
  },
  [WorkPacketActionName.FirstDisputeFailed]: {
    value: WorkPacketActionName.FirstDisputeFailed,
    title: "First Dispute Failed",
    category: "First Dispute",
    color: "#ff0000",
  },
  [WorkPacketActionName.FirstDisputeCreated]: {
    value: WorkPacketActionName.FirstDisputeCreated,
    title: "First Dispute Created",
    category: "First Dispute",
    color: "#FFCD4D",
  },
  [WorkPacketActionName.FirstDisputeCreatedInReview]: {
    value: WorkPacketActionName.FirstDisputeCreatedInReview,
    title: "First Dispute Created",
    category: "First Dispute",
    color: "#FFCD4D",
  },
  [WorkPacketActionName.FirstDisputeApproved]: {
    value: WorkPacketActionName.FirstDisputeApproved,
    title: "First Dispute Resolved - Approved",
    category: "First Dispute",
    color: "#39D4A5",
  },
  [WorkPacketActionName.FirstDisputePartiallyApproved]: {
    value: WorkPacketActionName.FirstDisputePartiallyApproved,
    title: "First Dispute Resolved - Partially Approved",
    category: "First Dispute",
    color: "#318FFF",
  },
  [WorkPacketActionName.FirstDisputeDenied]: {
    value: WorkPacketActionName.FirstDisputeDenied,
    title: "First Dispute Resolved - Denied/More Info Needed",
    category: "First Dispute",
    color: "#FF820F",
  },
  [WorkPacketActionName.FirstDisputePaid]: {
    value: WorkPacketActionName.FirstDisputePaid,
    title: "First Dispute - VC Client Payment",
    category: "First Dispute",
    color: "#7B3DFF",
  },
  [WorkPacketActionName.FirstDisputeInvoiced]: {
    value: WorkPacketActionName.FirstDisputeInvoiced,
    title: "First Dispute - C6 Invoices Client",
    category: "First Dispute",
    color: "#F155FF",
  },
  [WorkPacketActionName.SecondDisputeIdentified]: {
    value: WorkPacketActionName.SecondDisputeIdentified,
    title: "Second Dispute Identified",
    category: "Second Dispute",
    color: "#FFD878",
  },
  [WorkPacketActionName.SecondDisputeFailed]: {
    value: WorkPacketActionName.SecondDisputeFailed,
    title: "Second Dispute Failed",
    category: "Second Dispute",
    color: "#ff0000",
  },
  [WorkPacketActionName.SecondDisputeCreatedInReview]: {
    value: WorkPacketActionName.SecondDisputeCreatedInReview,
    title: "Second Dispute Created",
    category: "Second Dispute",
    color: "#FFCD4D",
  },
  [WorkPacketActionName.SecondDisputeApproved]: {
    value: WorkPacketActionName.SecondDisputeApproved,
    title: "Second Dispute Resolved - Approved",
    category: "Second Dispute",
    color: "#39D4A5",
  },
  [WorkPacketActionName.SecondDisputePartiallyApproved]: {
    value: WorkPacketActionName.SecondDisputePartiallyApproved,
    title: "Second Dispute Resolved - Partially Approved",
    category: "Second Dispute",
    color: "#318FFF",
  },
  [WorkPacketActionName.SecondDisputeDenied]: {
    value: WorkPacketActionName.SecondDisputeDenied,
    title: "Second Dispute Resolved - Denied",
    category: "Second Dispute",
    color: "#FF820F",
  },
  [WorkPacketActionName.SecondDisputePaid]: {
    value: WorkPacketActionName.SecondDisputePaid,
    title: "Second Dispute - VC Client Payment",
    category: "Second Dispute",
    color: "#7B3DFF",
  },
  [WorkPacketActionName.SecondDisputeInvoiced]: {
    value: WorkPacketActionName.SecondDisputeInvoiced,
    title: "Second Dispute - C6 Invoices Client",
    category: "Second Dispute",
    color: "#F155FF",
  },
  [WorkPacketActionName.Junk]: {
    value: WorkPacketActionName.Junk,
    title: "Junk",
    category: "Other Actions",
    color: "#E03741",
  },
  [WorkPacketActionName.Duplicate]: {
    value: WorkPacketActionName.Duplicate,
    title: "Duplicate",
    category: "Other Actions",
    color: "#EAECF0",
  },
};

export default mapActionNameToWorkPacketActionObject;
