import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { updateVendor } from "../../Api/Vendors/Vendors";
import Loader from "../../../../../components/Loader/Loader";
import useVendorById from "../../hooks/useVendorById";
import useVendorReferrals from "../../hooks/useVendorReferrals";
import { Formik } from "formik";
import { vendorSchema } from "../../util/schema";
import { mapToUpdateVendor } from "../../util/mapper";
import VendorForm from "../VendorForm/VendorForm";

const UpdateVendor = ({ vendorID }) => {
  const [referralAll, setReferralAll] = useState([]);

  const {
    data: vendor,
    loading: loadingVendor,
    isRequestCompleted: vendorRequestCompleted,
  } = useVendorById(vendorID);

  const {
    data: vendorReferrals,
    loading: loadingReferrals,
    isRequestCompleted: referralRequestCompleted,
  } = useVendorReferrals();

  useEffect(() => {
    setReferralAll(
      vendorReferrals?.map((referral) => {
        return { label: referral.NAME, value: referral.ID.toString() };
      })
    );
  }, [loadingVendor, loadingReferrals, vendorReferrals]);

  const loading =
    loadingVendor ||
    loadingReferrals ||
    !vendorRequestCompleted ||
    !referralRequestCompleted;

  const handleVendorUpdate = async (values, { resetForm }) => {
    const updatedData = {};
    const relevantFields = [
      "REFERRAL",
      "REFERRAL_COMMISSION_RATE",
      "REFERRAL_COMMISSION_DURATION",
      "REFERRAL_PARTNER_ID",
      "REFERRAL_PAYMENT_METHOD",
      "REFERRAL_PARTNER_TYPE",
      "REFERRAL_CUSTOM_COMMISSION_RATE",
      "REFERRAL_2",
      "REFERRAL_2_COMMISSION_RATE",
      "REFERRAL_2_COMMISSION_DURATION",
      "REFERRAL_2_PARTNER_ID",
      "REFERRAL_2_PAYMENT_METHOD",
      "REFERRAL_2_PARTNER_TYPE",
      "REFERRAL_2_CUSTOM_COMMISSION_RATE",
    ];

    let relevantFieldsUpdated = false;

    Object.keys(values).forEach((key) => {
      if (isFieldUpdated(key, values)) {
        updatedData[key] = values[key];
      }
    });

    relevantFields.forEach((key) => {
      if (isFieldUpdated(key, values)) {
        relevantFieldsUpdated = true;
      }
    });

    if (relevantFieldsUpdated) {
      relevantFields.forEach((key) => {
        updatedData[key] = values[key];
      });
    }
    const mappedData = mapToUpdateVendor(updatedData);
    await updateVendor(vendorID, mappedData, toast);
    resetForm({ values });
  };

  const initialValues = {
    VENDOR_ID: `V${vendorID}`,
    REFERRAL: vendor?.REFERRALS[0]?.REFERRAL_PARTNER_NAME ?? null,
    REFERRAL_COMMISSION_RATE: vendor?.REFERRALS[0]?.COMMISSION_RATE ?? null,
    REFERRAL_COMMISSION_DURATION: vendor?.REFERRALS[0]
      ?.COMMISSION_ELIGIBILITY_END_AT
      ? new Date(vendor?.REFERRALS[0]?.COMMISSION_ELIGIBILITY_END_AT)
          .toISOString()
          .split("T")[0]
      : null,
    REFERRAL_PARTNER_ID: vendor?.REFERRALS[0]?.PARTNER_ID ?? null,
    REFERRAL_PAYMENT_METHOD: vendor?.REFERRALS[0]?.PAYMENT_METHOD ?? null,
    REFERRAL_PARTNER_TYPE: vendor?.REFERRALS[0]?.PARTNER_TYPE ?? null,
    REFERRAL_CUSTOM_COMMISSION_RATE: vendor?.REFERRALS[0]?.CUSTOM_COMMISSION_RATE ?? null,
    REFERRAL_2: vendor?.REFERRALS[1]?.REFERRAL_PARTNER_NAME || null,
    REFERRAL_2_COMMISSION_RATE: vendor?.REFERRALS[1]?.COMMISSION_RATE ?? null,
    REFERRAL_2_COMMISSION_DURATION: vendor?.REFERRALS[1]
      ?.COMMISSION_ELIGIBILITY_END_AT
      ? new Date(vendor?.REFERRALS[1]?.COMMISSION_ELIGIBILITY_END_AT)
          .toISOString()
          .split("T")[0]
      : null,
    REFERRAL_2_PARTNER_ID: vendor?.REFERRALS[1]?.PARTNER_ID ?? null,
    REFERRAL_2_PAYMENT_METHOD: vendor?.REFERRALS[1]?.PAYMENT_METHOD ?? null,
    REFERRAL_2_PARTNER_TYPE: vendor?.REFERRALS[1]?.PARTNER_TYPE ?? null,
    REFERRAL_2_CUSTOM_COMMISSION_RATE: vendor?.REFERRALS[1]?.CUSTOM_COMMISSION_RATE ?? null,
    SPECIFIC_POINT_OF_CONTACT_NAME:
      vendor?.SPECIFIC_POINT_OF_CONTACT_NAME ?? "",
    SPECIFIC_POINT_OF_CONTACT_EMAIL:
      vendor?.SPECIFIC_POINT_OF_CONTACT_EMAIL ?? "",
    VENDOR_NAME: vendor?.VENDOR_NAME ?? "",
    TIER: vendor?.TIER ?? "",
    INITIAL_COMMISSION_RATE: vendor?.INITIAL_COMMISSION_RATE ?? null,
    FINAL_COMMISSION_RATE: vendor?.FINAL_COMMISSION_RATE ?? null,
    ENGAGEMENT_FEE: vendor?.ENGAGEMENT_FEE ?? null,
    CURRENT_RECOVERY: vendor?.CURRENT_RECOVERY ?? 0,
    NOTES: vendor?.NOTES ?? "",
    RECOVERY_THRESHOLD: vendor?.RECOVERY_THRESHOLD ?? null,
    AGREEMENT_SIGNED_DATE: vendor?.AGREEMENT_SIGNED_DATE
      ? new Date(vendor?.AGREEMENT_SIGNED_DATE).toISOString().split("T")[0]
      : "",
    KICK_OFF: vendor?.KICK_OFF
      ? new Date(vendor?.KICK_OFF).toISOString().split("T")[0]
      : null,
    PHONE: vendor?.PHONE ?? "",
    BILLING_NAME: vendor?.BILLING_NAME ?? "",
    BILLING_EMAIL: vendor?.BILLING_EMAIL ?? "",
    VENDOR_TYPE: vendor?.VENDOR_TYPE ?? "",
  };

  const isFieldUpdated = (fieldName, values) => {
    return initialValues[fieldName] !== values[fieldName];
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container mt-4 ">
        <Formik
          initialValues={initialValues}
          onSubmit={handleVendorUpdate}
          validationSchema={vendorSchema}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <VendorForm
              isSubmitting={isSubmitting}
              dirty={dirty}
              isValid={isValid}
              values={values}
              referralAll={referralAll}
              setReferralAll={setReferralAll}
              showVendorID
              buttonCaption="Update Customer"
            />
          )}
        </Formik>
      </div>
    </>
  );
};

export default UpdateVendor;
