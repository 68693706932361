import { WorkPacketView } from "../../WorkPacketView";

export const getPacketOwnerLabel = (view: WorkPacketView, userNickname: string): string => {
  switch (view) {
    case WorkPacketView.MyPackets:
      return userNickname;
    case WorkPacketView.NewPackets:
      return "Unassigned";
    default:
      return "Current Packet Owner";
  }
};
