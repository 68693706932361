/**
 * This custom hook manages the fetching, filtering, sorting, and paginating of work packets.
 * Note: This hook is designed to work with MUI's DataGrid component.
 */

import { useState, useEffect, useCallback, useMemo } from "react";
import {
  type GridFilterModel,
  type GridRowSelectionModel,
  type GridSortModel,
} from "@mui/x-data-grid";
import {
  type LocalWorkPacketTypeMap,
  type WorkPacket,
  WorkPacketFilter,
  type WorkPacketFilters,
} from "src/types/work-packets";
import { useAuth0 } from "@auth0/auth0-react";
import { getFlatRow } from "./util/getFlatRow";
import { WorkPacketView } from "./WorkPacketView";
import * as WorkPacketsAPI from "./api/workPacketsAPI";
import debounce from "lodash/debounce";
import toast from "react-hot-toast";
import { useWorkPacketsContext } from "./WorkPacketsContext";
import { WorkPacketType } from "./WorkPacketType";

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface UseWorkPacketsReturnType<T extends WorkPacketType> {
  /** Grid rows */
  data: LocalWorkPacketTypeMap[T][];
  /** Pagination model: current page and page size */
  paginationModel: PaginationModel;
  /** Total number of rows (not just the current page) */
  total: number;
  /** Loading state while fetching data */
  loading: boolean;
  /** Filter values */
  filters: WorkPacketFilters;
  /** Update filter values */
  setFilters: (filters: WorkPacketFilters) => void;
  /** Dynamic filter options like store, current owner. */
  dynamicFilterOptions: Record<string, any[]>;
  /** Selected rows (row IDs) including all pages */
  rowSelection: GridRowSelectionModel;
  /** Update selected rows */
  setRowSelection: (newSelection: GridRowSelectionModel) => void;
  /** Keeps track of whether user clicked "Select All" */
  selectAllEnabled: boolean;
  /** Rows that should be excluded from selection when "Select All" is clicked */
  excludeWorkPacketIds: string[];
  /** Callbacks for grid events */
  callbacks: {
    /** Triggered on any pagination change */
    onPaginationModelChange: (model: {
      page: number;
      pageSize: number;
    }) => void;
    /** Triggered on any filter change */
    onFilterModelChange: (filterModel: GridFilterModel) => void;
    /** Triggered on any sort change */
    onSortModelChange: (sortModel: GridSortModel) => void;
    /** Triggered when a row is updated */
    processRowUpdate: (updatedRow: any, originalRow: any) => any;
    /** Triggered when a row update fails */
    onProcessRowUpdateError: (error: any) => void;
    /** Triggered when row selection changes */
    onRowSelectionModelChange: (newSelection: GridRowSelectionModel) => void;
  };

  /** Force trigger a fetch of work packets */
  triggerFetchWorkPackets: () => void;

  /** Set the row update signal */
  rowUpdateSignal: boolean;

  /** Call API to export work packets */
  handleExport: () => void;

  /** Export status */
  isExporting: boolean;
}

const computeFilters = (filters: WorkPacketFilters, view: WorkPacketView, userNickname?: string) => ({
  ...filters,
  /* Force current packet owner filter if the view is "My Packets" */
  [WorkPacketFilter.CurrentPacketOwner]: view === WorkPacketView.MyPackets
  ? userNickname || ""
  : view === WorkPacketView.NewPackets
    ? "UNASSIGNED"
    : filters[WorkPacketFilter.CurrentPacketOwner],
});

/**
 * Custom hook to manage fetching, filtering, sorting, and paginating work packets.
 */
export const useWorkPacketsGrid = <T extends WorkPacketType>(workPacketType: T): UseWorkPacketsReturnType<T> => {
  type LocalWorkPacket = LocalWorkPacketTypeMap[T];
  const { user } = useAuth0();
  const [data, setData] = useState<LocalWorkPacket[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const { currentView: view, currentFilters, setFilters } = useWorkPacketsContext();

  const computedFilters = useMemo(
    () => computeFilters(currentFilters, view, user?.sub),
    [view, currentFilters, user?.sub],
  );

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([]);
  const [excludedFromSelection, setExcludedFromSelection] = useState<string[]>(
    []
  );
  const [selectAllMode, setSelectAllMode] = useState<
    /** TODO: Could be simplified to keep select-all only  */
    "select-all" | "deselect-all" | null
  >(null);

  const [dynamicFilterOptions, setDynamicFilterOptions] = useState<{
    storeName: any[];
    currentPacketOwner: any[];
    recoveryStreamType: { value: string; title: string }[];
    recoveryStreamSubtype1: { value: string; title: string }[];
    recoveryStreamSubtype2: { value: string; title: string }[];
  }>({
    storeName: [],
    currentPacketOwner: [],
    recoveryStreamType: [],
    recoveryStreamSubtype1: [],
    recoveryStreamSubtype2: [],
  });

  /*
   * Used to let the component know that the row has been updated.
   * Usefull for sharing info between non-nested components.
   * Flag value doesn't matter.
   */
  const [rowUpdateSignal, setRowUpdateSignal] = useState(false);

  const fetchWorkPacketsData = useCallback(
    async (
      paginationModel: PaginationModel,
      filters: WorkPacketFilters,
      sorting: GridSortModel
    ) => {
      if (!user || !user.sub) return;

      const sortObject: Record<string, string> = {};
      if (sorting.length > 0 && !!sorting[0].sort) {
        sortObject[sorting[0].field as string] = sorting[0].sort;
      }

      try {
        setLoading(true);
        const data = await WorkPacketsAPI.fetchWorkPackets({
          userId: user.sub,
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          filters,
          sorting: sortObject,
          type: workPacketType,
        });

        setData(data.workPackets as LocalWorkPacket[]);
        setTotal(data.totalRecords);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    },
    [user, view, workPacketType],
  );

  /* Preventing multiple requests especially on initial load */
  const debouncedFetchWorkPacketsData = useCallback(
    debounce(
      (paginationModel, filters, sorting) => {
        fetchWorkPacketsData(paginationModel, filters, sorting);
      },
      300,
      { leading: true, trailing: false }
    ),
    [fetchWorkPacketsData]
  );

  useEffect(() => {
    if (!user?.sub) return;

    debouncedFetchWorkPacketsData(
      paginationModel,
      computedFilters,
      sortModel,
    );
  }, [paginationModel, computedFilters, fetchWorkPacketsData, view, user, sortModel]);

  useEffect(() => {
    if (selectAllMode === "select-all") {
      const currentPageRowIds = data.map((row) => row.packetId);

      // Select all rows on the current page excluding the ones that are excluded
      setRowSelection((prevSelection) => [
        ...prevSelection,
        ...currentPageRowIds.filter(
          (id) =>
            !prevSelection.includes(id) && !excludedFromSelection.includes(id)
        ),
      ]);
    } else if (selectAllMode === "deselect-all") {
      setRowSelection([]);
      setExcludedFromSelection([]);
    } else {
      setExcludedFromSelection([]);
    }
  }, [paginationModel.page, selectAllMode, data]);

  const triggerFetchWorkPackets = () => {
    if (!user?.sub) return;

    fetchWorkPacketsData(
      paginationModel,
      computedFilters,
      sortModel,
    );
  };

  const fetchDynamicOptions = useCallback(async (workPacketType: WorkPacketType) => {
    if (workPacketType === WorkPacketType.CHARGEBACKS) {
      const [accountsData, usersData, recoveryStreamFiltersData] =
        await Promise.all([
          WorkPacketsAPI.fetchAccounts(),
          WorkPacketsAPI.fetchUsers(),
          WorkPacketsAPI.fetchRecoveryStreamFilters(workPacketType),
        ]);

      setDynamicFilterOptions({
        storeName: accountsData,
        currentPacketOwner: usersData,
        ...recoveryStreamFiltersData,
      });
    } else if (workPacketType === WorkPacketType.SHORTAGES) {
      const [accountsData, usersData] =
        await Promise.all([
          WorkPacketsAPI.fetchAccounts(),
          WorkPacketsAPI.fetchUsers(),
        ]);

      setDynamicFilterOptions({
        storeName: accountsData,
        currentPacketOwner: usersData,
        recoveryStreamSubtype1: [],
        recoveryStreamSubtype2: [],
        recoveryStreamType: [],
      });
    } else {
      console.error(`Unknown work packet type ${workPacketType}`);
    }
  }, []);

  /* Preventing multiple requests especially on initial load */
  const debouncedFetchDynamicOptions = useCallback(
    debounce(
      (workPacketType: WorkPacketType) => {
        fetchDynamicOptions(workPacketType);
      },
      300,
      { leading: true, trailing: false },
    ),
    [fetchDynamicOptions],
  );

  useEffect(() => {
    debouncedFetchDynamicOptions(workPacketType);
  }, [debouncedFetchDynamicOptions, workPacketType]);

  const handlePaginationModelChange = useCallback(
    (model: { page: number; pageSize: number }) => {
      setPaginationModel(model);
    },
    []
  );

  const handleRowUpdate = async (
    updatedRow: LocalWorkPacket,
    originalRow: LocalWorkPacket,
  ) => {
    try {
      setLoading(true);

      if (!user?.sub) {
        console.error("User not found");
        return;
      }

      const updatedFlatRow = getFlatRow(updatedRow);
      const originalFlatRow = getFlatRow(originalRow);

      const changedFields = Object.keys(updatedFlatRow).filter(
        (key) =>
          updatedFlatRow[key] !== originalFlatRow[key as keyof WorkPacket]
      );

      if (changedFields.length === 0) {
        return originalRow;
      }

      await WorkPacketsAPI.updateWorkPacket({
        workPacketType: workPacketType,
        workPacketId: updatedRow.packetId,
        userId: user.sub,
        updatedFlatRow,
        changedFields,
      });

      const updatedData = data.map(row => row.packetId === originalRow.packetId ? updatedRow : row);

      setData(updatedData);
      setRowUpdateSignal((prev) => !prev);
      return updatedRow;
    } catch (error) {
      console.error("Error updating row:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowError = (error: any) => {
    console.error("Error updating row", error);
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    console.info("Grid Filter change", { filterModel });
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleRowSelection = (newSelection: GridRowSelectionModel) => {
    /* TODO: THINK ABOUT CORNER CASES */

    /* Check if the selection is a bulk selection */
    const totalChanges = Math.abs(newSelection.length - rowSelection.length);
    const isBulkSelection = totalChanges > 1;
    if (isBulkSelection) {
      const isSelectAll = newSelection.length > rowSelection.length;
      setSelectAllMode(isSelectAll ? "select-all" : "deselect-all");

      if (!isSelectAll) {
        triggerFetchWorkPackets();
        setExcludedFromSelection([]);
        setSelectAllMode(null);
        setRowSelection([]);
        return;
      }

      console.info(
        "Bulk selection",
        isSelectAll ? "Select All" : "Deselect All"
      );
    }

    // If selected all check for exclusion:
    if (selectAllMode === "select-all") {
      const excludedIds = data
        .filter((row) => !newSelection.includes(row.packetId))
        .map((row) => row.packetId);

      // Include unique IDs to be added to exclude list
      setExcludedFromSelection((prev) => {
        const uniqueIds = Array.from(new Set([...prev, ...excludedIds]));
        return uniqueIds;
      });
    }

    setRowSelection(newSelection);
  };

  const handleExport = useCallback(async () => {
    // Limiting export to 100k rows
    if (total >= 100000) {
      toast.error(
        "Export row limit of 100k exceeded. Please apply some filters to the data set and try again.",
        {
          duration: 4000,
        }
      );
      return;
    }

    if (!user || !user.sub) return;

    try {
      setIsExporting(true);
      await WorkPacketsAPI.exportWorkPackets({
        workPacketType: workPacketType,
        userId: user.sub,
        filters: computedFilters,
      });

      toast.success("Export completed!");
    } catch (error) {
      console.error("Error while exporting:", error);
      toast.error("Error exporting work packets");
    } finally {
      setIsExporting(false);
    }
  }, [user, computedFilters, total, workPacketType]);

  return {
    data,
    paginationModel,
    filters: computedFilters,
    total,
    loading,
    rowSelection,
    setRowSelection,
    setFilters,
    selectAllEnabled: selectAllMode === "select-all",
    excludeWorkPacketIds: excludedFromSelection,
    dynamicFilterOptions,
    triggerFetchWorkPackets,
    rowUpdateSignal,
    handleExport,
    isExporting,
    callbacks: {
      onPaginationModelChange: handlePaginationModelChange,
      onFilterModelChange: onFilterChange,
      onSortModelChange: handleSortModelChange,
      processRowUpdate: handleRowUpdate,
      onProcessRowUpdateError: handleRowError,
      onRowSelectionModelChange: handleRowSelection,
    },
  };
};
