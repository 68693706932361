import { Scope } from "../types";

const getSelectOptionsByName = (fieldName: keyof Scope) => {
  switch (fieldName) {
    case "NAME":
      return [
        { value: "price_claims", label: "Price Claims" },
        { value: "shortages", label: "Shortages" },
        { value: "chargebacks", label: "Chargebacks" },
        { value: "accruals", label: "Accruals" },
      ];
    case "IS_HISTORIC":
      return [
        { value: "", label: "Select Historic" },
        { value: "false", label: "False" },
        { value: "true", label: "True" },
      ];
    default:
      return [];
  }
};

interface SelectCellProps {
  dataValue?: string | null | boolean;
  isEditableRow: boolean;
  formik: any;
  handleChange: (field: keyof Scope, value: any) => void;
  name: keyof Scope;
}

const SelectCell = ({
  dataValue,
  isEditableRow,
  formik,
  handleChange,
  name,
}: SelectCellProps) => {
  const options = getSelectOptionsByName(name);

  const getStaticValue = (value?: string | null | boolean) => {
    if (value === null) return "N/A";
    if (typeof value === "boolean") return value ? "True" : "False";
    return value;
  };

  if (!isEditableRow) return <td>{getStaticValue(dataValue)}</td>;

  return (
    <td>
      <select
        className="form-control"
        name={name}
        value={formik.values[name]}
        onChange={(e) => handleChange(name, e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </td>
  );
};

export default SelectCell;
