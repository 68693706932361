import usePermissions from "components/CustomHooks/usePermissions";
import { FC } from "react";
import { Table } from "react-bootstrap";
import ScopeRow from "./ScopeRow";
import formatDatesInScopeData from "./utils/formatDatesInScopeData";
import { Scope } from "./types";

interface ScopeTableProps {
  scopesData: Scope[];
  getAccountsScopesAll: any;
  setChangesInScopes: any;
}

const ScopeTable: FC<ScopeTableProps> = ({
  scopesData,
  getAccountsScopesAll,
  setChangesInScopes,
}) => {
  const { hasPermission } = usePermissions();
  const formattedScopesData = formatDatesInScopeData(scopesData);

  return (
    <div>
      <h4>Scopes</h4>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Activation Date</th>
            <th>Deactivation Date</th>
            <th>Historic</th>
            <th>Start Date Under Scope (invoice start date)</th>
            <th>End Date Under Scope (invoice due date)</th>
            <th>Notes</th>
            {hasPermission(["admin:*", "user:client_config:write:*"]) && (
              <th>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {formattedScopesData.map((data, index) => (
            <ScopeRow
              key={index}
              data={data}
              setChangesInScopes={setChangesInScopes}
              getAccountsScopesAll={getAccountsScopesAll}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ScopeTable;
