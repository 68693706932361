import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState, type FC } from "react";
import { getPermissionsList } from "src/queries/users";
import { ADMIN_PERMISSION, getPermissionFeatureLabel, getPermissionFromFeatureType, parsePermissionsList, parseUserPermissions, type ReadWriteState } from "./permission-parser";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import css from "./PermissionSelect.module.css";

export interface PermissionSelectProps {
  permissions: string[];
  onPermissionsChange: (newPermissions: string[]) => void;
}

export const PermissionSelect: FC<PermissionSelectProps> = ({ permissions: userPermissions, onPermissionsChange }) => {
  const allPermissionsQuery = useQuery({ queryFn: getPermissionsList, queryKey: ['permissions-list'], staleTime: 60000 });
  const allPermissions = useMemo(parsePermissionsList.bind(null, allPermissionsQuery.data || []), [allPermissionsQuery.data]);

  const isAdmin = useMemo(() => userPermissions.includes(ADMIN_PERMISSION), [userPermissions]);

  // when the "Admin" checkbox is checked, we backup the chosen permissions in case the user wants to undo
  // the backed up permissions are still shown, but grayed out and unselectable
  const [userPermissionsBackup, setUserPermissionsBackup] = useState<string[] | null>(null);
  const displayedPermissions = userPermissionsBackup ?? userPermissions;

  const userPermissionsState = useMemo(
    () => parseUserPermissions(allPermissions, displayedPermissions),
    [allPermissions, displayedPermissions],
  );

  const setAdminState = useCallback((adminState: boolean) => {
    if (adminState) {
      setUserPermissionsBackup(userPermissions);
      onPermissionsChange([ADMIN_PERMISSION]);
    } else {
      onPermissionsChange(userPermissionsBackup ?? []);
      setUserPermissionsBackup(null);
    }
  }, [userPermissions]);

  const handleReadWriteStateChange = useCallback((feature: string, newState: ReadWriteState) => {
    const readPermission = getPermissionFromFeatureType(feature, "read");
    const writePermission = getPermissionFromFeatureType(feature, "write");
    const permissionSet = new Set(userPermissions);
    if (newState === "none") {
      permissionSet.delete(readPermission);
      permissionSet.delete(writePermission);
    } else if (newState === "read") {
      permissionSet.add(readPermission);
      permissionSet.delete(writePermission);
    } else if (newState === "read-write") {
      permissionSet.add(readPermission);
      permissionSet.add(writePermission);
    }
    onPermissionsChange(Array.from(permissionSet));
  }, [userPermissions, onPermissionsChange]);

  const handleCheckboxStateChange = useCallback((permission: string, newState: boolean) => {
    const permissionSet = new Set(userPermissions);
    if (newState) {
      permissionSet.add(permission);
    } else {
      permissionSet.delete(permission);
    }
    onPermissionsChange(Array.from(permissionSet));
  }, [userPermissions, onPermissionsChange]);

  if (allPermissionsQuery.isLoading) {
    return (
      <div>
        <p>Loading permissions list...</p>
      </div>
    );
  }

  if (allPermissionsQuery.isError) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem" }}>
        <p>Failed to get permissions list.</p>
        <Button variant="contained" onClick={() => allPermissionsQuery.refetch()}>Try again?</Button>
      </div>
    );
  }

  return (
    <div>
      <Typography fontWeight={500} sx={{ color: "var(--primary)" }}>Permissions</Typography>
      <div className={css.topLevelPermissionsGrid}>
        <div className={css.permissionBox}>
          <FormControlLabel
            label="Admin (access to all permissions below)"
            control={
              <Checkbox
                color="success"
                checked={isAdmin}
                onChange={e => setAdminState(e.target.checked)}
              />
            }
          />
        </div>
        <div className={css.permissionBoxGrid} {...isAdmin && { inert: '', style: { opacity: 0.5 } }}>
          {Object.entries(userPermissionsState.readWrite).map(([permissionFeature, state]) => (
            <div key={permissionFeature} className={css.permissionBox}>
              <span>{getPermissionFeatureLabel(permissionFeature)}</span>
              <ReadWritePermissionWidget featureType={permissionFeature} state={state} onStateChange={handleReadWriteStateChange} />
            </div>
          ))}
        </div>
        {Object.keys(userPermissionsState.misc).length > 0 && (
          <div className={css.permissionBoxGrid} {...isAdmin && { inert: '', style: { opacity: 0.5 } }}>
            {Object.entries(userPermissionsState.misc).map(([permission, state]) => (
              <div key={permission} className={css.permissionBox}>
                <FormControlLabel
                  label={permission}
                  control={
                    <Checkbox
                      color="success"
                      checked={state}
                      onChange={e => handleCheckboxStateChange(permission, e.target.checked)}
                    />
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
};


interface ReadWritePermissionWidgetProps {
  featureType: string;
  state: ReadWriteState;
  onStateChange: (featureType: string, state: ReadWriteState) => void;
}

const ReadWritePermissionWidget: FC<ReadWritePermissionWidgetProps> = ({ featureType, state, onStateChange }) => {
  return (
    <ToggleButtonGroup
      color="success"
      value={state}
      exclusive
      onChange={e => onStateChange(featureType, (e.target as HTMLButtonElement).value as ReadWriteState)}
      aria-label={featureType}
    >
      <ToggleButton value="none">None</ToggleButton>
      <ToggleButton value="read">Read only</ToggleButton>
      <ToggleButton value="read-write">Read/write</ToggleButton>
    </ToggleButtonGroup>
  )
};
