import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { BsFillInfoCircleFill } from "react-icons/bs";

const UsageHistoryTable = ({ tableData, handleShowInfoModal }) => {
  return (
    <div className="table-container">
      <table className="table">
        <thead className="thead-dark">
          <tr>
            {/* <th scope="col">Id</th> */}
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col">Total </th>
            <th scope="col">Completed </th>
            <th scope="col">Failed</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody>
          {(tableData ?? [0]).length !== 0 &&
            tableData?.map((data, index) => (
              <tr key={index}>
                {/* <td>{data.id}</td> */}
                
                  <td>{data.date}</td>
               

                <td>
                  <button className="btn btn-outline-dark">
                    {data.total === data.completed ? "Done" : "In Progress"}
                  </button>
                </td>
                <td>{data.total}</td>
                <td className="text-success">{data.completed}</td>
                <td className="text-danger">{data.failed}</td>
                <td>
                  <div className="d-flex">
                    <>
                      <button
                        className="btn btn-dark me-2"
                        onClick={() => {
                          handleShowInfoModal(data.id);
                        }}
                      >
                        <BsFillInfoCircleFill />
                      </button>
                    </>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsageHistoryTable;
