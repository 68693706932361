import React, {useEffect, useRef, useState} from "react";
import "./OBAR.css";
//Packages
//Components
import PaginationButtons from "../../../components/Pagination/PaginationButtons";

import Table from "./Table";
import TableLoader from "../../../components/Loader/TableLoader";
import TotalServices from "../../../TotalServices";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import CreatableSelect from "react-select";
import {FaCloudUploadAlt} from "react-icons/fa";
import OBARServices from "../../../Services/OBARServices.js";
import Loader from "../../../components/Loader/Loader.jsx";
import toast from "react-hot-toast";
import {MdOutlineCreateNewFolder} from "react-icons/md";
import AsyncCreatableSelect from "react-select/async-creatable";
import moment from "moment";
import axios from "axios";
import AxiosServices from "../../../Services/axiosServices.js";

const OBAR = () => {

    // Pagination
    const [totalRecords, setTotalRecords] = useState();
    const [searchRecord, setSearchRecord] = useState(0);
    const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [goto, setGoto] = useState("");
    const [totalPages, setTotalPages] = useState(1);

    // Accounts
    const [options, setOptions] = useState([]);
    const [store, setStore] = useState();

    const [tableData, setTableData] = useState([]);
    const [loader, setLoader] = useState(true);

    const [file, setFile] = useState();

    const [uploading, setUploading] = useState(false);

    const inputRef = useRef();

    const [downloading, setDownloading] = useState();
    const [deleting, setDeleting] = useState();

    const [generating, setGenerating] = useState(false);

    const [fetchingAccount, setFetchingAccount] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [abortListEvidence, setAbortListEvidence] = useState();
    const [abortGetStores, setAbortGetStores] = useState();

    useEffect(() => {
        document.title = "Charge Guard | OBAR";
        setMounted(true);
    }, []);

    useEffect(() => {
        if (mounted && options.length === 0) {
            GetStores();
        }
    }, [mounted]);

    useEffect(() => {
        if (downloading !== undefined) {
            toast.loading("Downloading " + downloading + "...", {id: "downloading-obar"});
        } else {
            toast.dismiss("downloading-obar");
        }
    }, [downloading]);

    useEffect(() => {
        if (deleting !== undefined) {
            toast.loading("Deleting " + deleting + "...", {id: "deleting-obar"});
        } else {
            toast.dismiss("deleting-obar");
        }
    }, [deleting]);

    useEffect(() => {
        if (store) {
            GetEvidences()
        }
    }, [store, currentPage]);

    useEffect(() => {
        if (uploading) {
            toast.loading("Uploading File...", {id: "uploading-obar"});
        } else {
            toast.dismiss("uploading-obar");
        }
    }, [uploading]);

    const GetEvidences = async () => {
        setLoader(true);
        const abortController = AxiosServices.handlePreviousRequests(abortListEvidence, setAbortListEvidence)

        try {
            const response = await OBARServices.listEvidences(store.value, currentPage, abortController);

            if (response.status === 200 && response?.data?.success) {
                setTableData(response.data.data);
                setTotalPages(response?.data?.total_pages);
                setCurrentPage(response?.data?.current_page);
                setNumberOfRecordsPerPage(response.data.data.length);
                setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage);
                setTotalRecords(response?.data?.total_count);

                setGoto("")

                setLoader(false);
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                return
            }else{
                throw e;
            }
        }

        setAbortListEvidence(undefined)
    };

    const GetStores = async () => {
        setFetchingAccount(true);
        const abortController = AxiosServices.handlePreviousRequests(abortGetStores, setAbortGetStores)
        try {
            let response = await TotalServices.GetAllAccounts(undefined, undefined, undefined, abortController);
            if (response.status === 200) {
                setOptions([...response?.data?.stores?.map((value) => ({
                    value: value['Account ID'], label: value['Account Name'],
                })),]);
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                return
            }else{
                throw e;
            }
        }
        setFetchingAccount(false);
        setAbortGetStores(undefined)
    };

    const onFileSelected = async (e) => {
        if (e.target?.files?.length > 0) {
            await setFile(e.target.files[0]);
            await OBARServices.uploadEvidences(store.value, e.target.files[0], setUploading, setFile, GetEvidences);
            inputRef.current.value = null;
        }
    }

    const onUploadBtnClicked = () => {
        inputRef.current.click();
    }

    const onGenerateBtnClicked = async () => {
        await setGenerating(true);
        await OBARServices.generateOBAR(store, setGenerating);
    }

    return (<div className="container">

        <div className="text-center mb-3">
            <div className="row d-inline-flex w-100 justify-content-center">
                <div className="col-md-6 col-12">
                    <div className="ms-2 me-2 mt-2 mb-3">
                        <label className="form-label fw-bold">Select Account</label>
                        <CreatableSelect
                            className="basic-single"
                            classNamePrefix="select"
                            name="color"
                            placeholder="Account"
                            isClearable
                            isLoading={options.length === 0 || fetchingAccount}
                            value={store}
                            options={options}
                            onChange={setStore}
                        />
                    </div>
                </div>
            </div>

            {store && <span>
                <button
                    className="btn-primary mt-3 mb-3 me-3"
                    onClick={onGenerateBtnClicked}
                    disabled={generating}
                >
                    {generating ? (<Loader style={{}} color="white"/>) : (<span>
                            <span className="me-1">
                                <MdOutlineCreateNewFolder size="1.3em" className="me-1 self-center" />
                            </span>
                            Generate OBAR
                        </span>)}
                </button>
            </span>}

            {store && <span>
                <input
                    type="file"
                    className="d-none"
                    ref={inputRef}
                    onChange={onFileSelected}
                />
                <button
                    className="btn-primary mt-3 mb-3"
                    onClick={onUploadBtnClicked}
                    disabled={uploading}
                >
                    {uploading ? (<Loader style={{}} color="white"/>) : (<span>
                            <span className="me-1">
                                <FaCloudUploadAlt/>
                            </span>
                            Upload Summary & Evidence
                        </span>)}
                </button>
            </span>}
        </div>

        {store && <div className="container-sm table_main">
            {!loader ? (<>
                {tableData.length >= 1 ? (<>
                        <Table
                            tableData={tableData}
                            GetEvidences={GetEvidences}
                            setLoader={setLoader}
                            store={store.value}
                            downloading={downloading}
                            setDownloading={setDownloading}
                            deleting={deleting}
                            setDeleting={setDeleting}
                        />
                        <PaginationButtons
                            totalRecords={totalRecords}
                            setRecord={setSearchRecord}
                            record={searchRecord}
                            NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setGoto={setGoto}
                            goto={goto}
                            totalPages={totalPages}
                        />
                    </>

                ) : <NoDataFound/>}
            </>) : <TableLoader/>}
        </div>}
    </div>);
};

export default OBAR;
