import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { type FieldProps } from "formik";
import { type FC } from "react";

export interface CheckboxFieldProps extends FieldProps {
  name: string;
  label: string;
  disabled?: boolean;
}

/**
 * CheckboxField component integrates Material-UI's Checkbox component with Formik.
 */
const CheckboxField: FC<CheckboxFieldProps> = ({ field, label, disabled }) => (
  <FormControl size="small" disabled={disabled} margin="none">
    <FormControlLabel {...field} control={<Checkbox />} label={label} labelPlacement="end" sx={{ margin: 0 }} />
  </FormControl>
);

export default CheckboxField;
