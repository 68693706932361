import ClientConfigServices from "../../../../../Services/ClientConfigServices";

const addScopes = async (
  accountID: any,
  inputData: any,
  setLoader: any,
  toast: any,
  resetForm: any,
  getData: any,
  setChangesInScopes: any
) => {
  try {
    setLoader(true);
    let response = await ClientConfigServices.addScopes(accountID, inputData);
    if (response.status === 200) {
      setLoader(false);
      toast.success(response.data.message);
      resetForm();
      getData();
      setChangesInScopes(true);
    }
  } catch (e) {
    setLoader(false);
  }
};

const updateAccountsScopes = async ({
  accountID,
  scopeID,
  inputData,
}: {
  accountID: number;
  scopeID: number;
  inputData: any;
}) => {
  try {
    const response = await ClientConfigServices.updateScope({
      accountID,
      scopeID,
      data: inputData,
    });

    if (response.status !== 200) {
      throw new Error("Error updating scope, response status is not 200");
    }
  } catch (e) {
    throw e;
  }
};

const GetAccountsScopesData = async (
  setTableData: any,
  setLoader: any,
  accountID: any
) => {
  try {
    setLoader(true);
    let response = await ClientConfigServices.GetAccountsScopes(accountID);
    if (response.status === 200) {
      setTableData(response?.data.scopes);
      setLoader(false);
    }
  } catch (e) {
    // @ts-ignore
    if (e.response.status === 404) {
      setTableData([]);
    }
    setLoader(false);
  }
};

interface DeleteScopes {
  AccountsID: number;
  scopesID: number;
}

const deleteScopes = async ({ AccountsID, scopesID }: DeleteScopes) => {
  try {
    const response = await ClientConfigServices.deleteScopes(
      AccountsID,
      scopesID
    );

    if (response.status !== 200) {
      throw new Error("Error deleting scope, response status is not 200");
    }
  } catch (e) {
    throw e;
  }
};

export { addScopes, GetAccountsScopesData, updateAccountsScopes, deleteScopes };
