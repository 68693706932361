import { Button, Modal } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
const DeleteModal = ({
  setShowDeleteModal,
  showDeleteModal,
  Delete,
  loader,
}) => {
  return (
    <Modal
      show={showDeleteModal}
      onHide={(e) => setShowDeleteModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <form>
        <Modal.Header closeButton>
          <Modal.Title>Delete data</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              color: "white",
              backgroundColor: "#f54242",
              border: "none",
            }}
            onClick={(e) => setShowDeleteModal(false)}
          >
            No
          </Button>
          {loader ? (
            <Button
              style={{
                color: "white",
                backgroundColor: "#212529",
                border: "none",
              }}
            >
              <ClipLoader color="white" size={15} />
            </Button>
          ) : (
            <Button
              style={{
                color: "white",
                backgroundColor: "#212529",
                border: "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                Delete();
              }}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteModal;
