import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Validations from "../../../../Regex";
import { toast } from "react-hot-toast";
import TotalServices from "../../../../TotalServices";
import { ClipLoader } from "react-spinners";
import { useEffect } from "react";

const PhraseModal = ({
  showAddModal,
  handleCloseAddModal,
  region,
  getData,
  editData,
  regionData,
}) => {
  const [issueType, setIssueType] = useState("");
  const [subType, setSubType] = useState(null);
  const [Notes, setNotes] = useState(null);
  const [DisputePhrase, setDisputePhrase] = useState("");
  const [editRegion, setEditRegion] = useState("");
  const [loader, setLoader] = useState(false);

  const addDisputePhrases = async () => {
    if (
      Validations.isEmpty(issueType) ||
      Validations.isEmpty(DisputePhrase) ||
      Validations.isEmpty(region)
    ) {
      toast.error("Fields Can't be Empty");
    } else {
      try {
        setLoader(true);
        let response = await TotalServices.CreatePhrases({
          issue_type: issueType,
          sub_type: subType,
          notes: Notes,
          phrase: DisputePhrase,
          region: region,
        });
        // console.log(response);
        if (response.status === 200) {
          toast.success(response.data.message);
          setLoader(false);
          handleCloseAddModal();
          getData();
        }
      } catch (e) {
        setLoader(false);
      }
    }
  };
  const editDisputePhrases = async () => {
    if (
      Validations.isEmpty(issueType) ||
      Validations.isEmpty(DisputePhrase) ||
      Validations.isEmpty(editRegion)
    ) {
      toast.error("Fields Can't be Empty");
    } else {
      // console.log(editRegion);
      try {
        setLoader(true);
        let response = await TotalServices.EditPhrases(editData[0], {
          issue_type: issueType,
          sub_type: subType,
          notes: Notes,
          phrase: DisputePhrase,
          region: editRegion,
          isMissing: "False",
        });
        // console.log(response);
        if (response.status === 200) {
          toast.success(response.data.message);
          setLoader(false);
          handleCloseAddModal();
          getData();
        }
      } catch (e) {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    editData && editData && setIssueType(editData[1]);
    editData && setSubType(editData[2]);
    editData && setNotes(editData[3]);
    editData && setDisputePhrase(editData[4]);
    editData && setEditRegion(editData[5]);
  }, [editData]);
  return (
    <Modal
      show={showAddModal}
      onHide={handleCloseAddModal}
      backdrop="static"
      keyboard={false}
    >
      <form>
        <Modal.Header closeButton>
          <Modal.Title>Add Dispute Phrase</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="p-3">
            {editData && (
              <div className=" mb-3">
                <label
                  htmlFor="name"
                  className="form-label ms-0 ps-0 fw-bold text-dark"
                >
                  Region:
                </label>
                <select
                  className="form-select form-select mb-3"
                  aria-label=".form-select-lg example"
                  value={editRegion}
                  onChange={(e) => {
                    setEditRegion(e.target.value);
                  }}
                >
                  {regionData?.map((obj) => {
                    return <option value={obj}>{obj}</option>;
                  })}
                </select>
              </div>
            )}
            <div className=" mb-3">
              <label
                htmlFor="name"
                className="form-label ms-0 ps-0 fw-bold text-dark"
              >
                Issue Type:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                required
                value={issueType}
                onChange={(e) => {
                  setIssueType(e.target.value);
                }}
                placeholder="Enter Issue Type..."
              />
            </div>
            <div className=" mb-3">
              <label
                htmlFor="name"
                className="form-label ms-0 ps-0 fw-bold text-dark"
              >
                Sub Type:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={subType}
                onChange={(e) => {
                  setSubType(e.target.value);
                }}
                placeholder="Enter Sub Type..."
              />
            </div>
            <div className=" mb-3">
              <label
                htmlFor="name"
                className="form-label ms-0 ps-0 fw-bold text-dark"
              >
                Notes:
              </label>
              <textarea
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={Notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                placeholder="Enter Notes..."
              />
            </div>
            <div className=" mb-3">
              <label
                htmlFor="name"
                className="form-label ms-0 ps-0 fw-bold text-dark"
              >
                Dispute Phrase:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={DisputePhrase}
                onChange={(e) => {
                  setDisputePhrase(e.target.value);
                }}
                placeholder="Enter Dispute Phrase..."
                required
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              color: "white",
              backgroundColor: "#f54242",
              border: "none",
            }}
            onClick={handleCloseAddModal}
          >
            Close
          </Button>
          {loader ? (
            <Button
              style={{
                color: "white",
                backgroundColor: "#212529",
                border: "none",
              }}
            >
              <ClipLoader color="white" size={15} />
            </Button>
          ) : editData ? (
            <Button
              style={{
                color: "white",
                backgroundColor: "#212529",
                border: "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                editDisputePhrases();
              }}
            >
              Save Dispute Phrase
            </Button>
          ) : (
            <Button
              style={{
                color: "white",
                backgroundColor: "#212529",
                border: "none",
              }}
              onClick={(e) => {
                e.preventDefault();

                addDisputePhrases();
              }}
            >
              Create Dispute Phrase
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PhraseModal;
