import { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import TotalServices from "../../../../TotalServices";
import DeleteModal from "../../../../components/Modals/Delete";

const DeletePhrase = ({deleteId,getData}) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [loader,setLoader]=useState(false)

    const DeleteQuery = async() => {

        
        try{
            setLoader(true)
            let response=await TotalServices.DeletePhrase(deleteId)
        if(response.status===200){
            toast.success(response.data.message)
        //  console.log(response.data.message);
         setLoader(false)
              setShowDeleteModal(false);
              getData()
           
        }
        }catch(e){
            setLoader(false)
        }
              };
    return ( 
        <>
         <button
                    className="btn btn-danger ms-2"
                  
                    onClick={() => {
                            
                      setShowDeleteModal(true);}}

                  >
                    <AiOutlineDelete />
                  </button>
     
      {showDeleteModal &&
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          Delete={DeleteQuery}
          loader={loader}
          
        />
              }
      </>);
}
 
export default DeletePhrase;