import { Components } from "@mui/material/styles";

const tabs: {
  MuiTab: Components["MuiTab"];
  MuiTabs: Components["MuiTabs"];
} = {
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
        padding: "0 48px",
        fontSize: "16px",
        "&.Mui-selected": {
          color: "#F7104D",
        },

        "@media (max-width:960px)": {
          padding: "0 16px",
          fontSize: "14px",
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: "#F7104D",
      },
    },
  },
};

export default tabs;
