/// @ts-check
import { BeatLoader } from "react-spinners";

/** @import { CSSProperties } from "react"; */
/** @import { Property } from "csstype"; */

/** @type {CSSProperties} */
const defaultStyle = { height: "100vh", color: "red" };

/** @type {Property.Color} */
const defaultColor = "black";

/** @param {{ style?: CSSProperties; color?: Property.Color; }} props */
const Loader = ({ style = defaultStyle, color = defaultColor }) => (
  <div className="d-flex justify-content-center w-100 align-items-center" style={style}>
    <BeatLoader color={color} />
  </div>
);

export default Loader;
