/**
 * @file mockApi.ts
 * @description This file sets up a mock API using Axios and Axios-Mock-Adapter to facilitate front-end development.
 * The mock API provides endpoints for fetching dynamic options and work packets, applying filters, and handling pagination.
 *
 * Note: This mock API is not fully accurate and is intended to expedite front-end development.
 * It should be improved and aligned with the specifications of the real API.
 */

import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { type WorkPacketBase, type WorkPacketFilters } from "src/types/work-packets";
import { dynamicOptions, mockWorkPackets } from "./mockData.ts";

const mock = new MockAdapter(axios);

const applyFilters = (
  data: WorkPacketBase[],
  filters: WorkPacketFilters
): WorkPacketBase[] => {
  return data.filter((item) => {
    return Object.keys(filters).every((key) => {
      const filterValue = filters[key as keyof WorkPacketFilters];
      const itemValue = item[key as keyof WorkPacketBase];

      if (!filterValue) return true;

      if (
        typeof itemValue === "object" &&
        itemValue !== null &&
        "title" in itemValue
      ) {
        return (itemValue as any).title
          .toLowerCase()
          .includes(filterValue.toString().toLowerCase());
      }

      if (typeof itemValue === "string" && typeof filterValue === "string") {
        return itemValue.toLowerCase().includes(filterValue.toLowerCase());
      }

      return itemValue === filterValue;
    });
  });
};

const paginate = (data: any[], page: number, pageSize: number): any[] => {
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  return data.slice(start, end);
};

mock.onGet("/api/dynamic-options").reply(200, dynamicOptions);

mock.onGet("/api/work-packets").reply((config) => {
  console.log("Request", config.params);

  const page = parseInt(config.params.page as string, 10) || 1;
  const pageSize = parseInt(config.params.pageSize as string, 10) || 10;
  const filters: WorkPacketFilters = config.params.filters
    ? JSON.parse(config.params.filters)
    : {};

  const filteredData = applyFilters(mockWorkPackets as any[], filters);
  const paginatedData = paginate(filteredData, page, pageSize);

  // Timeout to simulate network latency
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        200,
        {
          data: paginatedData,
          total: filteredData.length,
          page,
          pageSize,
        },
      ]);
    }, 1000);
  });

  return [
    200,
    {
      data: paginatedData,
      total: filteredData.length,
      page,
      pageSize,
    },
  ];
});

export default axios;
