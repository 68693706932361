import { toast } from "react-hot-toast";
import TotalServices from "../../../../TotalServices";

const OffCanvasTable = ({data,store,setLoader}) => {
  
    
      // edit Configuration
      const editConfiguration =async (e, id) => {
        try {
            setLoader(true)
            let response = await TotalServices.EditConfigurationData(store,e ? 1 : 0,id
           ,"Single" );
            if(response.status===200){
               toast.success(response.data.message)
               setLoader(false)
            }
            }
          catch(error ){
            toast.error("There is error please try again");
            setLoader(false)
         
          }
          
    
      };
    return (
      <div className="table-container">  
        <table className="table">
    <thead className="thead-dark">
      <tr>
        {/* <th scope="col">Serial No.</th> */}
        <th scope="col">Issue Type</th>
        <th scope="col">Sub Type</th>
        <th scope="col">Notes</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      {data && data.map((obj, index) => {
        return (
          <>
            <tr key={index}>

              <td scope="col">{obj[1] == null ? "NA" : obj[1]}</td>
              <td scope="col">{obj[2] == null ? "NA" : obj[2]} </td>
              <td scope="col">{obj[3] == null ? "NA" : obj[3]  }</td>
              <td>
                <div className="form-check form-switch custom_toggle">
                  <input className="form-check-input custom_toggle_style" type="checkbox" role="switch"
                    defaultChecked={obj[4]}
                    onChange={(e) => {
                      editConfiguration(e.target.checked, obj[0]);
                    }}
                  />
                </div>
                <div class="form-check form-switch">




                </div>
              </td>


            </tr>

          </>
        )

      })}
    </tbody>
  </table>
 
  </div> );
}
 
export default OffCanvasTable;