interface MaybeValueObject {
  value?: unknown;
}

/**
 * Get a flat row from a work packet.
 *
 * We need to flatten the row to be able to compare it with the original row
 * as well as to easy access the values.
 */
export const getFlatRow = (row: object) => {
  const updatedRowFlat = Object.keys(row).reduce((acc, key) => {
    const value = row[key as keyof typeof row];
    acc[key] = value && typeof value === "object" ? (value as MaybeValueObject)?.value : value;
    return acc;
  }, {} as Record<string, any>);

  return updatedRowFlat;
};
