import { Box, Divider, Typography } from "@mui/material";
import { type FC, useCallback, useEffect, useState } from "react";
import { ControlPanel } from "./ControlPanel";
import {
  MetricRowCard,
  RecoverableSummaryCard,
  SummaryByWorkPacketActionCard,
} from "components/work-packets/metric-cards";
import {
  WorkPacketMetric,
  type WorkPacketsSummaryAggregated,
} from "src/types/work-packets";
import { WorkPacketView } from "../WorkPacketView";
import * as WorkPacketsAPI from "../api/workPacketsAPI";
import debounce from "lodash.debounce";
import { useWorkPacketsContext } from "../WorkPacketsContext";
import type { WorkPacketType } from "../WorkPacketType";

interface WorkPacketsManagerProps {
  isAdmin?: boolean;

  /** Used to trigger a refetch when grid data changes.*/
  refetch?: boolean;
}

export const WorkPacketsManager: FC<WorkPacketsManagerProps> = ({
  isAdmin = false,
  refetch,
}) => {
  const { currentView, setView, currentWorkPacketType } = useWorkPacketsContext();
  const [data, setData] = useState<WorkPacketsSummaryAggregated | null>(null);
  const [loading, setLoading] = useState(false);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setView(newValue);
  };

  const mapViewToAPI = {
    [WorkPacketView.AllPackets]: WorkPacketsAPI.fetchAllPacketsSummary,
    [WorkPacketView.NewPackets]: WorkPacketsAPI.fetchNewPacketsSummary,
    [WorkPacketView.MyPackets]: WorkPacketsAPI.fetchUserPacketsSummary,
  };

  const fetchSummaryData = async (view: WorkPacketView, type: WorkPacketType) => {
    try {
      setLoading(true);
      const summaryData = await mapViewToAPI[view](type);
      setData(summaryData);
    } catch (error) {
      console.error("Error fetching summary data", error);
    } finally {
      setLoading(false);
    }
  };

  /* Preventing multiple requests especially on initial load */
  const debouncedFetchSummaryData = useCallback(
    debounce(
      (currentView: WorkPacketView, type: WorkPacketType) => {
        fetchSummaryData(currentView, type);
      },
      300,
      { leading: true, trailing: false }
    ),
    [fetchSummaryData],
  );

  useEffect(() => {
    setData(null);
    debouncedFetchSummaryData(currentView, currentWorkPacketType);
  }, [currentView, setData, refetch, currentWorkPacketType]);

  return (
    <>
      <ControlPanel
        selectedTab={currentView}
        handleTabChange={handleTabChange}
        isAdmin={isAdmin}
      />

      <Box bgcolor="#fff" padding={3}>
        <Typography variant="h6" marginBottom={2}>
          Summary
        </Typography>

        <Box
          display="flex"
          gap={1.5}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          {currentView === WorkPacketView.AllPackets && (
            <>
              <RecoverableSummaryCard
                total={Number(data?.recoverableAmount?.total)}
                pending={Number(data?.recoverableAmount?.pending)}
                approved={Number(data?.recoverableAmount?.approved)}
                loading={loading}
              />

              <Box display="flex" flexDirection="column" flex={1} gap={1.5}>
                {[
                  {
                    name: WorkPacketMetric.TotalStores,
                    value: data?.totalStores || 0,
                  },
                  {
                    name: WorkPacketMetric.OpenWorkPackets,
                    value: data?.totalWorkPackets || 0,
                  },
                ].map((metric) => (
                  <MetricRowCard
                    key={metric.name}
                    {...metric}
                    loading={loading}
                  />
                ))}
              </Box>
            </>
          )}

          {currentView === WorkPacketView.NewPackets && (
            <>
              <RecoverableSummaryCard
                total={Number(data?.recoverableAmount?.total)}
                loading={loading}
              />

              <Box display="flex" flexDirection="column" flex={1} gap={1.5}>
                {[
                  {
                    name: WorkPacketMetric.TotalStores,
                    value: data?.totalStores || 0,
                  },
                  {
                    name: WorkPacketMetric.NewPackets,
                    value: data?.totalWorkPackets || 0,
                  },
                ].map((metric) => (
                  <MetricRowCard
                    key={metric.name}
                    {...metric}
                    loading={loading}
                  />
                ))}
              </Box>
            </>
          )}

          {currentView === WorkPacketView.MyPackets && (
            <>
              <SummaryByWorkPacketActionCard
                total={data?.totalWorkPackets || 0}
                actions={data?.workPackets || []}
                loading={loading}
              />

              <Box display="flex" flexDirection="column" flex={1} gap={1.5}>
                {[
                  {
                    name: WorkPacketMetric.TotalPacketsAssigned,
                    value: data?.totalWorkPackets || 0,
                  },
                  {
                    name: WorkPacketMetric.PacketsWithRedFlags,
                    value: data?.totalRedFlags || 0,
                  },
                ].map((metric) => (
                  <MetricRowCard
                    key={metric.name}
                    {...metric}
                    loading={loading}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Divider />
    </>
  );
};
