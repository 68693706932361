import { toast } from "react-hot-toast";
import TotalServices from "../../TotalServices";
import moment from "moment";

const useDisputeToolDownload = () => {
   
   
  const DownlaodFile = async(type,id , setFileLoader,store,date) =>{
    
    setFileLoader(true);
  try{
    let response=await TotalServices.DownlaodFile(type,id)
  // console.log(response);
       if(response.status===200){
    

          var a = document.createElement("a");
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
          a.href =downloadUrl
          // check if .csv occurs in the filename
          // console.log(response,"tst",moment(date).format("YYYY-MM-DD"),date,store);
          let fileName =store+"_"+moment(date).format("YYYY-MM-DD")
          // console.log(fileName);
          a.setAttribute('download', fileName+".csv");
    document.body.appendChild(a);
   
          a.click();
          setFileLoader(false);
        
     
      }
    }
      catch(error) {
         
        setFileLoader(false);
        toast.error("Can't be able to download file");
      };
    
  }
    return({DownlaodFile})
}
 
export default useDisputeToolDownload;