import { useAuth0 } from "@auth0/auth0-react";
import { Box, TextField } from "@mui/material";
import usePermissions from "components/CustomHooks/usePermissions";
import {
  AutocompleteField,
  DatePickerField,
  SelectField,
  TextAreaField,
} from "components/UI/Form";
import { Field } from "formik";
import { type FC, useEffect, useState } from "react";
import { WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketView } from "../../../WorkPacketView";
import {
  workPacketActionsEditable
} from "../../../WorkPacketsGrid/helpers/getStaticFilterOptions";
import * as WorkPacketsAPI from "../../../api/workPacketsAPI";
import { FormFieldsGroup } from "../components";
import { useWorkPacketsContext } from "../../../WorkPacketsContext";

interface WorkPacketInfoProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  view: WorkPacketView;
}

const recoveryStreamOptions = [
  { value: "PPV", label: "PPV" },
  { value: "PQV", label: "PQV" },
];

const fetchOwnerOptions = async (setUsersData: (users: object[]) => void, forceSingleId?: string) => {
  try {
    const users = await WorkPacketsAPI.fetchUsers();

    if (forceSingleId) {
      /* For non-admins, only show the current user */
      const user = users.find((user) => user.value === forceSingleId);
      if (!user) {
        throw new Error(`User with ID ${forceSingleId} not found`);
      }

      setUsersData([user]);
      return;
    }

    setUsersData(users);
  } catch (error) {
    console.error("Error fetching dynamic options:", error);
  }
};

export const WorkPacketInfo: FC<WorkPacketInfoProps> = ({ setFieldValue, view }) => {
  const { user } = useAuth0();
  const { isAdmin } = usePermissions();
  const [usersData, setUsersData] = useState<object[]>([]);
  const { currentWorkPacketType } = useWorkPacketsContext();

  useEffect(() => {
    if (!user?.sub) return;
    fetchOwnerOptions(setUsersData, isAdmin ? undefined : user.sub);
  }, [user, isAdmin]);

  return (
    <FormFieldsGroup title="Work Packet info" id="work-packet-info">
      <Field
        name="packet-id"
        as={TextField}
        variant="outlined"
        placeholder="Enter Packet ID"
        size="small"
        label="Packet ID"
        disabled
      />

      <Field
        name="packet-date"
        component={DatePickerField}
        label="Packet Date"
        disabled
      />

      <Field
        name="closed-date"
        as={TextField}
        variant="outlined"
        placeholder="Packet Closed Date"
        size="small"
        label="Packet Closed Date"
        disabled
      />

      <Field
        name="packet-stage"
        as={TextField}
        variant="outlined"
        placeholder="Packet Stage"
        size="small"
        label="Packet Stage"
        disabled
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={workPacketActionsEditable[currentWorkPacketType]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        disabled={view === WorkPacketView.NewPackets && !isAdmin}
        disableClearable
      />

      <Field
        name={WorkPacketFilter.RecoveryStream}
        component={SelectField}
        label="Recovery Stream"
        options={recoveryStreamOptions}
        disabled
      />

      <Field
        name="recovery-stream-activation-date"
        component={DatePickerField}
        label="Recovery Stream Activation Date"
        disabled
      />

      {/* Omitted for now - will be implemented later */}
      {/* <Field
        name="vc-po-id"
        as={TextField}
        variant="outlined"
        placeholder="VC PO ID"
        size="small"
        label="VC PO ID"
        disabled
      />

      <Field
        name="vc-vendor-code"
        as={TextField}
        variant="outlined"
        placeholder="VC Vendor Code"
        size="small"
        label="VC Vendor Code"
        disabled
      /> */}

      <Field
        name="vc-payee-code"
        as={TextField}
        variant="outlined"
        placeholder="VC Payee Code"
        size="small"
        label="VC Payee Code"
        disabled
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label="Current Packet Owner"
        options={usersData}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disableClearable={!isAdmin}
      />

      <Field
        name="last-modified-by"
        component={TextField}
        variant="outlined"
        placeholder="Last Modified By"
        size="small"
        label="Last Modified By"
        disabled
      />

      <Box sx={{ gridColumn: { xs: "span 1", md: "span 2" } }}>
        <Field name="notes" component={TextAreaField} label="Notes" />
      </Box>
    </FormFieldsGroup>
  );
};
