import { Autocomplete, TextField } from "@mui/material";
import { FC, SyntheticEvent, useState } from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid";

interface Option {
  title: string;
  avatar?: string;
  color?: string;
  value?: string;
}

interface CellProps extends GridRenderEditCellParams {
  options: Option[];
  // Temporary. Will be removed after the API is updated
  valueType?: "string" | "object";
  groupBy?: (option: any) => string;
}

const EditableAutocompleteCell: FC<CellProps> = ({
  id,
  value,
  field,
  api,
  options,
  valueType = "string",
  groupBy,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    /*
     * || "" added to handle broken data when we can't map value into any expected values.
     * Please see getWorkPacketsGridColumns errors handling for more details.
     */
    valueType === "object" ? value?.title || "" : value
  );

  const handleChange = (_event: SyntheticEvent, newValue: Option) => {
    setInputValue(newValue.title);

    const value = valueType === "object" ? newValue : newValue.title;
    api.setEditCellValue({ id, field, value });
  };

  return (
    <Autocomplete
      value={options.find((option) => option.title === inputValue)}
      onChange={handleChange}
      options={options}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      disableClearable
      size="small"
      className="autocomplete-cell-container"
      renderOption={(props, option) => (
        <li
          {...props}
          className={`autocomplete-menu-item ${props.className}`}
          key={option.value ? option.value : option.title}
        >
          {option?.color && (
            <span
              className="autocomplete-menu-item__color-indicator"
              style={{ background: option.color }}
            />
          )}

          {option.avatar && (
            <img
              src={option.avatar}
              alt={option.title}
              className="autocomplete-menu-item__avatar"
            />
          )}

          <span className="autocomplete-menu-item__text">{option.title}</span>
        </li>
      )}
      groupBy={groupBy}
    />
  );
};

export default EditableAutocompleteCell;
