import React from "react";
import { PulseLoader } from "react-spinners";

const Card = ({ loader, carddata }) => {
  return (
    <>
      <div>
        <div className="btn-box col d-flex h-fit-content justify-content-center me-md-5 mb-2 ">
          <div>
            <h6 className="text-white text-center">
              <strong>{carddata.name}</strong>
            </h6>
            <h6 className="text-white text-center">
              {loader ? (
                `${carddata.value}`
              ) : (
                <PulseLoader size="8px" color="#EF1442"></PulseLoader>
              )}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
