import { useCallback, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";


const Search = ({setSearchQuery,searchQuery,currentPage,setCurrentPage,GetData,placeholder,newClass,type}) => {
  const [focus,setFocus]=useState(false)
useEffect(()=>{


  searchQuery==="" && focus && GetData() ;
},[searchQuery])
    return ( 
        <form  className={newClass?"w-100":""} onSubmit={(e) => {
          e.preventDefault()
        
      currentPage!==1?setCurrentPage(1):GetData()
          }}>
            <div  className={newClass?"search-container justify-content-start":"search-container "}>
              
              <span className="span1">
                <BiSearch />
              </span>
              <input
              id="input"
                type={type?type:"search"}
                onFocus={e=>setFocus(true)}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                className="form-control"
  
                placeholder={placeholder?placeholder:"Search"}
              />
              &nbsp;|&nbsp;
              <button className="btn btn-dark" type="submit">
                Search
              </button>
            </div>
          </form>
     );
}
 
export default Search;