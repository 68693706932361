const Input = ({
  placeholder,
  name,
  handleChange,
  value,
  type,
  disabled,
  handleFocus,
  handleBlur,
  required,
  classes,
  options, // Add options prop for select element
}) => {
  return (
    <input
      className={`w-full form-control outline-none p-2 border rounded-xl sm:text-md my-1 disabled:text-gray-500 ${
        classes ? classes : ""
      } `}
      placeholder={placeholder}
      type={type}
      name={name}
      required = {required}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={value}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default Input;
