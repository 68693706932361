import moment from "moment";
import OBARServices from "../../../Services/OBARServices";
import Loader from "../../../components/Loader/Loader.jsx";
import {confirmAlert} from 'react-confirm-alert';

const Table = ({tableData, GetEvidences, store, downloading, setDownloading, deleting, setDeleting}) => {

    const handleDeleteConfirmation = async (amazon_dispute_id) => {
        setDeleting(amazon_dispute_id)
        await OBARServices.deleteEvidences(amazon_dispute_id, store, setDeleting, GetEvidences)
    }

    const handleDownload = async (amazon_dispute_id) => {
        setDownloading(amazon_dispute_id)
        await OBARServices.downloadEvidences(amazon_dispute_id, store, setDownloading)
    }

    return (<div className="table-container">
        <table className="table">
            <thead className="thead-dark">
            <tr>
                <th scope="col">Dispute ID</th>
                <th scope="col">Date Time</th>
                <th scope="col"># Rows</th>
                <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            {(tableData ?? []).length !== 0 && tableData?.map((data, index) => (<tr key={index}>
                <td>{data['amazon_dispute_id']}</td>
                <td>{moment(data['created_at']).format("YYYY-MM-DD")}</td>
                <td>{data['count']}</td>

                <td>
                    <button
                        className="btn px -4 bg-primary btn-outline-primary text-white me-2"
                        onClick={async () => handleDownload(data['amazon_dispute_id'])}
                        disabled={downloading !== undefined}
                    >
                        {downloading === data['amazon_dispute_id'] ? (<Loader style={{}} color="white"/>) : "Download"}
                    </button>
                    <button
                        className="btn px-4 bg-danger btn-outline-danger text-white"
                        onClick={async () => {
                            confirmAlert({
                                title: 'Delete ' + data['amazon_dispute_id'] + '?',
                                message: 'Are you sure to delete this dispute evidence?',
                                buttons: [{
                                    label: 'Yes',
                                    onClick: async () => handleDeleteConfirmation(data['amazon_dispute_id'])
                                }, {
                                    label: 'No', onClick: () => setDeleting(undefined)

                                }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                            });
                        }}
                        disabled={deleting !== undefined}
                    >
                        {deleting === data['amazon_dispute_id'] ? (<Loader style={{}} color="white"/>) : "Delete"}
                    </button>
                </td>
            </tr>))}
            </tbody>
        </table>
    </div>);
};

export default Table;
