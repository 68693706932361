import AddAccount from "./CustomersCreate/AddAccounts.jsx";
import React, { useState, useEffect, useContext } from "react";
import Card from "react-bootstrap/Card";
import "./Customers.css";
import ViewAccountsTable from "./ViewAccountsTable.jsx";
import UpdateVendor from "./components/UpdateVendor/UpdateVendor.jsx";
import TableLoader from "../../../components/Loader/TableLoader.jsx";
import NoDataFound from "../../../components/NoDataFound/NoDataFound.jsx";
import {
  getShippingData,
  getShortageProcessor,
  getChargebackProcessor,
} from "./Api/Shared/SharedApi.js";
import { GetAccountsData } from "./Api/Accounts/Accounts.js";
import { getAvcData } from "./Api/Shared/SharedApi.js";
import { useLocation } from "react-router-dom";
import AuditServices from "../../../Services/AuditsServices";
import { MyContext } from "../../../App.jsx";
import usePermissions from "../../../components/CustomHooks/usePermissions.js";
import useUsers from "components/CustomHooks/useUsers";
const ViewOneVendor = () => {
  const location = useLocation();
  const { state } = location;
  const { hasPermission } = usePermissions();
  const { getUsers } = useUsers();

  const urlParams = new URLSearchParams(window.location.search);
  const vendorID = urlParams.get("vendor");

  const { vendorTableData } = location.state || {};

  const [ShippingData, setShippingData] = useState([]);
  const [avcData, setAvcData] = useState([]);

  const [chargebackProcessorData, setChargebackProcessorData] = useState([]);
  const [shortageProcessorData, setShortageProcessorData] = useState([]);
  const [showAccountsForm, setShowAccountsForm] = useState(false);

  // Pagination
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableAccountData, setAccountsTableData] = useState([]);

  const [loader, setLoader] = useState(true);

  const getAccountsAll = () => {
    GetAccountsData(setAccountsTableData, searchQuery, setLoader, vendorID);
  };

  const GetAuditAccounts = async () => {
    try {
      let response = await AuditServices.GetAuditAccounts();
      if (response.status === 200) {
        setAvcData(
          response.data.accounts.map((val) => ({
            label: val[0],
            value: val[0],
          }))
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    GetAuditAccounts();
    getShortageProcessor(setShortageProcessorData);
    getProcessors('chargebacks');
    getAccountsAll();
    getShippingData(setShippingData);
  }, []);

  const handleShowAccountsForm = () => {
    setShowAccountsForm(!showAccountsForm);
  };

  const getProcessors = (type) => {

    if (typeof type !== 'string') {
      throw new Error('Invalid processor type');
    }

    type = type.toLowerCase();

    if (!((new Set(['chargebacks', 'shortages'])).has(type))) {
      throw new Error('Invalid processor type');
    }

    getUsers(undefined, undefined, undefined, ["admin:*", "user:" + type + ":write:*", "user:" + type + ":read:*"]).then((response) => {
      const users = response?.data?.users;

      const convertedData = users.map((item) => ({
        label: item.NICKNAME,
        value: item.ID,
      }));

      convertedData.unshift({
        label: "Select " + (type.charAt(0).toUpperCase() + type.slice(1)) + " Processor",
        value: null
      });
      console.debug('convertedData', convertedData, 'type', type)
      switch (type) {
        case 'chargebacks':
          setChargebackProcessorData(convertedData);
          break;
        case 'shortages':
          setShortageProcessorData(convertedData);
          break;
      }
    });
  }

  return (
    <>
      <Card
        className="rounded bg_shadow bg-light-subtle"
        style={{ width: "95%", marginTop: "10px", marginBottom: "10px" }}
      >
        <Card.Header>Update Customer</Card.Header>
        <Card.Body>
          <UpdateVendor vendorID={vendorID} />
        </Card.Body>
      </Card>
      <Card
        className="rounded bg_shadow bg-light-subtle"
        style={{ width: "95%", marginTop: "10px", marginBottom: "10px" }}
      >
        <Card.Header>Stores</Card.Header>
        {hasPermission(['admin:*', 'user:client_config:write:*']) && (
          <div className="mt-5">
            <button
              className="btn btn-dark"
              onClick={() => {
                handleShowAccountsForm();
              }}
            >
              Add Store
            </button>

            <div>
              {showAccountsForm && (
                <AddAccount
                  vendorID={vendorID}
                  tableData={vendorTableData}
                  chargebackProcessorData={chargebackProcessorData}
                  shortageProcessorData={shortageProcessorData}
                  ShippingData={ShippingData}
                  getData={getAccountsAll}
                  handleShowAccountsForm={handleShowAccountsForm}
                  avcData={avcData}
                ></AddAccount>
              )}
            </div>
          </div>
        )}
        <div className="container-sm table_main_account">
          <Card.Body>
            {!loader ? (
              <>
                {tableAccountData?.length >= 1 ? (
                  <>
                    <ViewAccountsTable
                      loader={loader}
                      avcData={avcData}
                      shortageProcessorData={shortageProcessorData}
                      chargebackProcessorData={chargebackProcessorData}
                      getData={getAccountsAll}
                      ShippingData={ShippingData}
                      tableData={tableAccountData}
                    ></ViewAccountsTable>
                  </>
                ) : (
                  <NoDataFound />
                )}
              </>
            ) : (
              <TableLoader />
            )}
          </Card.Body>
        </div>
      </Card>
    </>
  );
};

export default ViewOneVendor;
