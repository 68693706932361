import { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import PhraseModal from "./PhraseModal";
import InfoModal from "./InfoModal";
import DeletePhrase from "./DeletePhrase";

const PhraseTable = ({data,regionData,getData}) => {
    const [editData,setEditData]=useState([])
    const [editModal,setEditModal]=useState(false)
    const [infoData,setInfoData]=useState([])
    const [showInfoModal, setShowInfoModal] = useState(false);
    const handleCloseInfoModal = () => setShowInfoModal(false);
   const  handleCloseEditModal =() =>{
    setEditModal(false)
   }
    return ( 
      <div className="table-container"> 
        <table className="table">
    <thead className="thead-dark">
      <tr>
        <th scope="col">Issue Type</th>
        {/* <th scope="col">Sub Type</th>
        <th scope="col">Notes</th>
        <th scope="col">Dispute Phrase</th>
        <th scope="col">Region</th> */}
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((obj) => {
          return (
            <tr>
              <td>
             
                <> {obj[1]}</>
              
              </td>
              
              <td>
                <div className="d-flex">
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => {
                        setShowInfoModal(true)
                    setInfoData(obj)
                    }}
                  >
                    <BsFillInfoCircleFill />
                  </button>
               
                 

                  <button
                    className="btn btn-dark"
                    onClick={() => {
                        setEditData(obj)
                        setEditModal(true)
                    }}
                  >
                    <AiOutlineEdit />
                  </button>

               <DeletePhrase  deleteId={obj[0]} getData={getData}/>

                 

                  {/* Delete confirmation modal */}
                </div>
              </td>
            </tr>
          );
        })}
    </tbody>
  </table>
  {showInfoModal && <InfoModal showInfoModal={showInfoModal}  handleCloseInfoModal={handleCloseInfoModal} infoData={infoData}/>}
 {editModal && <PhraseModal showAddModal={editModal} editData={editData} handleCloseAddModal={handleCloseEditModal} regionData={regionData} getData={getData}/>}
  </div> );
}
 
export default PhraseTable;