
import { useMutation } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useState, type FC } from "react";
import { toast } from "react-hot-toast";
import { http } from "src/axios";
import { type OssrQuery } from "src/queries/ossr";
import { queryClient } from "src/query-client";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { type GridCellParams } from "@mui/x-data-grid-pro";
import DeleteModal from "components/Modals/Delete";

import css from "./OssrDeleteButton.module.css";

const deleteOssr = (query: OssrQuery) => http.delete(`/OSSR/${query.QUERY_ID}/0`);

const onOssrDeleteSuccess = (response: AxiosResponse) => {
  toast(response.data?.message);
  queryClient.invalidateQueries({ queryKey: ["ossr"] });
};

export const OssrDeleteButton: FC<GridCellParams<OssrQuery>> = ({ row }) => {
  const mutation = useMutation({
    mutationKey: ["ossr-delete", row.QUERY_ID],
    mutationFn: deleteOssr,
    onSuccess: onOssrDeleteSuccess,
  });

  const [isDeleteModalOpen, setDeleteModelOpen] = useState(false);

  const onDeleteClick = async () => {
    try {
      await mutation.mutateAsync(row);
    } finally {
      setDeleteModelOpen(false);
    }
  };

  return (
    <>
      <Button
        className={css.ossrDeleteButton}
        onClick={() => setDeleteModelOpen(true)}
        variant="outlined"
        color="warning"
      >
        <DeleteIcon />
      </Button>
      {/* perf optimization: only render dialog when needed - this breaks the fade out transition, but would otherwise slow down page render */}
      {isDeleteModalOpen && (
        <DeleteModal
          showDeleteModal={isDeleteModalOpen}
          setShowDeleteModal={setDeleteModelOpen}
          loader={mutation.isPending}
          Delete={onDeleteClick}
        />
      )}
    </>
  );
};
