import { MenuOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import useCurrentPage from "src/hooks/useCurrentPage";
import theme from "src/styles/theme";

interface HeaderProps {
  sidebarToggled: boolean;
  setSidebarToggled: (value: boolean) => void;
}

const Header: FC<HeaderProps> = ({ sidebarToggled, setSidebarToggled }) => {
  const { currentPage, showFileDownloadButton, onFileDownload } =
    useCurrentPage();

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        paddingX={3}
        height="72px"
        justifyContent="space-between"
        alignItems="center"
        bgcolor="#F7F7F7"
      >
        <Typography variant="h6">{currentPage}</Typography>

        <Box display="flex" alignItems="center" gap={2}>
          {showFileDownloadButton && (
            <Button variant="outlined" onClick={onFileDownload}>
              <span>Template File</span>
            </Button>
          )}

          <IconButton
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={() => {
              setSidebarToggled(!sidebarToggled);
            }}
          >
            <MenuOutlined />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </ThemeProvider>
  );
};

export default Header;
