import { useMutation } from "@tanstack/react-query";
import { produce } from "immer";
import { useCallback, useEffect, useMemo, useState, type FC, type FormEvent } from "react";
import toast from "react-hot-toast";
import { http } from "src/axios";
import { queryClient } from "src/query-client";
import { PermissionSelect } from "./PermissionSelect";
import { useUserAddEditDialogStore, type DialogState } from "./UserAddEditDialogContext";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import css from "./UserAddEditDialog.module.css";

const userAddEditMutationFn = async ({ dialogState, formState }: { dialogState: DialogState; formState: FormState; }) => {
  const { mode, user: originalUser } = dialogState;
  const { id, email, fullName, permissions, password } = formState;

  if (mode === "closed") return;
  if (mode === "add") {
    const userRequest: UserRequest = {
      name: fullName,
      email: email,
      password,
      nickname: fullName,
      permissions,
    };
    return http.post("/api/v2/user", userRequest);
  } else if (mode === "edit") {
    const userRequest: UserRequest = {
      id: originalUser.ID,
      name: fullName,
      nickname: fullName,
      permissions,
      ...(formState.isEditingPassword && { password: formState.password }),
    };
    return http.patch(`/api/v2/user/${id}`, userRequest);
  }
};

const onUserAddEditSuccess = async (_data: any, { dialogState }: { dialogState: DialogState }) => {
  queryClient.invalidateQueries({ queryKey: ["users"] });
  if (dialogState.mode === "add") {
    toast.success("User successfully created");
  } else if (dialogState.mode === "edit") {
    toast.success("User successfully updated");
  }
};

interface FormState {
  id?: string;
  fullName: string;
  email: string;
  password: string;
  passwordRepeat: string;
  permissions: string[];
  isEditingPassword: boolean;
}

const DEFAULT_FORM_STATE: FormState = {
  id: undefined,
  fullName: "",
  email: "",
  password: "",
  passwordRepeat: "",
  permissions: [],
  isEditingPassword: false,
};

interface UserRequest {
  id?: string;
  name: string;
  nickname?: string;
  password?: string;
  email?: string;
  permissions: string[];
}

export const UserAddEditDialog: FC = () => {
  const { dialogState, setDialogState } = useUserAddEditDialogStore();
  const open = dialogState.mode !== "closed";

  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);
  const onFormStateChange = useCallback(function <K extends keyof FormState, V extends FormState[K]>(field: K, value: V) {
    setFormState(produce(draft => void Reflect.set(draft, field, value)));
  }, []);
  const onPermissionsChange = useMemo(() => onFormStateChange.bind(null, "permissions"), []);

  useEffect(() => {
    const user = dialogState.user;
    if (!user) {
      setFormState(DEFAULT_FORM_STATE);
    } else {
      setFormState({
        ...DEFAULT_FORM_STATE,
        id: user.ID,
        email: user.EMAIL,
        fullName: user.NICKNAME,
        permissions: user.PERMISSIONS.map(p => p.PERMISSION),
      });
    }
  }, [dialogState.user]);

  const mutation = useMutation({
    mutationKey: ["create-update-user"],
    mutationFn: userAddEditMutationFn,
    onSuccess: onUserAddEditSuccess,
  });

  const canClose = !mutation.isPending;
  const onClose = useCallback(() => {
    if (!canClose) return;
    setFormState(DEFAULT_FORM_STATE);
    setDialogState({ mode: "closed" });
  }, [canClose]);

  const onSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await mutation.mutateAsync({ dialogState, formState });
    onClose();
  }, [mutation.mutateAsync, onClose, dialogState, formState]);

  const showPasswordFields = dialogState.mode === "add" || formState.isEditingPassword;
  const togglePasswordEdit = () => {
    setFormState({ ...formState, isEditingPassword: !formState.isEditingPassword, password: "", passwordRepeat: "" });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={css.userDialog}
      maxWidth="md"
      PaperProps={{ onSubmit, component: "form" }}
    >
      <DialogTitle>{dialogState.mode === "add" ? "Add New User" : "Edit User"}</DialogTitle>
      <DialogContent>
        <div className={css.inputGroup}>
          <TextField
            value={formState.fullName}
            onChange={e => onFormStateChange("fullName", e.target.value)}
            required
            margin="dense"
            name="fullName"
            label="Full Name"
            type="text"
            variant="outlined"
          />
          <TextField
            value={formState.email}
            onChange={e => onFormStateChange("email", e.target.value)}
            disabled={dialogState.mode === "edit"}
            autoFocus
            required
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            variant="outlined"
          />
          {showPasswordFields && (
            <>
              <TextField
                value={formState.password}
                onChange={e => onFormStateChange("password", e.target.value)}
                required
                margin="dense"
                name="password"
                label="Password"
                type="password"
                variant="outlined"
              />
              <TextField
                value={formState.passwordRepeat}
                onChange={e => {
                  e.target.setCustomValidity(e.target.value !== formState.password ? "Passwords don't match." : "");
                  onFormStateChange("passwordRepeat", e.target.value);
                }}
                required
                margin="dense"
                name="passwordConfirm"
                label="Confirm Password"
                type="password"
                variant="outlined"
              />
            </>
          )}
        </div>
        {dialogState.mode === "edit" && (
          <Button variant="outlined" onClick={togglePasswordEdit}>
            {formState.isEditingPassword ? "Cancel password change" : "Change password"}
          </Button>
        )}
      </DialogContent>
      <DialogContent dividers>
        <PermissionSelect permissions={formState.permissions} onPermissionsChange={onPermissionsChange} />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "transparent" }}>
        <Button onClick={onClose} color="primary" disabled={mutation.isPending}>Cancel</Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          sx={{ paddingInline: "1.5rem" }}
          disabled={mutation.isPending}
        >
          {dialogState.mode === "add" ? (mutation.isPending ? "Creating user..." : "Add New User") : (mutation.isPending ? "Updating user..." : "Update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
