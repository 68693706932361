import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Field } from "formik";
import { useMemo } from "react";
import { AutocompleteField, CheckboxField, SelectField, StringDateRangePickerField } from "src/components/UI/Form";
import { getAuditAccounts } from "src/queries/audit-accounts";
import { WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketView } from "../../WorkPacketView";
import { staticFilterOptions } from "../helpers/getStaticFilterOptions";
import { type FiltersForm } from "./WorkPacketsGridFilters";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";
import { getVendorList } from "src/queries/vendors";

const recoveryStreamOptions = [
  { value: "PPV", title: "PPV" },
  { value: "PQV", title: "PQV" },
];

export const ShortageFiltersForm: FiltersForm = ({
  props: { dynamicFilterOptions, userNickname, view },
  formApi: { setFieldValue },
}) => {
  const { data: auditAccounts } = useQuery({
    queryKey: ["audit-accounts"],
    queryFn: getAuditAccounts,
    staleTime: 30000,
  });

  const { data: vendorList } = useQuery({
    queryKey: ["vendors"],
    queryFn: getVendorList,
    staleTime: 30000,
  });

  const vendorOptions = useMemo(
    () => (vendorList ?? []).map(vendor => ({ value: vendor.VENDOR_NAME, title: vendor.VENDOR_NAME })),
    [vendorList],
  );

  const auditAccountOptions = useMemo(
    () => (auditAccounts ?? []).map(name => ({ value: name, title: name })),
    [auditAccounts],
  );

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr) 1.5fr",
        },
        gap: 2,
        rowGap: 3,
      }}
    >
      <Field
        name={WorkPacketFilter.VendorName}
        component={AutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Vendor Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={AutocompleteField}
        label="Store Name"
        options={dynamicFilterOptions[WorkPacketFilter.StoreName] ?? []}
        getOptionLabel={(option: any) => option.title}
        placeholder="Store Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.PacketStage}
        component={SelectField}
        label="Packet Stage"
        options={staticFilterOptions[WorkPacketFilter.PacketStage] ?? []}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={staticFilterOptions[WorkPacketFilter.CurrentAction] ?? []}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.ManualFilingUser}
        component={AutocompleteField}
        label="Manual Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: any) => option.title}
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.RecoveryStream}
        component={AutocompleteField}
        label="Recovery Stream"
        options={recoveryStreamOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(view, userNickname)}
        options={dynamicFilterOptions[WorkPacketFilter.CurrentPacketOwner] ?? []}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disabled={view !== WorkPacketView.AllPackets}
      />

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box flexGrow={1}>
          <Field
            name={WorkPacketFilter.DisputeCreatedAt}
            component={StringDateRangePickerField}
            label="Dispute - Created At"
          />
        </Box>
        <Field type="checkbox" name={WorkPacketFilter.RedFlags} component={CheckboxField} label="Red flags" />
      </Box>
    </Box>
  );
};
