import ClientConfigServices from "../../../../../Services/ClientConfigServices.js";
import FileDownloadServices from "../../../../../Services/FileDownloadServices.js";

const addVendor = async (inputData, toast, getData) => {
  try {
    let response = await ClientConfigServices.addVendor(inputData);
    if (response.status === 201) {
      toast.success(response.data.message);
      getData();
    }
  } catch (e) {
    setLoader(false);
  }
};

const updateVendor = async (vendorId, inputData, toast, getData) => {
  try {
    let response = await ClientConfigServices.editVendor(vendorId, inputData);
    if (response.status === 200) {
      toast.success(response.data.message);
      getData();
    }
  } catch (e) {}
};

const exportVendors = async (setExportLoader) => {
  setExportLoader(true);
  try {
    let response = await ClientConfigServices.exportVendors();
    if (response.status === 200) {
      FileDownloadServices.downloadFile(response, "vendors", "csv");
    }
  } catch (e) {}
  setExportLoader(false);
};

export { addVendor, updateVendor, exportVendors };
