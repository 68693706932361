import {
  KeyboardArrowDownOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import usePermissions from "components/CustomHooks/usePermissions";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import SidebarLink from "./SidebarLink";
import { Icon } from "components/UI/Icon";

interface SidebarNestedLinkProps {
  title: string;
  iconName: string;
  minimized?: boolean;
  subMenu: {
    title: string;
    to: string;
    iconName?: string;
    permission: string[];
  }[];
  forceActiveOnUrls?: string[];
}

const SidebarNestedLink = ({
  title,
  iconName,
  minimized,
  subMenu,
  forceActiveOnUrls,
}: SidebarNestedLinkProps) => {
  const location = useLocation();
  const { hasPermission } = usePermissions();

  const isActive = forceActiveOnUrls?.some((url) => location.pathname === url) || subMenu?.some(x => x.to === location.pathname);
  const [expanded, setExpanded] = useState(isActive);
  const handleToggle = () => setExpanded(!expanded);

  return (
    <Box
      color="#0a0f27"
      bgcolor={minimized ? "#f7f7f7" : "transparent"}
      borderRadius="8px"
    >
      <Tooltip
        title={title}
        placement="right-end"
        disableHoverListener={!minimized}
      >
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          paddingX="12px"
          paddingY="10px"
          onClick={handleToggle}
          sx={{
            cursor: "pointer",
            color: isActive ? "white" : "#0A0F27",
            backgroundColor: isActive ? "#F7104D" : "",
            borderRadius: "8px",
            textDecoration: "none",
            svg: {
              color: isActive ? "white" : "#0A0F27",
            },
            hover: {
              backgroundColor: isActive ? "#F7104D" : "#FFE4E8",
              svg: {
                color: isActive ? "white" : "#F7104D",
              },
            },
          }}
        >
          <Box>
            {/* @ts-ignore */}
            <Icon name={iconName} color="inherit" />
          </Box>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body2"
              noWrap
              sx={{
                opacity: minimized ? 0 : 1,
                visibility: minimized ? "hidden" : "visible",
                transition: "opacity 0.3s",
              }}
            >
              {title}
            </Typography>

            <Box
              sx={{
                opacity: minimized ? 0 : 1,
                visibility: minimized ? "hidden" : "visible",
                transition: "opacity 0.3s",
              }}
            >
              {expanded ? (
                <KeyboardArrowDownOutlined />
              ) : (
                <KeyboardArrowRightOutlined />
              )}
            </Box>
          </Box>
        </Box>
      </Tooltip>

      {expanded && (
        <Box
          paddingLeft={minimized ? "0" : "30px"}
          paddingY={minimized ? "6px" : "10px"}
        >
          {subMenu.map((subItem, index) => {
            if (!hasPermission(subItem.permission)) return null;
            return (
              <SidebarLink
                key={index}
                title={subItem.title}
                to={subItem.to}
                iconName={subItem.iconName || ""}
                minimized={minimized}
                isSubItem
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SidebarNestedLink;
