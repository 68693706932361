import Services from "./ServicesRoutes";
import { http } from "./axios";

//user
const login = (data) => {
  return http.post(Services.login, data);
};
const Logout = () => {
  return http.put(Services.login);
};
//ossr
const ListQueries = (limit, offset, data) => {
  return http.put(Services.Ossr + limit + "/" + offset, data);
};
const CreateUserQuery = (data) => {
  return http.post(Services.Ossr + undefined + "/" + undefined, data);
};
const UserDeleteQuery = (id) => {
  return http.delete(Services.Ossr + id + "/" + undefined);
};
const UserDownlaodQuery = (type, id) => {
  return http.get(Services.Ossr + type + "/" + id, {responseType:'arraybuffer'});
};

//ossr new
const ListQueriesNew = (limit, offset, data) => {
  return http.put(Services.Ossr_new + limit + "/" + offset, data);
};
const CreateUserQueryNew = (data) => {
  return http.post(Services.Ossr_new + undefined + "/" + undefined, data);
};
const UserDeleteQueryNew = (id) => {
  return http.delete(Services.Ossr_new + id + "/" + undefined);
};
const UserDownlaodQueryNew = (type, id) => {
  return http.get(Services.Ossr_new + type + "/" + id, {responseType:'arraybuffer'});
};

//dispute tool
const ListDisputesVendors = (limit, offset, data) => {
  return http.put(
    Services.DisputeTool + "vendor/" + limit + "/" + offset,
    data
  );
};
const EditDisputesVendors = (data) => {
  return http.patch(Services.DisputeTool + undefined + "/" + undefined, data);
};
const CreateDisputesVendors = (data) => {
  return http.post(Services.DisputeTool + null + "/" + null + "/" + null, data);
};
const DownlaodFile = (type, id) => {
  return http.get(Services.DisputeTool + id + "/" + type + "/" + null);
};
const DownlaodSampleFile = (type, id) => {
  return http.get(Services.Ossr + type + "/" + id);
};
// manage phrases
const ListPhrases = (limit, offset, type, data) => {
  return http.put(
    Services.ManagePhrase + type + "/" + limit + "/" + offset,
    data
  );
};
const EditPhrases = (id, data) => {
  return http.patch(
    Services.ManagePhrase + id + "/" + undefined + "/" + undefined,
    data
  );
};
const CreatePhrases = (data) => {
  return http.post(
    Services.ManagePhrase + "Create/" + undefined + "/" + undefined,
    data
  );
};
const DeletePhrase = (id) => {
  return http.delete(
    Services.ManagePhrase + id + "/" + undefined + "/" + undefined
  );
};
const GetRegion = () => {
  return http.get(Services.GetRegion);
};
const DownlaodPhrasesFile = (type, id) => {
  return http.get(Services.DisputeTool + id + "/" + type + "/" + null);
};
//Configuration
const ListConfiguration = (limit, offset, data) => {
  return http.put(Services.Configuration + limit + "/" + offset, data);
};
const ListConfigurationData = (store, checked, limit, offset, data) => {
  return http.put(
    Services.ConfigurationData +
      limit +
      "/" +
      offset +
      "/" +
      store +
      "/" +
      checked,
    data
  );
};
const EditConfigurationData = (store, checked, id, type) => {
  return http.patch(
    Services.ConfigurationData + id + "/" + checked + "/" + type + "/" + store
  );
};
const CreateConfiguration = (data) => {
  return http.post(Services.Configuration + undefined + "/" + undefined, data);
};
const DeleteConfiguration = (id) => {
  return http.delete(
    Services.Configuration + id + "/" + undefined + "/" + undefined
  );
};

//shortage dispute
const ListShotageDispute = (limit, offset, data) => {
  return http.put(
    Services.ShortageDispute + "vendor/" + limit + "/" + offset,
    data
  );
};
const EditShotageDispute = (data) => {
  return http.patch(
    Services.ShortageDispute + undefined + "/" + undefined,
    data
  );
};
const CreateShotageDispute = (data) => {
  return http.post(
    Services.ShortageDispute + null + "/" + null + "/" + null,
    data
  );
};
const ShotageDisputeDownlaodFile = (type, id) => {
  return http.get(Services.ShortageDispute + id + "/" + type + "/" + null);
};
const ShotageDisputeDownlaodSampleFile = (type, id) => {
  return http.get(Services.ShortageDispute + id + "/" + type + "/" + null);
};

//invoicing automation
const UserDownlaodInvoicingAutomation = (store) => {
  return http.get(Services.InvoicingAutomation + "/" + store);
};

//Custom Branding
const ListCustomBrandingCustomers = () => {
  return http.get(Services.base +
    Services.version + Services.CustomBranding);
};

//UsageHistory -- MDTAnalytics

const GetMDTAnalytics = (data) => {
  return http.post(
    Services.MDTAnalytics + undefined + "/" + undefined + "/" + undefined,
    data
  );
};

const GetMDTAnalyticsTableData = (limit, offset, data) => {
  return http.put(
    Services.MDTAnalytics + limit + "/" + offset + "/" + undefined,
    data
  );
};

const GetMDTAnalyticsModalTableData = (id, limit, offset, data) => {
  return http.patch(
    Services.MDTAnalytics + id + "/" + limit + "/" + offset,
    data
  );
};

const ShortageModalCasesDownload = (id, cases, store) => {
  return http.get(Services.MDTAnalytics + id + "/" + cases + "/" + store);
};

const GetAllAccounts = (page = undefined, per_page = undefined, query = undefined, abortController = undefined) => {
  return http.get(
      Services.base +
      Services.version +
      Services.accounts,
      {
        params: {
          ...(page ? { page } : {}),
          ...(per_page ? { per_page } : {}),
          ...(query ? { query } : {}),
        },
        ...(abortController ? {signal: abortController.signal} : {}),
      }
  )
}


const TotalServices = {
  //ossr
  ListQueries,
  CreateUserQuery,
  UserDeleteQuery,
  UserDownlaodQuery,

  //ossr new
  ListQueriesNew,
  CreateUserQueryNew,
  UserDeleteQueryNew,
  UserDownlaodQueryNew,

  //dispute tool
  DownlaodSampleFile,
  DownlaodFile,
  CreateDisputesVendors,
  EditDisputesVendors,
  ListDisputesVendors,
  //manage phrase
  DownlaodPhrasesFile,
  CreatePhrases,
  ListPhrases,
  EditPhrases,
  GetRegion,
  DeletePhrase,
  //Configuration
  CreateConfiguration,
  ListConfiguration,
  ListConfigurationData,
  EditConfigurationData,

  //Custom Branding
  ListCustomBrandingCustomers,

  //shortage dispute
  ShotageDisputeDownlaodSampleFile,
  ShotageDisputeDownlaodFile,
  CreateShotageDispute,
  EditShotageDispute,

  ListShotageDispute,

  //Invoicing Automation
  UserDownlaodInvoicingAutomation,

  //UsageHistory -- MDTAnalytics
  GetMDTAnalytics,
  GetMDTAnalyticsTableData,
  GetMDTAnalyticsModalTableData,
  ShortageModalCasesDownload,

  login,
  Logout,

  GetAllAccounts
};

export default TotalServices;
