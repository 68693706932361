import {useContext} from "react";
import {MyContext} from "../../App.jsx";

const usePermissions = () => {
  const {permissions} = useContext(MyContext);

  const hasPermission = (required_permissions, require_all = false) => {
    let requires = new Map();
    let remaining_requirements = required_permissions;

    for (const [index, required_permission] of required_permissions.entries()) {
      const components = required_permission.split(":")
      let permission = ''
      components.forEach((component) => {
        if (!component.includes("*")) {
          permission = permission + component + ":"
          requires.set(permission + "*", required_permission)
        } else {
          permission = permission + "*:"
        }
      });
    }

    for (const [index, permission] of permissions.entries()) {
      const permission_components = permission.split(":")

      // All requirements met
      if (remaining_requirements.length === 0) {
        return true;
      }

      // Complete Match of Permission and Requirement
      if (remaining_requirements.includes(permission)) {
        if (require_all) {
          remaining_requirements = remaining_requirements.filter((r) => r !== permission)
          continue;
        } else {
          return true;
        }
      }

      for (const [index, remaining_requirement] of remaining_requirements.entries()) {
        const components = remaining_requirement.split(":")
        let match = true

        for (const [index, accept_component] of components.entries()) {
          if (accept_component === '*' || permission_components[index] === '*') {
            continue;
          }

          if (permission_components[index] !== accept_component) {
            match = false;
            break;
          }
        }

        if (match) {
          if (require_all) {
            remaining_requirements = remaining_requirements.filter((r) => r !== remaining_requirement)
          } else {
            return true;
          }
        }
      }

      for (const [require, required_permission] of requires.entries()) {
        // No longer required (already met)
        if (!required_permissions.includes(required_permission)) {
          continue;
        }

        // Wildcard Match
        if (permissions.includes(require)) {
          if (require_all) {
            remaining_requirements = remaining_requirements.filter((r) => r !== required_permission)
          } else {
            return true;
          }
        }
      }
    }

    if (require_all) {
      return remaining_requirements.length === 0;
    } else {
      return false;
    }
  }

  return {
    hasPermission,
    isAdmin: hasPermission(["admin:*"]),
    permissions,
  };
};

export default usePermissions;
