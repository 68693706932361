import Services from "../ServicesRoutes";
import { http } from "../axios";
import FileDownloadServices from "./FileDownloadServices.js";

import {toast} from "react-hot-toast";
import {confirmAlert} from 'react-confirm-alert';

const listEvidences = (account, page, abortController) => {

    return http.get(Services.base + Services.version + Services.obar, {
        params: {
            account_id: account, page
        }, ...(abortController ? {signal: abortController.signal} : {})
    });
}

const downloadEvidences = async (amazon_dispute_id, account, setDownloading, abortController) => {
    try {
        const response = await http.get(Services.base + Services.version + Services.obar + '/export', {
            params: {
                account_id: account, amazon_dispute_id
            }, responseType: 'blob', ...(abortController ? {signal: abortController.signal} : {})
        })

        setDownloading(undefined)
        if (response.status === 200) {
            FileDownloadServices.downloadFile(response, 'evidences', 'csv')
            toast.success(amazon_dispute_id + ' downloaded successfully')
        }
    } catch (e) {
        setDownloading(undefined)
    }
}

const deleteEvidences = async (amazon_dispute_id, account, setDeleting, fetch) => {
    try {
        const response = await http.delete(Services.base + Services.version + Services.obar, {
            params: {
                account_id: account, amazon_dispute_id
            }
        })

        setDeleting(undefined)

        if (response.status === 200) {
            toast.success(amazon_dispute_id + ' deleted successfully')
        }
        fetch()
    } catch (e) {
        setDeleting(undefined)
    }
}

const uploadEvidences = async (account, file, setUploading, setFile, fetch, override = false) => {
    try {
        setUploading(true)
        let formData = new FormData();
        formData.append('file', file);

        const response = await http.post(Services.base + Services.version + Services.obar, formData, {
            params: {
                account_id: account, override
            }, headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        setUploading(false)
        if (response.status === 200) {
            toast.success('File uploaded successfully')
            await fetch()
        }

        setFile(undefined)
    } catch (e) {
        if (e.response?.status === 409) {
            confirmAlert({
                title: 'File already exists', message: 'Are you sure to override this file?', buttons: [{
                    label: 'Yes', onClick: async () => {
                        await uploadEvidences(account, file, setUploading, setFile, fetch, true)
                    }
                }, {
                    label: 'No', onClick: () => {
                        setFile(undefined)
                    }
                }], closeOnEscape: true, closeOnClickOutside: true,
            });
        }

        setUploading(false)
    }
}

const generateOBAR = async (account, setGenerating) => {
    try {
        setGenerating(true)

        const response = await http.get(Services.base + Services.version + Services.generate_obar, {
            params: {
                account_id: account.value
            }, responseType: 'blob'
        })
        if (response.status === 200) {
            FileDownloadServices.downloadFile(response, 'Accruals Overbilling', 'zip')
            toast.success('File generated successfully for ' + account.label)
        }

        setGenerating(false)
    } catch (e) {
        setGenerating(false)
    }
}

const OBARServices = {
    listEvidences, downloadEvidences, deleteEvidences, uploadEvidences, generateOBAR
};

export default OBARServices;
