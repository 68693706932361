import type { InputData, Vendor } from "../types/vendor.ts";

const mapToUpdateVendor = (inputData: Partial<InputData>): Partial<Vendor> => {
  const vendor: Partial<Vendor> = {};

  if (
    inputData.REFERRAL ||
    inputData.REFERRAL_COMMISSION_RATE ||
    inputData.REFERRAL_COMMISSION_DURATION
  ) {
    vendor.referrals = [mapFirstReferral(inputData)];
  }

  if (
    inputData.REFERRAL_2 ||
    inputData.REFERRAL_2_COMMISSION_RATE ||
    inputData.REFERRAL_2_COMMISSION_DURATION
  ) {
    if (!vendor.referrals) {
      vendor.referrals = [];
    }
    vendor.referrals.push(mapSecondReferral(inputData));
  }

  if (inputData.SPECIFIC_POINT_OF_CONTACT_NAME) {
    vendor.specific_point_of_contact_name =
      inputData.SPECIFIC_POINT_OF_CONTACT_NAME;
  }

  if (inputData.SPECIFIC_POINT_OF_CONTACT_EMAIL) {
    vendor.specific_point_of_contact_email =
      inputData.SPECIFIC_POINT_OF_CONTACT_EMAIL;
  }

  if (inputData.VENDOR_NAME) {
    vendor.name = inputData.VENDOR_NAME;
  }

  if (inputData.TIER) {
    vendor.tier = inputData.TIER;
  }

  if (inputData.INITIAL_COMMISSION_RATE) {
    vendor.initial_commission_rate = inputData.INITIAL_COMMISSION_RATE;
  }

  if (inputData.FINAL_COMMISSION_RATE) {
    vendor.final_commission_rate = inputData.FINAL_COMMISSION_RATE;
  }

  if (inputData.ENGAGEMENT_FEE) {
    vendor.engagement_fee = inputData.ENGAGEMENT_FEE;
  }

  if (inputData.CURRENT_RECOVERY) {
    vendor.current_recovery = inputData.CURRENT_RECOVERY;
  }

  if (inputData.NOTES) {
    vendor.notes = inputData.NOTES;
  }

  if (inputData.RECOVERY_THRESHOLD) {
    vendor.recovery_threshold = inputData.RECOVERY_THRESHOLD;
  }

  if (inputData.PHONE) {
    vendor.phone = inputData.PHONE;
  }

  if (inputData.AGREEMENT_SIGNED_DATE) {
    vendor.agreement_signed_date = new Date(inputData.AGREEMENT_SIGNED_DATE)
      .toISOString()
      .split("T")[0];
  }

  if (inputData.KICK_OFF) {
    vendor.kick_off = new Date(inputData.KICK_OFF).toISOString().split("T")[0];
  }

  if (inputData.BILLING_NAME) {
    vendor.billing_name = inputData.BILLING_NAME;
  }

  if (inputData.BILLING_EMAIL) {
    vendor.billing_email = inputData.BILLING_EMAIL;
  }

  if (inputData.VENDOR_TYPE) {
    vendor.vendor_type = inputData.VENDOR_TYPE;
  }

  return vendor;
};

const mapToCreateVendor = (inputData: InputData): Vendor => {
  const vendor: Vendor = {
    referrals: [mapFirstReferral(inputData)],
    specific_point_of_contact_name: inputData.SPECIFIC_POINT_OF_CONTACT_NAME,
    specific_point_of_contact_email: inputData.SPECIFIC_POINT_OF_CONTACT_EMAIL,
    name: inputData.VENDOR_NAME,
    tier: inputData.TIER,
    initial_commission_rate: inputData.INITIAL_COMMISSION_RATE,
    final_commission_rate: inputData.FINAL_COMMISSION_RATE,
    engagement_fee: inputData.ENGAGEMENT_FEE,
    recovery_threshold: inputData.RECOVERY_THRESHOLD,
    agreement_signed_date: new Date(inputData.AGREEMENT_SIGNED_DATE)
      .toISOString()
      .split("T")[0],
    vendor_type: inputData.VENDOR_TYPE
  };
  if (
    inputData.REFERRAL_2 ||
    inputData.REFERRAL_2_COMMISSION_RATE ||
    inputData.REFERRAL_2_COMMISSION_DURATION
  ) {
    vendor.referrals.push(mapSecondReferral(inputData));
  }

  if (inputData.BILLING_NAME) {
    vendor.billing_name = inputData.BILLING_NAME;
  }

  if (inputData.BILLING_EMAIL) {
    vendor.billing_email = inputData.BILLING_EMAIL;
  }
  

  if (inputData.NOTES) {
    vendor.notes = inputData.NOTES;
  }

  if (inputData.PHONE) {
    vendor.phone = inputData.PHONE;
  }

  if (inputData.KICK_OFF) {
    vendor.kick_off = new Date(inputData.KICK_OFF).toISOString().split("T")[0];
  }

  return vendor;
};

export { mapToUpdateVendor, mapToCreateVendor };

const mapFirstReferral = (inputData: Partial<InputData>) => {
  return {
    name: inputData.REFERRAL ?? null,
    commission_rate:
        inputData.REFERRAL_COMMISSION_RATE &&
        inputData.REFERRAL_COMMISSION_RATE !== ""
            ? Number(inputData.REFERRAL_COMMISSION_RATE)
            : null,
    commission_eligibility_end_at: inputData.REFERRAL_COMMISSION_DURATION
        ? new Date(inputData.REFERRAL_COMMISSION_DURATION)
            .toISOString()
            .split("T")[0]
        : null,
    partner_id: inputData.REFERRAL_PARTNER_ID ?? null,
    payment_method: inputData.REFERRAL_PAYMENT_METHOD &&
    inputData.REFERRAL_PAYMENT_METHOD !== ""
        ? inputData.REFERRAL_PAYMENT_METHOD
        : null,
    custom_commission_rate: inputData.REFERRAL_CUSTOM_COMMISSION_RATE &&
    inputData.REFERRAL_CUSTOM_COMMISSION_RATE !== ""
        ? inputData.REFERRAL_CUSTOM_COMMISSION_RATE
        : null,
    partner_type: inputData.REFERRAL_PARTNER_TYPE &&
    inputData.REFERRAL_PARTNER_TYPE !== ""
        ? inputData.REFERRAL_PARTNER_TYPE
        : null,
  }
}

const mapSecondReferral = (inputData: Partial<InputData>) => {
  return {
    name: inputData.REFERRAL_2 ?? null,
    commission_rate:
        inputData.REFERRAL_2_COMMISSION_RATE &&
        inputData.REFERRAL_2_COMMISSION_RATE !== ""
            ? Number(inputData.REFERRAL_2_COMMISSION_RATE)
            : null,
    commission_eligibility_end_at: inputData.REFERRAL_2_COMMISSION_DURATION
        ? new Date(inputData.REFERRAL_2_COMMISSION_DURATION)
            .toISOString()
            .split("T")[0]
        : null,
    partner_id: inputData.REFERRAL_2_PARTNER_ID ?? null,
    payment_method: inputData.REFERRAL_2_PAYMENT_METHOD &&
    inputData.REFERRAL_2_PAYMENT_METHOD !== ""
        ? inputData.REFERRAL_2_PAYMENT_METHOD
        : null,
    custom_commission_rate: inputData.REFERRAL_2_CUSTOM_COMMISSION_RATE &&
    inputData.REFERRAL_2_CUSTOM_COMMISSION_RATE !== ""
        ? inputData.REFERRAL_2_CUSTOM_COMMISSION_RATE
        : null,
    partner_type: inputData.REFERRAL_2_PARTNER_TYPE &&
    inputData.REFERRAL_2_PARTNER_TYPE !== ""
        ? inputData.REFERRAL_2_PARTNER_TYPE
        : null,
  };
}
