const downloadFile = (response, default_name = 'download', ext = 'xlsx') => {
    const href = window.URL.createObjectURL(response.data);
    const anchorElement = document.createElement('a');

    anchorElement.href = href;

    // 1) Get the value of content-disposition header
    const contentDisposition = response.headers['content-disposition'];

    // 2) set the fileName variable to the default value
    let fileName = default_name+"."+ext;

    // 3) if the header is set, extract the filename
    if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=(.+)/);

        if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
        }
    }
    anchorElement.download = fileName.replace(/['"]+/g, '');

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
}

const FileDownloadServices = {
    downloadFile
};

export default FileDownloadServices;
