import Services from "../ServicesRoutes";
import { http } from "../axios";

//ClientConfigs -- Vendors

const GetVendorsByID = (vendorID, signal) => {
  return http.get(
    Services.base + Services.v2 + "/" + Services.vendors + "/" + vendorID,
    signal
  );
};

const addVendor = (data) => {
  return http.post(Services.base + Services.v2 + "/" + Services.vendors, data);
};

const editVendor = (vendorID, data) => {
  return http.put(
    Services.base + Services.v2 + "/" + Services.vendors + "/" + vendorID,
    data
  );
};

const exportVendors = () => {
  return http.get(
    Services.base + Services.version + Services.vendors + "/" + "export",
    { responseType: "blob" }
  );
};

//ClientConfigs -- get all scopes

const GetAllSpoc = (limit, offset, data) => {
  return http.put(
    Services.base +
      Services.version +
      Services.spoc +
      "/" +
      limit +
      "/" +
      offset,
    data
  );
};

// add scopes

const addScopes = (accountID, data) => {
  return http.post(
    Services.base +
      Services.version +
      Services.accounts +
      "/" +
      accountID +
      "/" +
      Services.scope,
    data
  );
};

//ClientConfigs -- get all referral

const GetAllReferral = (signal) => {
  return http.get(Services.base + Services.version + Services.referral, signal);
};

//ClientConfigs -- get all shipping

const GetAllShipping = () => {
  return http.get(Services.base + Services.version + Services.shipping);
};

//ClientConfigs -- get all avcusers

const GetAllAvcUsers = () => {
  return http.get(Services.base + Services.version + Services.avcuser);
};

//ClientConfigs -- get all shortage_processor

const GetAllShortageProcessor = () => {
  return http.get(
    Services.base + Services.version + Services.shortageProcessor
  );
};

//ClientConfigs -- get all chargebacks_processor

const GetAllChargebackProcessor = () => {
  return http.get(
    Services.base + Services.version + Services.chargebackProcessor
  );
};

//ClientConfigs -- Accounts

const GetVendorAccounts = (vendorId, data) => {
  return http.get(
    Services.base +
      Services.version +
      Services.vendors +
      "/" +
      `${vendorId}/` +
      Services.accounts +
      "?keyword=" +
      data.query
  );
};

// add accounts

const addAccounts = (vendorId, data) => {
  return http.post(
    Services.base +
      Services.version +
      Services.vendors +
      "/" +
      vendorId +
      "/" +
      Services.accounts,
    data
  );
};

// edit accounts

const editAccounts = (vendorId, accountsID, data) => {
  return http.put(
    Services.base +
      Services.version +
      Services.vendors +
      "/" +
      vendorId +
      "/" +
      Services.accounts +
      "/" +
      accountsID,
    data
  );
};

//delete accounts

const deleteAccounts = (vendorId, accountsID) => {
  return http.delete(
    Services.base +
      Services.version +
      Services.vendors +
      "/" +
      vendorId +
      "/" +
      Services.accounts +
      "/" +
      accountsID
  );
};

//ClientConfigs -- Scopes

const GetVendorScopes = (scopesId) => {
  return http.get(Services.clientConfig + Services.scopes + vendorId);
};

//ClientConfigs -- Get All Scopes

const GetAccountsScopes = (accountsID) => {
  return http.get(
    Services.base +
      Services.version +
      Services.accounts +
      "/" +
      accountsID +
      "/" +
      Services.scope
  );
};

const updateScope = ({ accountID, scopeID, data }) => {
  const url = `${Services.base}${Services.v2}/${Services.accounts}/${accountID}/${Services.scope}/${scopeID}`;
  return http.put( url, data );
};

//delete scopes for accounts

const deleteScopes = (accountID, scopesID) => {
  return http.delete(
    Services.base +
      Services.version +
      Services.accounts +
      "/" +
      accountID +
      "/" +
      Services.scope +
      "/" +
      scopesID
  );
};

const ClientConfigServices = {
  //Client config
  editVendor,
  GetAllSpoc,
  GetAllAvcUsers,
  GetVendorsByID,
  updateScope,
  GetAccountsScopes,
  GetAllShipping,
  addAccounts,
  deleteScopes,
  editAccounts,
  deleteAccounts,
  GetAllReferral,
  addVendor,
  GetVendorAccounts,
  GetVendorScopes,
  addScopes,
  GetAllShortageProcessor,
  GetAllChargebackProcessor,
  exportVendors,
};
export default ClientConfigServices;
