import { SearchOutlined } from "@mui/icons-material";
import { Box, InputAdornment } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

const GridToolbar = () => {
  return (
    <Box mb={1}>
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        placeholder="Search across all columns..."
        sx={{ width: "100%" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default GridToolbar;
