import { forwardRef, SVGProps } from "react";

import { icons } from "./icons";

interface Props extends SVGProps<SVGSVGElement> {
  name: keyof typeof icons;
  size?: string;
  color?: string;
}

/**
 * Component used for rendering monochromatic square SVG icons.
 * In order to add a new icon to this component, place your SVG file in the `./icons` folder, and import and register your icon in the {@link icons} map.
 */
export const Icon = forwardRef<SVGSVGElement, Props>(
  ({ name, size, color = "#0A0F27", style, ...forwardedProps }: Props, ref) => {
    const Component = icons[name];

    return (
      <Component
        ref={ref}
        width={"24px" ?? size}
        height={"24px" ?? size}
        style={{
          color,
          ...style,
        }}
        {...forwardedProps}
      />
    );
  }
);

Icon.displayName = "Icon";
