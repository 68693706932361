import { useMutation, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useId, useState, type FC, type FormEvent } from "react";
import { toast } from "react-hot-toast";
import { http } from "src/axios";
import { getAccounts } from "src/queries/accounts";
import { queryClient } from "src/query-client";

import Button from "@mui/material/Button";
import { FaCloudUploadAlt } from "react-icons/fa";
import CreatableSelect from "react-select";
import { ClipLoader } from "react-spinners";

import css from "./OssrCreateQuery.module.css";

type SelectOption = { value: string, label: string };

const createQuery = (storeName: string) => http.post("/OSSR/0/0", { store: storeName });

const onCreateSuccess = (response: AxiosResponse) => {
  toast.success(response.data.message);
  queryClient.invalidateQueries({ queryKey: ["ossr"] });
};

export const OssrCreateQuery: FC = () => {
  const query = useQuery({ queryFn: getAccounts, queryKey: ["accounts"], staleTime: 0 });

  const mutation = useMutation({
    mutationKey: ["ossr-create"],
    mutationFn: createQuery,
    onSuccess: onCreateSuccess,
  });

  const options: SelectOption[] = query.data?.stores.map(store => {
    const value = store["Account Config Vendor Name"] ?? store["Account Name"];
    return { value, label: value };
  }) ?? [];

  const [selectedStore, setSelectedStore] = useState<SelectOption | null>(null);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!selectedStore) return;
    mutation.mutate(selectedStore.value);
  };

  const selectId = useId();

  return (
    <form onSubmit={onSubmit}>
      <div className={css.ossrCreateQuery}>
        <label htmlFor={selectId}>
          Select Store
        </label>
        <CreatableSelect
          className={css.select}
          id={selectId}
          name="color"
          placeholder="Store"
          isLoading={query.isFetching}
          isClearable
          value={selectedStore}
          options={options}
          onChange={setSelectedStore}
        />

        <Button
          color="error"
          variant="contained"
          type="submit"
          disabled={!selectedStore || mutation.isPending}
          startIcon={mutation.isPending ? <ClipLoader size={16} color="white" /> : <FaCloudUploadAlt />}
        >
          Start Processing
        </Button>
      </div>
    </form>
  );
};
