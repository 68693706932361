import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  CircularProgress,
  Box,
} from "@mui/material";
import { type FC, useCallback, useEffect, useRef, useState } from "react";
import { Summary } from "./Summary";
import { ChangeLog } from "./ChangeLog";
import { useAuth0 } from "@auth0/auth0-react";
import type { WorkPacket } from "src/types/work-packets";
import * as WorkPacketsAPI from "../api/workPacketsAPI";
import { WorkPacketView } from "../WorkPacketView";
import debounce from "lodash.debounce";
import type { WorkPacketType } from "../WorkPacketType";
import { useWorkPacketsContext } from "../WorkPacketsContext.tsx";

interface DetailsPopupProps {
  workPacketId: string;
  open: boolean;
  view: WorkPacketView;
  onClose: () => void;
  onSuccessfulUpdate?: () => void;
}

enum TabIndex {
  Summary,
  ChangeLog,
}

export const WorkPacketDetailsPopup: FC<DetailsPopupProps> = ({
  workPacketId,
  open,
  onClose,
  onSuccessfulUpdate,
}) => {
  const { user } = useAuth0();
  const { currentWorkPacketType } = useWorkPacketsContext();
  const [selectedTab, setSelectedTab] = useState<TabIndex>(TabIndex.Summary);
  const [workPacket, setWorkPacket] = useState<WorkPacket | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<any>(null);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const fetchWorkPacketDetails = useCallback(
    debounce(
      async (workPacketId: string, workPacketType: WorkPacketType) => {
        if (!user?.sub) return;

        setLoading(true);
        try {
          const data = await WorkPacketsAPI.fetchWorkPacketById({
            workPacketType,
            workPacketId,
            userId: user.sub,
          });

          setWorkPacket(data);
        } catch (error) {
          console.error("Error in fetchWorkPacketDetails:", error);
        } finally {
          setLoading(false);
        }
      },
      300,
      { leading: true, trailing: false }
    ),
    [user],
  );

  useEffect(() => {
    fetchWorkPacketDetails(workPacketId, currentWorkPacketType);
  }, [user, workPacketId, currentWorkPacketType]);

  if (!workPacket)
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      </Dialog>
    );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Work Packet Details | {workPacket.recoveryStream}</DialogTitle>
      <DialogContent>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Summary" />
          <Tab label="Change Log" />
        </Tabs>

        <Divider />

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {selectedTab === TabIndex.Summary && (
              <Summary
                workPacket={workPacket}
                formRef={formRef}
                onSubmit={() => {
                  onClose();
                  onSuccessfulUpdate && onSuccessfulUpdate();
                }}
                setLoading={setLoading}
              />
            )}
            {selectedTab === TabIndex.ChangeLog && (
              <ChangeLog workPacketId={workPacket.packetId} />
            )}
          </>
        )}
      </DialogContent>

      {selectedTab === TabIndex.Summary && (
        <DialogActions>
          <Button
            className="contained-reversed"
            variant="contained"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => formRef.current.submitForm()}
          >
            <span>Save Changes</span>
            {loading && (
              <CircularProgress
                size={14}
                style={{ marginLeft: "12px", color: "#101828" }}
              />
            )}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
