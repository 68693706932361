import { TextField } from "@mui/material";
import { Field } from "formik";
import { FC } from "react";
import { FormFieldsGroup } from "../components";

export const DetailsFirstDispute: FC = () => {
  return (
    <FormFieldsGroup
      title="First Dispute"
      withDivider
      id="details-first-dispute"
    >
      <Field
        name="first-dispute-financial-charge"
        as={TextField}
        variant="outlined"
        placeholder="First Dispute - Financial Charge"
        size="small"
        label="First Dispute - Financial Charge"
        disabled
      />

      <Field
        name="first-dispute-chargeback-issue-date"
        as={TextField}
        variant="outlined"
        placeholder="First Dispute - Chargeback Issue Date"
        size="small"
        label="First Dispute - Chargeback Issue Date"
        disabled
      />
    </FormFieldsGroup>
  );
};
