import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ModalComp from './Modal';
import CheckStatus from '../../../../components/checkStatus/checkStatus';
import TotalServices from '../../../../TotalServices';
import PaginationButtons from '../../../../components/Pagination/PaginationButtons';
import TableLoader from '../../../../components/Loader/TableLoader';
import Search from '../../../../components/Search/Search';
import OffCanvasTable from './OffCanvasTable';
import NoDataFound from '../../../../components/NoDataFound/NoDataFound';
function OffCanvasComp({ store, show, handleClose , storeStatus }) {


  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  //modal
  const [data, setData] = useState([]);

  // Pagination
  const [totalRecords, setTotalRecords] = useState(data);
  const [searchRecord, setSearchRecord] = useState(0);
  const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(5);

  const [currentPage, setCurrentPage] = useState(1);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(3);

  //Modal

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  // toggle 

  const [checked, setChecked] = useState(storeStatus);






  // Get Data
  const GetConfigurationData = async () => {
    try {
      setLoader(true);
      let response = await TotalServices.ListConfigurationData(store,checked,
        NumberOfRecordsPerPage,
        (currentPage - 1) * NumberOfRecordsPerPage,

        { query: searchQuery }
      );
      if (response.status === 200) {
        // console.log(response);
        setData(response.data.data);
        setTotalPages(response?.data?.pages);
        setTotalRecords(response?.data?.total_records);
        setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage);

        setLoader(false);
      }
    } catch (e) {
    }
  };
 

  useEffect(() => {

    if (checked === true) {
      GetConfigurationData();
    }

    GetConfigurationData();


  }, [currentPage, searchQuery, checked]);


  return (
    <>

      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <div className="container ">
            <h4>Store : <span className='heading_offcanvas'>{store}</span></h4>
            <h4 className='mb-3'>Region : <span className='heading_offcanvas'>{store.slice(0,2)}</span></h4>
            <div className="row">
              <div className="col-md-3 col-6">
                <button className='btn-primary mb-3' onClick={() => handleShowModal()} >
                  Add New
                </button>
              </div>

              <div className="col d-flex justify-content-end mb-3">
                {/* <CheckStatus status={checked} ></CheckStatus> */}
              </div>
            </div>
          </div>

          <div className="container-sm table_main">
            <div className="container">
              <div className="row">
                <div className="col-md-2 col-6 mb-4 mb-md-0">
                <CheckStatus status={checked}
                setChecked = {setChecked}

                ></CheckStatus>
                  {/* <div className="form-check form-switch custom_toggle">
                    <input className="form-check-input custom_toggle_style" type="checkbox" role="switch"
                      defaultChecked={checked}
                      onChange={(e) => {
                        handleChange(e.target.checked);
                      }}
                    />
                  </div> */}
                </div>

                <Search  setSearchQuery={setSearchQuery} searchQuery={searchQuery} currentPage={currentPage} setCurrentPage={setCurrentPage} GetData={GetConfigurationData} placeholder="Search with issue type"/>
              </div>
            </div>


            {!loader ? (
          <>
             {data.length>=1 ? (
              <>
                    <OffCanvasTable data={data} store={store} setLoader={setLoader} />
                    <PaginationButtons
                      totalRecords={totalRecords}
                      setRecord={setSearchRecord}
                      record={searchRecord}
                      NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setGoto={setGoto}
                      goto={goto}
                      totalPages={totalPages}
                    />
</>
) : 
         <NoDataFound />
         }
       </>
     ) : 
     <TableLoader  />}

          </div >


        </Offcanvas.Body>
      </Offcanvas>


      {showModal && <ModalComp showModal={showModal} handleShowModal={handleShowModal}
        handleCloseModal={handleCloseModal}
        store={store}
        getQuery={GetConfigurationData}

      />}


    </>
  );
}

export default OffCanvasComp;