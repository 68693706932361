import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FieldProps } from "formik";
import { FC } from "react";

interface DatePickerFieldProps extends FieldProps {
  label: string;
  disabled?: boolean;
}

/**
 * DatePickerField component integrates Material-UI's DatePicker component with Formik.
 *
 * @param {FieldProps} field - Formik field prop, automatically injected by Formik.
 * @param {object} form - Formik form prop, automatically injected by Formik.
 * @param {string} label - The label for the date picker field.
 * @param {boolean} disabled - Whether the date picker field is disabled.
 */
const DatePickerField: FC<DatePickerFieldProps> = ({
  field,
  form,
  label,
  disabled,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={field.value}
        onChange={(value) => {
          form.setFieldValue(field.name, value);
        }}
        format="MMM d, YYYY"
        slotProps={{
          inputAdornment: {
            position: "start",
          },
        }}
        sx={{
          width: "100%",
          "& .MuiInputBase-root input": {
            padding: "8.5px 14px 8.5px 0",
          },
          "& .MuiInputAdornment-root": {
            marginRight: 0,
          },
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
