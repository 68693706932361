import '../checkStatus/checkStatus.css'
const checkStatus = ({ status , setChecked}) => {

      return (
            <div style={{
                  display: 'flex', alignItems: 'center', paddingLeft: '8pt', cursor: 'default',
                  height : "40px",
                  width : "100px",
                  border: "1px solid gray", alignSelf: 'center', borderRadius: '50px',
                  marginLeft: '0px', boxShadow: '0px 0px 10px rgb(220,220,220)'
 
            }}

            onClick={() => setChecked(!status)}


            >
                  <div
                        style={{ color: status ? "gray" : "red" }}
                  >{status ? "Active" : "Inactive"}</div>
                  {status === true ? (
                        <div className='icon-container' >
                              <div className='status-circle-true'>
                              </div>
                        </div>
                  ) : (
                        <div className='icon-container'>
                              <div className='status-circle-false'>
                              </div>
                        </div>
                  )}
            </div>
      );
}

export default checkStatus;