import Services from "../../ServicesRoutes.js";
import { http } from "src/axios";
import { useCallback, useEffect, useRef } from "react";

const useUsers = () => {
  const abortUpdateUser = useRef<AbortController | null>(null);
  const abortGetUsers = useRef(new Map<string, AbortController>());

  const postUser = useCallback(async (user: Record<string, any>) => {
    if (abortUpdateUser.current) abortUpdateUser.current.abort();
    abortUpdateUser.current = new AbortController();

    return http.post("/api/v2/user/login", user, {
      signal: abortUpdateUser.current.signal
    }).finally(() => {
      abortUpdateUser.current = null;
    });
  }, []);

  const getUsers = useCallback(async (query = undefined, page = undefined, per_page = undefined, permissions = undefined) => {
    const params: Record<string, any> = {};
    if (query) params.query = query;
    if (page) params.page = page;
    if (per_page) params.per_page = per_page;
    if (permissions) params.permissions = permissions;

    const key = JSON.stringify(params);
    const existingAbortController = abortGetUsers.current.get(key);
    if (existingAbortController) existingAbortController.abort();

    const newAbortController = new AbortController();
    abortGetUsers.current.set(key, newAbortController);

    return http.get(Services.base + Services.v2 + Services.users, {
      signal: newAbortController.signal,
      params: params
    }).finally(() => {
      abortGetUsers.current.delete(key);
    });
  }, []);

  // Unmount
  useEffect(() => {
    return () => {
      if (abortUpdateUser.current) abortUpdateUser.current.abort();

      if (abortGetUsers.current.size) {
        for (const abortController of abortGetUsers.current.values()) {
          abortController.abort();
        }
      }
    };
  }, []);

  return { postUser, getUsers };
};

export default useUsers;
