export const attempt = <R, F>(fn: () => R, fallback: F, errorMessage?: string): R | F => {
  try {
    return fn();
  } catch (error) {
    if (errorMessage) {
      console.error(errorMessage, error);
    }
    return fallback;
  }
};
