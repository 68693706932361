import { useEffect, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import TotalServices from "../../../../TotalServices";
import { toast } from "react-hot-toast";
import Select from "react-select";
import AuditServices from "../../../../Services/AuditsServices";

const CreateDispute = ({ getData , options }) => {
  // Create Query ----- Upload Files
  const [file, setFile] = useState();
  const [loader, setLoader] = useState(false);
  const [sendStore, setSenStore] = useState(false);
  const [store, setStore] = useState("");

 

  const CreateDisputeQuery = async () => {
    if (file && store) {
      if (
        file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        try {
          setLoader(true);
          let formdata = new FormData();
          formdata.append("file", file);
          formdata.append(
            "store",
            store?.value === undefined ? "" : store?.value
          );

          let response = await TotalServices.CreateDisputesVendors(formdata);
          if (response.status === 200) {
              toast.success(response.data.message);
              setLoader(false);
              setStore("");
              setFile("");

              document.getElementById("fileMDT").value = "";
              getData();
          }
        } catch (e) {
          setLoader(false);
        }
      } else {
        toast.error("Please Select a CSV or Excel File");
      }
    } else {
      toast.error("Please Fill all the fields");
    }
  };


  return (
    <div className="container-sm text-center">
      <div className="ms-2 me-2 mt-2 mb-1 d-flex justify-content-center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            CreateDisputeQuery();
          }}
        >
          <label for="formFile" className="form-label fw-bold">
            Select Store
          </label>
          <div className="ms-2 me-2 mt-2 mb-1 d-flex justify-content-center">
            <Select
              className="basic-single w-100"
              classNamePrefix="select"
              placeholder="Select Store"
              name="color"
              options={options || []}
              onChange={setStore}
              value={store}
            />
          </div>

          <label for="formFile" className="form-label fw-bold">
            File Upload
          </label>

          <input
            id="fileMDT"
            className="form-control upload_file"
            type="file"
            name="file"
            disabled={true}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />

          <div className="col">
            <button
              className="btn btn-outline-danger  mt-2 mb-3"
              type="submit"
              disabled={true}
            >
              <span className="me-1">
                {loader ? <ClipLoader size={16} /> : <FaCloudUploadAlt />}
              </span>
              Start Disputing
            </button>
          </div>
          <br></br>
          <br></br>
        </form>
      </div>
    </div>
  );
};

export default CreateDispute;
