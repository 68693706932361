import usePermissions from "components/CustomHooks/usePermissions";
import DeleteModal from "components/Modals/Delete";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { deleteScopes, updateAccountsScopes } from "../../../Api/Scopes/scopes";
import getHistoricValue from "../utils/getHistoricValue";
import { Scope } from "../types";
import SelectCell from "./SelectCell";
import InputCell from "./InputCell";
import TextareaCell from "./TextareaCell";

interface ScopeRowProps {
  data: Scope;
  setChangesInScopes: (value: boolean) => void;
  getAccountsScopesAll: any;
}

const ScopeRow: FC<ScopeRowProps> = ({
  data,
  setChangesInScopes,
  getAccountsScopesAll,
}) => {
  const { hasPermission } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [isEditableRow, setIsEditableRow] = useState(false);

  const toggleEdit = () => setIsEditableRow((prev) => !prev);

  const [showDeleteScopeConfirmation, setShowDeleteScopeConfirmation] =
    useState(false);

  const [selectedToDelete, setSelectedToDelete] = useState<{
    accountID: number | null;
    scopesID: number | null;
  }>({ accountID: null, scopesID: null });

  const inititeDelition = (rowData: Scope) => {
    setSelectedToDelete({
      accountID: rowData.ACCOUNT_ID,
      scopesID: rowData.ID,
    });

    setShowDeleteScopeConfirmation(true);
  };

  const deleteScope = async () => {
    if (!selectedToDelete.accountID || !selectedToDelete.scopesID) {
      toast.error("Account ID or Scope ID is missing");
      return;
    }

    try {
      setLoading(true);

      await deleteScopes({
        AccountsID: selectedToDelete.accountID,
        scopesID: selectedToDelete.scopesID,
      });

      toast.success("Scope deleted successfully");
      getAccountsScopesAll();
    } catch (error) {
      console.error("Error deleting scope", error);
      toast.error("Error deleting scope");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      NAME: data.NAME || "",
      ACTIVATION_DATE: data.ACTIVATION_DATE || "",
      DEACTIVATION_DATE: data.DEACTIVATION_DATE || "",
      INVOICE_START_DATE: data.INVOICE_START_DATE || "",
      INVOICE_END_DATE: data.INVOICE_END_DATE || "",
      IS_HISTORIC: data.IS_HISTORIC?.toString() || "",
      NOTES: data.NOTES !== null ? data.NOTES : "",
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const inputData = { ...values };

        if (!inputData.ACTIVATION_DATE || !inputData.INVOICE_START_DATE) {
          toast.error("Activation date and Invoice Start date can't be empty");
          setLoading(false);
          return;
        }

        if (inputData.IS_HISTORIC !== undefined) {
          const isHistoric = getHistoricValue(inputData.IS_HISTORIC);
          if (isHistoric === data.IS_HISTORIC) {
            delete (inputData as { IS_HISTORIC?: any }).IS_HISTORIC;
          } else {
            (inputData as { IS_HISTORIC?: any }).IS_HISTORIC = isHistoric;
          }
        }

        const updatedData: { [key: string]: any } = {};

        const mapFieldNameToApiKeys: {
          [key in keyof Omit<Scope, "ID" | "ACCOUNT_ID" | "CREATED">]: string;
        } = {
          NAME: "name",
          ACTIVATION_DATE: "activation_date",
          DEACTIVATION_DATE: "deactivation_date",
          IS_HISTORIC: "is_historic",
          INVOICE_START_DATE: "invoice_start_date",
          INVOICE_END_DATE: "invoice_end_date",
          NOTES: "notes",
        };

        for (const key in inputData) {
          type ScopeKey = keyof Omit<Scope, "ID" | "ACCOUNT_ID" | "CREATED">;

          if (
            inputData.hasOwnProperty(key) &&
            inputData[key as ScopeKey] !== data[key as keyof Scope]
          ) {
            const field = key as ScopeKey;
            const apiKey = mapFieldNameToApiKeys[field];
            if (apiKey) {
              updatedData[apiKey] = inputData[field];
            }
          }
        }

        console.log("updatedData", updatedData);
        console.log("orginal data", data);

        await updateAccountsScopes({
          accountID: data.ACCOUNT_ID,
          scopeID: data.ID,
          inputData: updatedData,
        });

        toast.success("Scope updated successfully");
        toggleEdit();
        formik.resetForm();
        getAccountsScopesAll();
        setChangesInScopes(true);
      } catch (error) {
        console.error("Error updating scope", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleChange = (field: keyof Scope, value: any) => {
    formik.setFieldValue(field, value !== "" ? value : null);
    setChangesInScopes(true);
  };

  return (
    <tr>
      <SelectCell
        dataValue={data.NAME}
        isEditableRow={isEditableRow}
        formik={formik}
        handleChange={handleChange}
        name="NAME"
      />

      <InputCell
        dataValue={data.ACTIVATION_DATE}
        isEditableRow={isEditableRow}
        formik={formik}
        handleChange={handleChange}
        name="ACTIVATION_DATE"
      />

      <InputCell
        dataValue={data.DEACTIVATION_DATE}
        isEditableRow={isEditableRow}
        formik={formik}
        handleChange={handleChange}
        name="DEACTIVATION_DATE"
      />

      <SelectCell
        dataValue={data.IS_HISTORIC}
        isEditableRow={isEditableRow}
        formik={formik}
        handleChange={handleChange}
        name="IS_HISTORIC"
      />

      <InputCell
        dataValue={data.INVOICE_START_DATE}
        isEditableRow={isEditableRow}
        formik={formik}
        handleChange={handleChange}
        name="INVOICE_START_DATE"
      />

      <InputCell
        dataValue={data.INVOICE_END_DATE}
        isEditableRow={isEditableRow}
        formik={formik}
        handleChange={handleChange}
        name="INVOICE_END_DATE"
      />

      <TextareaCell
        dataValue={data.NOTES}
        isEditableRow={isEditableRow}
        formik={formik}
        handleChange={handleChange}
        name="NOTES"
      />

      <td>
        {hasPermission(["admin:*", "user:client_config:write:*"]) && (
          <ButtonGroup>
            <Button
              variant="warning"
              style={{ marginRight: "10px", borderRadius: "5px" }}
              onClick={toggleEdit}
              disabled={loading}
            >
              {isEditableRow ? "Cancel" : "Edit Scope"}
            </Button>

            {isEditableRow ? (
              <Button
                variant="success"
                style={{ borderRadius: "5px" }}
                onClick={() => formik.handleSubmit()}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update Scope"}
              </Button>
            ) : (
              <Button
                variant="danger"
                style={{ borderRadius: "5px" }}
                onClick={() => inititeDelition(data)}
                disabled={loading}
              >
                {loading ? "Deleting..." : "Delete"}
              </Button>
            )}
          </ButtonGroup>
        )}

        {showDeleteScopeConfirmation && (
          <DeleteModal
            Delete={deleteScope}
            loader={loading}
            showDeleteModal={showDeleteScopeConfirmation}
            setShowDeleteModal={setShowDeleteScopeConfirmation}
          />
        )}
      </td>
    </tr>
  );
};

export default ScopeRow;
