import { MenuOpen } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { type FC, useEffect, useState } from "react";
import { ProSidebar, SidebarHeader } from "react-pro-sidebar";
import SideBarContent from "./SidebarContent/SideBarContent";

import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";

import LogoIcon from "../../../assets/cg-logo-min.svg";
import Logo from "../../../assets/cg-logo.svg";

interface SidebarProps {
  sidebarToggled: boolean;
}

const Sidebar: FC<SidebarProps> = ({ sidebarToggled }) => {
  const [menuCollapse, setMenuCollapse] = useState<boolean>(() => {
    const savedMenuCollapse = localStorage.getItem("menuCollapse");
    return savedMenuCollapse !== null ? savedMenuCollapse === "true" : false;
  });

  useEffect(() => {
    localStorage.setItem("menuCollapse", String(menuCollapse));
  }, [menuCollapse]);

  return (
    <div id="mainHeader">
      <ToggleButton
        menuCollapse={menuCollapse}
        setMenuCollapse={setMenuCollapse}
      />

      <div id="header">
        <ProSidebar
          collapsed={menuCollapse}
          width={232}
          collapsedWidth={64}
          breakPoint="md"
          toggled={sidebarToggled}
        >
          <SidebarHeader>
            <Box
              padding="20px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={`sidebar-logo-container ${menuCollapse ? "collapsed" : ""}`}
                position="relative"
              >
                <img
                  src={Logo}
                  alt="Logo"
                  id="logo"
                  className="sidebar-logo"
                  height="28px"
                  style={{ display: "block" }}
                />
                <img
                  src={LogoIcon}
                  alt="Logo"
                  id="logo-icon"
                  className="sidebar-logo-icon"
                  style={{ display: "block" }}
                />
              </Box>
            </Box>
          </SidebarHeader>
          <SideBarContent menuCollapsed={menuCollapse} />
        </ProSidebar>
      </div>
    </div>
  );
};

const ToggleButton = ({
  menuCollapse,
  setMenuCollapse,
}: {
  menuCollapse: boolean;
  setMenuCollapse: (value: boolean) => void;
}) => {
  return (
    <IconButton
      onClick={() => {
        setMenuCollapse(!menuCollapse);
      }}
      className={`toggle-sidebar-btn ${menuCollapse ? "collapsed" : ""}`}
      sx={{
        right: menuCollapse ? "-20px" : "0",
      }}
    >
      <MenuOpen />
    </IconButton>
  );
};

export default Sidebar;
