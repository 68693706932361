import "../Login/login.css"
import React, { useState, useEffect} from "react";
import EmailLogo from "../../../assets/email.png"
import EmailExpired from "../../../assets/email-not-verified.png"
import MainLogo from "../../../assets/logo.png"
import { useParams } from "react-router-dom";
import ApiUrl from "../../../config/config";
import BarLoader from "react-spinners/BarLoader"
import { useNavigate } from "react-router-dom";
import { Routes_Main } from "../../../MainRoutes";


const EmailVerification = () =>{
    useEffect(() => {
        document.title = 'Charge Guard | Email Verification'
      }, [])
    let navigate = useNavigate();
    let { token, username } = useParams();

    const [Loader, setLoader] = useState(true);
    const [message, setMessage] = useState("")
    const [expired, setExpired] = useState(true)

    const CheckVerification = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
    
        fetch(
          ApiUrl + "verify-email-user/" + token + "/" + username,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 200){
                 setLoader(false);
                 setMessage(result.message)
            }
            else if (result.status === 405) {
              setMessage(result.message)
              setExpired(false);
              setLoader(false);
            }
          })
    
          .catch((error) => setLoader(false))
      };

      useEffect(() => {
        CheckVerification();
      }, []);
    return(
        <div className="login-container">
            {
               Loader ?
               <div className="card">
                <div className="text-left">
                    <div className="flexflex mb-2">
                        <img src={MainLogo} width="250px"/>
                    </div>
                    <div className="flexflex p-4">
                        <BarLoader color="#EF1442"/>
                    </div>
                    <p className="below-text text-center mb-0"><b>Checking Verification!</b></p>
                    <p className="text-center text-secondary mb-0">Please wait, while we are checking your Verification</p>
                    
            </div>
         </div>:
            <>
                {
                    expired ?
                        <div className="card">
                        <div className="text-left">
                            <div className="flexflex mb-2">
                                <img src={MainLogo} width="250px"/>
                            </div>
                            <div className="flexflex">
                                <img src={EmailLogo} height="70px"/>
                            </div>
                            <p className="below-text text-center mb-0"><b>{message.replaceAll("!","")}</b></p>
                            <p className="text-center text-secondary mb-0">Your email, is verified now, you can go to home page login page.</p>
                            
                    </div>
                    <div className="mt-2 proceed"> 
                        <button className="btn-primary btn-full mt-3">
                            Go to home
                        </button> 
                    </div>
                    <div className="mt-2">
                        <button className="btn-secondary btn-full" onClick={()=> navigate("/"+Routes_Main.userLogin)}>Login</button>
                    </div>
                </div>:
                    <div className="card">
                    <div className="text-left">
                        <div className="flexflex mb-2">
                            <img src={MainLogo} width="250px"/>
                        </div>
                        <div className="flexflex">
                            <img src={EmailExpired} height="70px"/>
                        </div>
                        <p className="below-text text-center mb-0"><b>{message.replaceAll("!","")}</b></p>
                        <p className="text-center text-secondary mb-0">Your token is expired or invalid, Please register your email.</p>
                        
                </div>
                <div className="mt-2 proceed"> 
                    <button className="btn-primary btn-full mt-3">
                        Go to home
                    </button> 
                </div>
                <div className="mt-2">
                    <button className="btn-secondary btn-full"  onClick={()=> navigate("/"+Routes_Main.userLogin)}>Register</button>
                </div>
            </div>
                }
            </>
            }
          
        </div>
    )
}
export default EmailVerification;