import type { QueryFunction } from "@tanstack/react-query";
import { http } from "src/axios";

export interface AccountsApiResponse {
  pages: number;
  stores: AccountStore[];
  success: boolean;
  total_records: number;
}

export interface AccountStore {
  "Account Config Vendor Name": string | null;
  "Account ID": number;
  "Account Name": string;
}

export const getAccounts: QueryFunction<AccountsApiResponse, ["accounts"]> = async ({ signal }) => {
  const response = await http.get<AccountsApiResponse>("/api/v1/accounts", { signal });
  return response.data;
};
