import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useState } from "react";
import TotalServices from "../../../../TotalServices";
import Validations from "../../../../Regex";


const ModalComp = ({ showModal, handleCloseModal, store , getQuery }) => {

  //add

  const [issueType, setIssueType] = useState("");
  const [subType, setSubType] = useState(null);
  const [Notes, setNotes] = useState(null);
  const [loader, setLoader] = useState(true);

 
  const addDisputePhrases = async() => {
    if(Validations.isEmpty(issueType)    ){
        toast.error("Fields Can't be Empty")
    }
    else{
        try{
            setLoader(true)
              let response=await TotalServices.CreateConfiguration({
                vendor: store,
                region: store.slice(0, 2),
                issue_type: issueType,
                sub_type: subType,
                notes: Notes,

              })
              // console.log(response);
          if(response.status===200){
            setLoader(true);
            handleCloseModal();
            getQuery("");
            toast.success(response.data.message);
            setIssueType("");
            setSubType("");
            setNotes("");

             
          }
        }
        catch(e){
            setLoader(false)
        }
          }
        
      
                 
     
     
    };
 


  return (

    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >

        <Modal.Header closeButton>
          <Modal.Title className="fw-bold text-dark">
            Update Configuration
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="p-3">
            <div className=" mb-3">
              <label
                htmlFor="name"
                className="form-label ms-0 ps-0 fw-bold text-dark"
              >
                Store: {store}
              </label>
            </div>
            <div className=" mb-3">
            <label
                htmlFor="name"
                className="form-label ms-0 ps-0 fw-bold text-dark"
              >
                Region: {store.slice(0,2)}
              </label>
            </div>
            <div className=" mb-3">
              <label
                htmlFor="name"
                className="form-label ms-0 ps-0 fw-bold text-dark"
              >
                Issue Type:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                required
                value={issueType}
                onChange={(e) => {
                  setIssueType(e.target.value);
                }}
                placeholder="Enter Issue Type..."
              />
            </div>
            <div className=" mb-3">
                <label
                  htmlFor="name"
                  className="form-label ms-0 ps-0 fw-bold text-dark"
                >
                  Sub Type:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={subType}
                  onChange={(e) => {
                    setSubType(e.target.value);
                  }}
                  placeholder="Enter Sub Type..."
                />
              </div>
              <div className=" mb-3">
                <label
                  htmlFor="name"
                  className="form-label ms-0 ps-0 fw-bold text-dark"
                >
                  Notes:
                </label>
                <textarea
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={Notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  placeholder="Enter Notes..."
                />
              </div>
           
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button
              style={{
                color: "white",
                backgroundColor: "#212529",
                border: "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                addDisputePhrases();
              }}
            >
              Update Configuration
            </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#f54242",
              border: "none",
            }}
            onClick={() => handleCloseModal()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
}

export default ModalComp;