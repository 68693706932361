import { ArrowForwardRounded } from "@mui/icons-material";
import { Avatar, Box, Button } from "@mui/material";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import { type ChargebackWorkPacket, type ShortageWorkPacket, type WorkPacket, type WorkPacketOwner } from "src/types/work-packets";
import { getWorkPacketActionColorByName } from "src/utils/work-packets/getWorkPacketActionColorByName";
import { WorkPacketType } from "../../WorkPacketType";
import EditableAutocompleteCell from "../EditableAutocompleteCell";

type WorkPacketsColumn = GridColDef<WorkPacket> & {
  field: keyof ChargebackWorkPacket | keyof ShortageWorkPacket | "details" | "disputeByDate";
};

interface GridColumnProps {
  ownerOptions: WorkPacketOwner[];
  currentActionOptions: any[];
  onDetailsClick: (id: string) => void;
  allowEditPacketAction?: boolean;
  workPacketType: WorkPacketType;
}

function pickColumns<ColumnArr extends WorkPacketsColumn[], Field extends ColumnArr[number]["field"]>(
  columns: ColumnArr,
  fields: Field[],
) {
  return fields.reduce(
    (acc, field) => [...acc, columns.find(column => column.field === field)!],
    [] as WorkPacketsColumn[],
  );
}

export const useWorkPacketsGridColumns = ({
  workPacketType,
  ownerOptions,
  currentActionOptions,
  onDetailsClick,
  allowEditPacketAction,
}: GridColumnProps): WorkPacketsColumn[] => {
  const columns = useMemo((): WorkPacketsColumn[] => {
    const allColumns = [
      {
        field: "packetId",
        headerName: "Packet ID",
        width: 150,
        filterable: false,
      },
      {
        field: "vendorName",
        headerName: "Vendor Name",
        width: 200,
        filterable: false,
      },
      {
        field: "storeName",
        headerName: "Store Name",
        width: 200,
        filterable: false,
      },
      {
        field: "currentPacketOwner",
        headerName: "Current Packet Owner",
        width: 300,
        filterable: false,
        editable: true,
        renderCell: params => {
          try {
            const owner = params.value as WorkPacketOwner;
            return (
              <Box display="flex" alignItems="center">
                <Avatar src={owner.avatar} sx={{ width: 32, height: 32 }} />
                <Box ml={1}>{owner.title}</Box>
              </Box>
            );
          } catch (error) {
            console.error("Error rendering currentPacketOwner cell:", error);
            return (
              <Box display="flex" alignItems="center">
                <Box ml={1}>Unknown</Box>
              </Box>
            );
          }
        },
        renderEditCell: params => <EditableAutocompleteCell {...params} options={ownerOptions} valueType="object" />,
      },
      {
        field: "currentAction",
        headerName: "Current Action",
        width: 300,
        type: "string",
        filterable: false,
        editable: allowEditPacketAction,
        renderCell: (params: GridRenderCellParams) => {
          try {
            const color = params.value.color ?? getWorkPacketActionColorByName(params.value.value);
            return (
              <Box display="flex" alignItems="center">
                {!!color && <Box width="12px" minWidth="12px" height="12px" bgcolor={color} borderRadius="2px" />}
                <Box ml={1}>{params.value.title}</Box>
              </Box>
            );
          } catch (error) {
            console.error("Error in getWorkPacketsGridColumns", error, "params:", params);
            return (
              <Box display="flex" alignItems="center">
                <Box ml={1}>Unknown</Box>
              </Box>
            );
          }
        },
        renderEditCell: params => (
          <EditableAutocompleteCell
            {...params}
            options={currentActionOptions}
            valueType="object"
            groupBy={(option: any) => option.category}
          />
        ),
      },
      {
        field: "recoveryStream",
        headerName: "Recovery Stream",
        filterable: false,
        width: 200,
      },
      {
        field: "recoveryStreamSubtype1",
        headerName: "Recovery Stream Subtype 1",
        filterable: false,
        width: 300,
      },
      {
        field: "recoveryStreamSubtype2",
        headerName: "Recovery Stream Subtype 2",
        filterable: false,
        width: 300,
      },
      {
        field: "recoveryStreamServer",
        headerName: "Recovery Stream",
        filterable: false,
        width: 200,
      },
      {
        field: "packetStage",
        headerName: "Packet Stage",
        width: 150,
        filterable: false,
        editable: false,
      },
      {
        field: "vcPoId",
        headerName: "VC PO ID",
        width: 150,
        filterable: false,
      },
      {
        field: "asinId",
        headerName: "ASIN ID",
        width: 150,
        filterable: false,
      },
      {
        field: "chargebackIssueId",
        headerName: "Chargeback Issue ID",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "manualFilingUser",
        headerName: "Manual Filing user",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "techUser",
        headerName: "Tech user",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "financialCharge",
        headerName: "Financial Charge",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "chargebackCreateDate",
        headerName: "Chargeback Create Date",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "disputeId",
        headerName: "Dispute ID",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "disputeAmount",
        headerName: "Dispute Amount",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "disputeCreatedAt",
        headerName: "Dispute Created At",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "vcParentInvoiceId",
        headerName: "VC Parent Invoice ID",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "vcDisputedInvoiceId",
        headerName: "VC Disputed Invoice ID",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "invoiceDate",
        headerName: "Invoice Date",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "invoiceDueDate",
        headerName: "Invoice Due Date",
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        field: "disputeByDate",
        headerName: "Dispute By Date",
        filterable: false,
        sortable: false,
        width: 200,
        renderCell: params => params.value || "Unknown",
      },
      {
        field: "details",
        headerName: "",
        width: 120,
        align: "center",
        sortable: false,
        filterable: false,
        hideable: false,

        renderCell: params => (
          <Button
            variant="outlined"
            endIcon={<ArrowForwardRounded />}
            onClick={() => onDetailsClick(params.row.packetId)}
          >
            Details
          </Button>
        ),
        cellClassName: () => "bg-gray-100",
      },
    ] as const satisfies WorkPacketsColumn[];

    if (workPacketType === WorkPacketType.CHARGEBACKS) {
      return pickColumns(allColumns, [
        "packetId",
        "vendorName",
        "storeName",
        "currentPacketOwner",
        "currentAction",
        "recoveryStream",
        "recoveryStreamSubtype1",
        "recoveryStreamSubtype2",
        "packetStage",
        "vcPoId",
        "asinId",
        "chargebackIssueId",
        "manualFilingUser",
        "techUser",
        "financialCharge",
        "chargebackCreateDate",
        "disputeByDate",
        "details",
      ]);
    }
    if (workPacketType === WorkPacketType.SHORTAGES) {
      return pickColumns(allColumns, [
        "packetId",
        "vendorName",
        "storeName",
        "currentPacketOwner",
        "currentAction",
        "recoveryStreamServer",
        "packetStage",
        "manualFilingUser",
        "disputeId",
        "disputeAmount",
        "disputeCreatedAt",
        "vcParentInvoiceId",
        "vcDisputedInvoiceId",
        "invoiceDate",
        "invoiceDueDate",
        "disputeByDate",
        "details",
      ]);
    }

    console.error(`Unknown work packet type ${workPacketType}`);
    return allColumns;
  }, [allowEditPacketAction, currentActionOptions, onDetailsClick, ownerOptions, workPacketType]);

  return columns;
};
