import { useField } from "formik";
import { Label } from "./Label";

export const FormikInputField = ({ label, mandatory = false, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const handleNumberChange = (e) => {
    const value = e.target.value.trim();
    if (value === "") {
      helpers.setValue(null);
    } else {
      helpers.setValue(Number(value));
    }
  };

  const handleChange = (e) => {
    if (props.type === "number") {
      handleNumberChange(e);
    } else {
      helpers.setValue(e.target.value);
    }
  };

  const handleWheel = (e) => {
    if (e.target.type === "number") {
      e.target.blur();
    }
  };

  return (
    <>
      <Label label={label} mandatory={mandatory} />
      <input
        className={`w-full form-control outline-none p-2 border rounded-xl sm:text-md my-1 disabled:text-gray-500 ${
          props.classes ? props.classes : ""
        } ${meta.touched && meta.error ? "is-invalid" : ""} `}
        {...field}
        {...props}
        onChange={handleChange}
        onWheel={handleWheel}
      />
      {meta.touched && meta.error && (
        <div className="text-red">{meta.error}</div>
      )}
    </>
  );
};
