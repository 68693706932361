import Services from "../ServicesRoutes";
import { http } from "../axios";

const listCustomers = () => {
  return http.get(Services.base + Services.version + Services.CustomersNew);
};

const CustomerNewServices = {
  listCustomers,
};

export default CustomerNewServices;
