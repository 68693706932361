import toast from "react-hot-toast";

const isName = (name) => {
  let regex = /^[a-zA-Z ]{2,30}$/;

  return regex.test(name);
};
const isUserName = (username) => {
  let regex = /^[a-z0-9_-]{3,15}$/;
  let regexTwo = /[A-Za-z]/i;

  return regex.test(username);
};
const isEmpty = (string) => {
  let regex = /^\s+$/;

  return regex.test(string) || string === "";
};
const isEmail = (email) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const regex2 = /^[0-9]/;

  return regex.test(email) && !regex2.test(email);
};
const isNumber = (number) => {
  const regex = /^[0-9]*$/;

  return regex.test(number);
};

const isDecimal = (value) => {
  const decimalRegex = /^[0-9]+(\.[0-9]{0,2})?$/;
  return decimalRegex.test(value);
};

const isPercentage = (value) => {
  // Regular expression to allow numbers between 0 and 100 with up to two decimal places
  const percentageRegex = /^(100(\.0{0,2})?|[1-9]?\d(\.\d{0,2})?)$/;
  return percentageRegex.test(value);
};


const checkMandatoryFields = (fields) => {
    return fields.filter((field) => field.value === null || field.value === "" || field.value === undefined);
  }


const  validateAndToastEmail = (email, errorMessage) => {
    if (email && !Validations.isEmail(email)) {
      toast.error(errorMessage);
      return false; // Indicate that validation failed
    }
    return true; // Indicate that validation passed
  }

function validatePassword(password) {
  // At least one lowercase letter, one uppercase letter, one digit, and one special character
  var pattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/;

  return pattern.test(password);
}
const Validations = {
  isName,
  isUserName,
  isEmpty,
  isEmail,
  validatePassword,
  isNumber,
  isDecimal,
  isPercentage,
  checkMandatoryFields,
  validateAndToastEmail
};
export default Validations;
