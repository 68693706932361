import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useMemo, type FC } from "react";

import { WorkPacketFilter, WorkPacketFilters } from "src/types/work-packets";
import { initialFilters, useWorkPacketsContext } from "../../WorkPacketsContext";
import { WorkPacketType } from "../../WorkPacketType";
import { WorkPacketView } from "../../WorkPacketView";

import { ChargebackFiltersForm } from "./ChargebackFiltersForm";
import { ShortageFiltersForm } from "./ShortageFiltersForm";

const areFiltersEqual = (
  currentFilters: Partial<WorkPacketFilters>,
  nextFilters: Partial<WorkPacketFilters>,
): boolean => {
  return Object.keys(nextFilters).every(
    key => currentFilters[key as keyof WorkPacketFilters] === nextFilters[key as keyof WorkPacketFilters],
  );
};

export interface FiltersFormProps {
  loading: boolean;
  view: WorkPacketView;
  dynamicFilterOptions: Record<string, any[]>;
  userNickname: string;
  filters: WorkPacketFilters;
  clearFilters: () => void;
}

export type FiltersForm = FC<{ props: FiltersFormProps; formApi: FormikProps<WorkPacketFilters> }>;

const workPacketTypeToFiltersFormMap: Record<WorkPacketType, FiltersForm> = {
  [WorkPacketType.CHARGEBACKS]: ChargebackFiltersForm,
  [WorkPacketType.SHORTAGES]: ShortageFiltersForm,
};

const createFormikForm: (
  props: FiltersFormProps,
  workPacketType: WorkPacketType,
) => FC<FormikProps<WorkPacketFilters>> = (props, workPacketType) => formApi => {
  const { filters, clearFilters, loading } = props;
  const { resetForm, dirty, values } = formApi;

  const hasNonEmptyValues = !areFiltersEqual(filters, initialFilters);
  const FormComponent = workPacketTypeToFiltersFormMap[workPacketType];

  return (
    <Form>
      <Box display="flex" flexDirection="column" mb={4}>
        <FormComponent props={props} formApi={formApi} />

        <Box mt={2} display="flex" justifyContent="flex-end" gap="12px">
          {dirty && (
            <Button
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
              onClick={() => {
                resetForm();
              }}
            >
              Cancel
            </Button>
          )}
          {hasNonEmptyValues && (
            <Button
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
              onClick={() => {
                resetForm();
                clearFilters();
              }}
            >
              Clear
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            disabled={areFiltersEqual(filters, values) || loading}
            sx={{ paddingX: "24px" }}
          >
            <span>Apply</span>
            {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

interface FiltersGridProps {
  loading: boolean;
  dynamicFilterOptions: Record<string, any[]>;
  view: WorkPacketView;
}

export const WorkPacketsGridFilters: FC<FiltersGridProps> = ({ dynamicFilterOptions, loading, view }) => {
  const { user } = useAuth0();
  const userNickname =
    user &&
    (dynamicFilterOptions[WorkPacketFilter.CurrentPacketOwner].find(x => x.value === user.sub)?.title ??
      "Unknown user");

  const { currentFilters: filters, setFilters, clearFilters, currentWorkPacketType } = useWorkPacketsContext();

  const handleSubmit = (values: WorkPacketFilters) => {
    setFilters(values);
  };

  const FormikChild = useMemo(
    () =>
      createFormikForm(
        { clearFilters, dynamicFilterOptions, filters, loading, userNickname, view },
        currentWorkPacketType,
      ),
    [clearFilters, dynamicFilterOptions, filters, loading, userNickname, view, currentWorkPacketType],
  );

  return (
    <Formik
      /* A key to force reinitialization when view changes */
      key={view}
      initialValues={filters}
      onSubmit={handleSubmit}
      enableReinitialize
      children={FormikChild}
    />
  );
};
