import Moment from "moment";
import useDisputeToolDownload from "../../../../components/CustomHooks/useDisputeToolDownload";
import { useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import StatusButton from "../../../../components/StatusButton/StatusButton";

const DisputeTable = ({data}) => {
  const [fileLoader,setFileLoader]=useState(false)
  const {DownlaodFile} =useDisputeToolDownload()
const [id,setId]=useState("")
    return ( 
      <div className="table-container">  
      <table className="table">
    <thead className="thead-dark">
      <tr>
        {/* <th scope="col">Serial No.</th> */}
        <th scope="col">Type</th>
        <th scope="col">Store</th>
        <th scope="col">Upload Date</th>
        <th scope="col">Status</th>
        <th scope="col">Total</th>
        <th scope="col">Failed</th>
        <th scope="col">Completed</th>
        <th scope="col">Download file</th>
      </tr>
    </thead>
    <tbody>
      { data.map((data, index) => (
            <tr key={index}>
              <td>
                {data[2] === "Dispute denied" ||
                data[2] === "Need more information" ? (
                  <button className="btn btn-danger">
                    Re Dispute
                  </button>
                ) : (
                  <button className="btn btn-warning">
                    Charged
                  </button>
                )}
              </td>
              <td>{data[1]}</td>

              <td>{Moment(data[3]).format("YYYY-MM-DD")}</td>
              <td>
              <StatusButton status={data[4]} />
              </td>
              <td className="text">{data[5]}</td>
              <td className="text-danger">{data[6]}</td>
              <td
                className="text-success"
                style={{ fontWeight: "bold" }}
              >
                {data[7]}
              </td>

              <td>
            {fileLoader && data[0] == id ? 

<>
  <div className="btn-group">
    <button type="button" className="btn btn-outline-success rounded-end">Downloading</button>
  </div>
</>

 : 

<>
              {data[4].toString().toLowerCase() ===
              "done".toLowerCase() ? (
                
                <button
                  className="btn btn-success"
                  onClick={(e, id = data[0]) => { setId(id); DownlaodFile("File", id, setFileLoader,data[1],data[3]) }}
                >
                  <FaCloudDownloadAlt />{" "}
                  Downoad
                </button>
              ) : (
                <button
                  className="btn btn-outline-danger"
                  disabled
              
                >
                  {" "}
                  Please wait...
                </button>
              )}
              </>
}
            </td>
            </tr>
          ))}
    </tbody>
  </table>
  </div> );
}
 
export default DisputeTable;