export enum WorkPacketView {
  /* Only Admins can see this tab */
  AllPackets,

  /* Unassigned work packets */
  NewPackets,

  /* Work packets assigned to the current user */
  MyPackets,
}
