import { useState } from "react";
import { Button } from "react-bootstrap";
import { BsFillInfoCircleFill } from "react-icons/bs";
import OffCanvasComp from "./OffCanvas";
import { toast } from "react-hot-toast";
import TotalServices from "../../../../TotalServices";

const ConfigurationTable = ({data,setLoader,GetData}) => {
    const [show, setShow] = useState(false);
    const [store, setStore] = useState("");
    const [storeStatus, setStoreStatus] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (value, status) => {
        setStore(value);
        setStoreStatus(status);
        setShow(true);
      };
     
    
      const editConfiguration =async (store, status) => {
      
        try {
            setLoader(true)
            let response = await TotalServices.EditConfigurationData(store,status?1:0,null
           ,"All" );
            if(response.status===200){
               toast.success(response.data.message)
               setLoader(false)
               GetData()
            }
            }
          catch(error ){
            toast.error("There is error please try again");
            setLoader(false)
         
          }
          
    
      };
    return ( 
      <div className="table-container">  
        <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Region</th>
            <th scope="col">Store</th>

            <th scope="col">Status</th>

            <th scope="col">
              Details

            </th>
          </tr>
        </thead>
        <tbody>
          {data && data.map((obj) => {
            return (
              <>
                <tr>
                  <td scope="col">{obj[0]}</td>
                  <td scope="col">{obj[1]}</td>
                  <td

                   


                    scope="col">


                    <div className="form-check form-switch custom_toggle">
                      <input className="form-check-input custom_toggle_style" type="checkbox" role="switch"
                        defaultChecked={obj[2]}
                        onChange={() => {
                            editConfiguration(obj[1], !obj[2]);
                        }}
                      />
                    </div>

                  </td>
                  <td scope="col" >
                    <Button variant="primary" onClick={() => handleShow(obj[1], obj[2])}>
                      <BsFillInfoCircleFill />
                    </Button>
                  </td>
                </tr>

              </>
            )

          })}
        </tbody>
      </table>
      {show && (
          <OffCanvasComp
            show={show}
            setShow={setShow}
            storeStatus={storeStatus}
            store={store}
            handleClose={handleClose}
          />
        )}
      </div>
     );
}
 
export default ConfigurationTable;