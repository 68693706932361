const dialog = {
  MuiDialog: {
    styleOverrides: {
      paper: {},
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: "18px 24px",
        fontSize: "18px",
        borderBottom: "1px solid #f0f0f0",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {},
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "16px 24px",
        backgroundColor: "#000",
        gap: "10px",
      },
    },
  },
};

export default dialog;
