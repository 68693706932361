import { Box, Typography, Divider, Skeleton } from "@mui/material";
import { FC } from "react";

interface RecoverableSummaryProps {
  total: number;
  pending?: number;
  approved?: number;

  loading?: boolean;
}

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);

const SubData = ({ title, value, loading }: { title: string; value: number; loading?: boolean; }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems={{ xs: "flex-start", md: "flex-start" }}
    flexDirection={{ xs: "column", md: "row" }}
  >
    <Typography color="secondary.light">{title}</Typography>

    {loading ? (
      <Skeleton variant="text" sx={{ fontSize: "24px", flex: 0.5 }} />
    ) : (
      <Typography variant="h3">{formatCurrency(value)}</Typography>
    )}
  </Box>
);

export const RecoverableSummaryCard: FC<RecoverableSummaryProps> = ({
  total,
  pending,
  approved,
  loading,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
      paddingX={3}
      paddingY={3}
      borderRadius={2}
      border="1px solid #D0D5DD"
      sx={{ width: { xs: "100%", md: "55%" } }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", md: "flex-start" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Typography>Total Recoverable Amount</Typography>

        {loading ? (
          <Skeleton variant="text" sx={{ fontSize: "30px", flex: 0.5 }} />
        ) : (
          <Typography variant="h2">{formatCurrency(total)}</Typography>
        )}
      </Box>
      {approved !== undefined && pending !== undefined && (
        <Box mt={2}>
          <Divider />

          <Box display="flex" flexDirection="column" gap={2} pt={2}>
            <SubData loading={loading} title="Pending Recovery Amount" value={pending} />
            <SubData loading={loading} title="Approved Recovery Amount" value={approved} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
