type Status = "active" | "inactive" | "retired";

export const getColorByCustomerStatus = (status?: Status): string => {
  const colorMap = {
    active: "#39D4A5",
    inactive: "#E03741",
    retired: "#EAECF0",
  };

  return status ? colorMap[status] : "transparent";
};
