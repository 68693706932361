const handlePreviousRequests = async (abortController, setAbortController) => {
    if (abortController) {
        abortController.abort()
    }

    abortController = new AbortController()
    if (setAbortController) {
        await setAbortController(abortController)
    }
    return abortController
}


const AxiosServices = {
    handlePreviousRequests
};

export default AxiosServices;
