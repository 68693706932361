import { useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import { toast } from "react-hot-toast";
import TotalServices from "../../../TotalServices";

const CreateShortageDispute = ({ getData, options }) => {
  const [file, setFile] = useState("");
  const [store, setStore] = useState("");
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(false);

  const CreateQuery = async () => {
    if (store && type && file) {
      // console.log(type);
      if (
        file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        try {
          setLoader(true);
          let formdata = new FormData();
          formdata.append("file", file);
          formdata.append("store", store.value);
          formdata.append("type", type.value);

          let response = await TotalServices.CreateShotageDispute(formdata);
          // console.log(response);
          if (response.status === 200) {
            toast.success(response.data.message);
            setLoader(false);
            setStore("");
            setType("");
            setFile();
            document.getElementById("fileMDT").value = "";
            getData();
          }
        } catch (e) {
          setLoader(false);
        }
      } else {
        toast.error("Please Select a CSV or Excel File");
      }
    } else {
      toast.error("Please Fill all the fields");
    }
  };

  return (
    <div className="container-sm text-center">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          CreateQuery();
        }}
      >
        <div className="row d-inline-flex">
          <div className="col">
            <label for="formFile" className="form-label fw-bold">
              File Upload
            </label>

            <input
              id="fileMDT"
              className="form-control upload_file"
              type="file"
              name="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </div>
        </div>
        <div className="text-start d-flex justify-content-center">
          <div className="ms-2 me-2 mt-2 mb-1 ">
            <label style={{ fontSize: "14px" }}>
              <strong>File Type</strong>
            </label>
            <Select
              className="basic-single"
              classNamePrefix="Type"
              placeholder="Type"
              value={type}
              name="color"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: "100px",
                }),
              }}
              options={[
                {
                  label: "PPV",
                  value: "PPV",
                },
                {
                  label: "PQV",
                  value: "PQV",
                }
              ]}
              onChange={setType}
            />
          </div>
          <div className="ms-2 me-2 mt-2 mb-1 ">
            <label style={{ fontSize: "14px" }}>
              <strong>Select Store</strong>
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="color"
              placeholder="Store"
              value={store}
              options={options}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: "230px",
                }),
              }}
              onChange={setStore}
            />
          </div>
        </div>

        <div className="col">
          <button
            className="btn btn-outline-danger  mt-2 mb-3"
            type="submit"
            disabled={loader}
          >
            <span className="me-1">
              {loader ? <ClipLoader size={16} /> : <FaCloudUploadAlt />}
            </span>
            Start Disputing
          </button>
        </div>
        <br></br>
        <br></br>
      </form>
    </div>
  );
};

export default CreateShortageDispute;
