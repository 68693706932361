type Status = "queued" | "success" | "fail" | "in progress";

export const getColorByAuditStatus = (status?: Status): string => {
  const colorMap = {
    queued: "#FFD700",
    success: "#39D4A5",
    fail: "#FF0000",
    "in progress": "#FFA500",
  };

  return status ? colorMap[status] : "transparent";
};
