import "./style.css";

import { type FC, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/theme.ts";
import WorkPacketsGrid from "./WorkPacketsGrid/index.tsx";
import { WorkPacketsManager } from "./WorkPacketsManager/index.tsx";
import usePermissions from "components/CustomHooks/usePermissions.js";
import CommandCenter from "./CommandCenter/index.tsx";
import { useWorkPacketsContext, WorkPacketsContext } from "./WorkPacketsContext.tsx";

interface OuterProps {
  setTriggerRefetch: (fn: (prev: boolean) => boolean) => void;
}

const WorkPacketsGridOuter: FC<OuterProps> = ({ setTriggerRefetch }) => {
  const { currentWorkPacketType } = useWorkPacketsContext();
  return (
    <WorkPacketsGrid workPacketType={currentWorkPacketType} onDataUpdate={() => setTriggerRefetch(prev => !prev)} />
  );
};

export const WorkPackets: FC = () => {
  const { isAdmin, permissions } = usePermissions();

  /*
   * Used to communicate between WorkPacketsManager and WorkPacketsGrid that are on the same level.
   * Flag value doesn't matter, any change will trigger a Summary data refetch.
   */
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  if (!permissions || !permissions.length) return null;

  return (
    <ThemeProvider theme={theme}>
      <WorkPacketsContext>
        <CommandCenter />
        <WorkPacketsManager isAdmin={isAdmin} refetch={triggerRefetch} />
        <WorkPacketsGridOuter setTriggerRefetch={setTriggerRefetch} />
      </WorkPacketsContext>
    </ThemeProvider>
  );
};
