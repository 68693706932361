import { createContext, useContext, useMemo, useState, type FC, type ReactNode } from "react";
import type { User } from "src/queries/users";

export type DialogState = { mode: "closed", user?: undefined }
  | { mode: "add", user?: undefined }
  | { mode: "edit", user: User };

export interface UserAddEditDialogStore {
  dialogState: DialogState;
  setDialogState: (state: DialogState) => void;
}

const defaultStore: UserAddEditDialogStore = { dialogState: { mode: "closed" }, setDialogState: () => void 0 };
export const UserAddEditDialogContext = createContext<UserAddEditDialogStore>(defaultStore);

export const UserAddEditDialogContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [dialogState, setDialogState] = useState<DialogState>({ mode: "closed" });
  const value = useMemo(() => ({ dialogState, setDialogState }), [dialogState, setDialogState]);
  const Provider = UserAddEditDialogContext.Provider;
  return <Provider value={value} children={children} />
};

export const useUserAddEditDialogStore = () => useContext(UserAddEditDialogContext);
