import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3437e996b7aeae05a94d8e0e178bbe9f@o4507469009256448.ingest.us.sentry.io/4507470655848448",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Performance monitoring
  tracesSampleRate: 1.0,

  // Session replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
