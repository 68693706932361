import { ThemeProvider } from "@mui/material/styles";
import { useContext, useEffect } from "react";
import theme from "src/styles/theme";

import { MyContext } from "src/App";
import { OssrCreateQuery } from "./OssrCreateQuery";
import { OssrTable } from "./OssrTable";

export const OssrPage = () => {
  useEffect(() => {
    document.title = "Charge Guard | OSSR";
  }, []);

  const { vendorID } = useContext(MyContext);

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1>{vendorID}</h1>
        <OssrCreateQuery />
        <div className="container-sm">
          <OssrTable />
        </div>
      </div>
    </ThemeProvider>
  );
};
