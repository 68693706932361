import { ThemeProvider } from "@mui/material/styles";
import { type FC } from "react";
import theme from "../../../styles/theme";
import { UserAddEditDialogContextProvider } from "./UserAddEditDialog/UserAddEditDialogContext";
import { UsersGrid } from "./UsersGrid/UsersGrid";

export const UsersPage: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserAddEditDialogContextProvider>
        <UsersGrid />
      </UserAddEditDialogContextProvider>
    </ThemeProvider>
  );
};
