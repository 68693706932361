const formatNumber = (number) => {
  // round a number to two decimal places
  const roundedNumber = Number(number?.toFixed(2));

  // Check if the rounded number has fractional part
  const hasFractionalPart = roundedNumber % 1 !== 0;

  return hasFractionalPart
  ? roundedNumber.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  : roundedNumber.toLocaleString("en-US");
};


export default formatNumber;
