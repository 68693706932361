import { toast } from "react-hot-toast";
import TotalServices from "../../TotalServices";
import moment from "moment";

const useDownloadNew = () => {
  const DownloadFileNew = async (type, id, setFileLoader, store, date) => {
    setFileLoader(true);
    try {
      let response = await TotalServices.UserDownlaodQueryNew(type, id);

      if (response.status === 200) {
        var a = document.createElement("a");
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        a.href = downloadUrl;
        let fileName = store + "_" + moment(date).format("YYYY-MM-DD");
        a.setAttribute("download", fileName + ".xlsx");
        document.body.appendChild(a);

        a.click();
        setFileLoader(false);
      }
    } catch (error) {
      setFileLoader(false);
      toast.error("Can't be able to download file");
    }
  };
  return { DownloadFileNew };
};

export default useDownloadNew;
