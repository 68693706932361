export const shortageProcessingOptions = [
  { label: "iNymbus", value: "iNymbus" },
  { label: "CG-In-process", value: "CG-In-process" },
  { label: "Not Started", value: "Not Started" },
  { label: "CG-In Queue", value: "CG-In Queue" },
  { label: "Terminated", value: "Terminated" },
  { label: "Multiple", value: "Multiple" },
  { label: "Need Permissions", value: "Need Permissions" },
  { label: "Not Needed-Historic Only", value: "Not Needed-Historic Only" },
  {
    label: "CG-Trigger Live, CG-Trigger Live",
    value: "CG-Trigger Live, CG-Trigger Live",
  },
  { label: "CG-Trigger Live", value: "CG-Trigger Live" },
  {
    label: "CG-Trigger Live, CG-Trigger Live, CG-Trigger Live, CG-Trigger Live",
    value: "CG-Trigger Live, CG-Trigger Live, CG-Trigger Live, CG-Trigger Live",
  },
  {
    label: "CG-Trigger Live, CG-In Queue",
    value: "CG-Trigger Live, CG-In Queue",
  },
];

export const dataPullStatusOptions = [
  { label: "N/A", value: "N/A" },
  { label: "Data Pull Requested", value: "Data Pull Requested" },
  { label: "Data Pull Complete", value: "Data Pull Complete" },
  { label: "Client Configuration", value: "Client Configuration" },
  { label: "CGI Dashboard Ready", value: "CGI Dashboard Ready" },
  { label: "CGI Org Complete", value: "CGI Org Complete" },
  {
    label: "(Historic)Client Configuration Complete",
    value: "(Historic)Client Configuration Complete",
  },
  {
    label: "(WL) Client Configuration Complete",
    value: "(WL) Client Configuration Complete",
  },
];

export const categoryOptions = [
  { label: "Automotive", value: "Automotive" },
  { label: "Baby", value: "Baby" },
  { label: "Beauty", value: "Beauty" },
  { label: "Books", value: "Books" },
  { label: "Cell Phones & Accessories", value: "Cell Phones & Accessories" },
  { label: "Clothing, Shoes & Jewelry", value: "Clothing, Shoes & Jewelry" },
  { label: "Electronics", value: "Electronics" },
  { label: "Grocery", value: "Grocery" },
  { label: "Health & Household", value: "Health & Household" },
  { label: "Home & Kitchen", value: "Home & Kitchen" },
  { label: "HPC", value: "HPC" },
  { label: "Industrial & Scientific", value: "Industrial & Scientific" },
  { label: "Kitchen & Dining", value: "Kitchen & Dining" },
  { label: "Musical Instruments", value: "Musical Instruments" },
  { label: "n/a", value: "n/a" },
  { label: "Office", value: "Office" },
  { label: "Office Products", value: "Office Products" },
  { label: "Patio, Lawn & Garden", value: "Patio, Lawn & Garden" },
  { label: "Pet", value: "Pet" },
  { label: "Pet Supplies", value: "Pet Supplies" },
  { label: "Sports & Outdoor", value: "Sports & Outdoor" },
  { label: "Tools & Home Improvement", value: "Tools & Home Improvement" },
  { label: "Toys & Games", value: "Toys & Games" },
];

export const cadenceOptions = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Daily", value: "Daily" },
];

export const accountStatuses = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Retired", value: "retired" },
];
