import ClientConfigServices from "../../../../../Services/ClientConfigServices";

const GetAccountsData = async (
  setTableData,
  searchQuery,
  setLoader,
  vendorId
) => {
  try {
    setLoader(true);
    let response = await ClientConfigServices.GetVendorAccounts(vendorId,
      { query: "" }
    );
    if (response.status === 200) {
      setTableData(response?.data.accounts);
      setLoader(false);
    }
  } catch (e) {
    if(e.response.status === 404)
    {
        setTableData([]);
    }
    
    setLoader(false);
    
  }
};

const addAccounts = async (inputData, vendorID ,  setLoader, toast, getData, resetForm , handleShowAccountsForm) => {
  try {
    setLoader(true);
    let response = await ClientConfigServices.addAccounts(vendorID , inputData);
    if (response.status === 200) {
      toast.success(response.data.message);
      setLoader(false);
      resetForm();
      handleShowAccountsForm();
      getData();
    }
  } catch (e) {
    setLoader(false);
  }
};

const UpdateAccounts = async (inputData, VendorID , AccountsID , setLoader, toast, getData) => {
    try {
      setLoader(true);
      let response = await ClientConfigServices.editAccounts(VendorID, AccountsID , inputData);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoader(false);
        getData();
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const DeleteAccounts = async (VendorID , AccountsID , setLoader, toast, getData) => {
    try {
      setLoader(true);
      let response = await ClientConfigServices.deleteAccounts(VendorID, AccountsID);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoader(false);
        getData();
      }
    } catch (e) {
      setLoader(false);
    }
  };

export { GetAccountsData, addAccounts , UpdateAccounts , DeleteAccounts };
