import { TextField } from "@mui/material";
import { Field } from "formik";
import { FC } from "react";
import { FormFieldsGroup } from "../components";

export const DetailsWorkPacketInfo: FC = () => {
  return (
    <FormFieldsGroup
      title="Work Packet info"
      withDivider
      id="details-work-packet-info"
    >
      <Field
        name="chargebackIssueId"
        as={TextField}
        variant="outlined"
        placeholder="Chargeback Issue ID"
        size="small"
        label="Chargeback Issue ID"
        disabled
      />
    </FormFieldsGroup>
  );
};
