import { Autocomplete, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FieldProps } from "formik";

interface AutocompleteFieldProps extends FieldProps {
  id: string;
  label: string;
  options: any[];
  groupBy?: (option: any) => string;
  getOptionLabel: (option: any) => string;
  placeholder: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  disableClearable?: boolean;
}

/**
 * AutocompleteField component integrates Material-UI's Autocomplete with Formik.
 *
 * @param {string} id - The unique identifier for the autocomplete field.
 * @param {string} label - The label for the autocomplete field.
 * @param {Array<any>} options - The options for the autocomplete dropdown.
 * @param {Function} [groupBy] - Optional function to group options.
 * @param {Function} getOptionLabel - Function to get the label of an option.
 * @param {string} placeholder - Placeholder text for the input field.
 * @param {Function} setFieldValue - Formik's function to set the field value.
 * @param {FieldProps} field - Formik field prop, automatically injected by Formik.
 */
const AutocompleteField: FC<AutocompleteFieldProps> = ({
  id,
  label,
  options,
  groupBy,
  getOptionLabel,
  placeholder,
  setFieldValue,
  field,
  disabled,
  onChange,
  disableClearable,
}) => {
  const [inputValue, setInputValue] = useState(field.value || "");

  // Sync input value with Formik's field value
  useEffect(() => {
    const option = options.find((option) => option.value === field.value);
    setInputValue(
      option
        ? option.title ?? option.name
        : field.value
          ? String(field.value)
          : ""
    );
  }, [field.value]);

  return (
    <Autocomplete
      id={id}
      options={groupBy ? groupAutocompleteOptions(options) : options}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      size="small"
      value={options.find((option) => option.value === field.value) || null}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          label={label}
        />
      )}
      onChange={(_, valueObject) => {
        setFieldValue(field.name, valueObject ? String(valueObject.value) : "");
        if (onChange) {
          onChange(valueObject);
        }
      }}
      renderOption={(props, option) => (
        <li {...props} className={`autocomplete-menu-item ${props.className}`} key={props.key}>
          {option?.color && (
            <span
              className="autocomplete-menu-item__color-indicator"
              style={{ background: option.color }}
            />
          )}
          {option.avatar && (
            <img
              src={option.avatar}
              alt={option.title}
              className="autocomplete-menu-item__avatar"
            />
          )}
          <span className="autocomplete-menu-item__text">
            {option.title ?? option.name}
          </span>
        </li>
      )}
      disabled={disabled}
      disableClearable={disableClearable}
    />
  );
};

const groupAutocompleteOptions = (options: any[]) => {
  return options.map((option) => {
    const firstLetter = option.category[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};

export default AutocompleteField;
