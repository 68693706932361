import Card from "../../../components/Cards/Card";
import TotalServices from "../../../TotalServices";
import { useState, useEffect } from "react";
const MDTAnalytics = () => {
  const [cardsData, setcardsData] = useState([]);
  const [loader, setLoader] = useState(true);
  // MDTFilter
  const [filterVal, setFilterVal] = useState("All");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const format = new Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 2,
  });

  const GetMDTAnalyticsData = async () => {
    setLoader(false);
    try {
      let response = await TotalServices.GetMDTAnalytics({ filter: filterVal });
      if (response.status === 200) {
        let cardData = [];
        cardData.push(
          {
            name: "Total Disputes",
            value: `${format.format(response.data.card[0]["total_disputes"])}`,
          },
          {
            name: "Total Disputed Amount",
            value: `${formatter.format(
              response.data.card[0]["sum_of_financialCharge"]
            )}`,
          },
          {
            name: "Failed Cases",
            value: response.data.card[0]["no_failed_cases"],
          }
        );

        setcardsData(cardData);

        setLoader(true);
      }
    } catch (e) {
    }
  };

  useEffect(() => {
    GetMDTAnalyticsData();
  }, [filterVal]);

  return (
    <div className="container ">
      <div className="ms-2 me-2 mt-2 mb-3 d-flex justify-content-end p-2">
        <select
          onChange={(e) => {
            setFilterVal(e.target.value);
          }}
          defaultValue={"options"}
          style={{
            padding: "5px",
            border: "2px solid rgb(117 4 30)",
            borderRadius: "8px",
          }}
        >
          <option value="All">All</option>
          <option value="Day">Day</option>
          <option value="Week">Week</option>
          <option value="Month">Month</option>
        </select>
      </div>

      <div className="row">
        {cardsData &&
          cardsData.map((obj) => {
            return (
              <div className="col-md-4">
                <Card loader={loader} carddata={obj} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MDTAnalytics;
