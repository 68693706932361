import React, {useState} from "react";
import {Route} from "react-router-dom";
import usePermissions from "../components/CustomHooks/usePermissions.js";
import Error404 from "../ErrorPages/404/404.jsx";

export const PermissionGuard = ({permissions, element, require_all = false}) => {

  const { hasPermission } = usePermissions();

  const Element = element

  return hasPermission(permissions, require_all) ? <Element/> : <Error404/>;
};
