/**
 * @file This file determines whether to use a mock API or a real API for making HTTP requests.
 *
 * Note: The current configuration uses a hardcoded flag `useMockApi` to toggle between mock and real APIs.
 */

import axios from "axios";
import "./mockApi";
import { http } from "../../../../axios";

const useMockApi = false; // TODO: use process.env.USE_MOCK_API === 'true';

// TODO: Revisit after implementing the real API
const apiClient = useMockApi ? axios : http;

export default apiClient;
