const dataGrid = {
  MuiDataGrid: {
    styleOverrides: {
      root: {
        "& .MuiDataGrid-cell": {
          "&.MuiDataGrid-cell--editing": {
            padding: "1px 12px",
          },
        },

        "& .MuiDataGrid-columnHeader": {
          background: "#101828",
          color: "#fff",
          "& *": {
            color: "#fff",
          },
        },

        "& .MuiDataGrid-headerFilterRow": {
          ".MuiDataGrid-columnHeader": {
            background: "#F9FAFB",
            "& *": {
              color: "#101828",
            },
            ".MuiFormControl-root": {
              width: "100%",
            },
          },
        },
      },
    },
  },
};

export default dataGrid;
