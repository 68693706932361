import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Tab, Tabs } from "@mui/material";
import { FC } from "react";
import { type WorkPacketView } from "../WorkPacketView";

interface WorkPacketsManagerProps {
  selectedTab: WorkPacketView;
  handleTabChange: (_event: React.SyntheticEvent, newValue: number) => void;
  isAdmin?: boolean;
}

export const ControlPanel: FC<WorkPacketsManagerProps> = ({
  selectedTab,
  handleTabChange,
  isAdmin,
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingX={3}
        bgcolor="#fff"
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{ paddingTop: 3.5 }}
        >
          {/* TODO: Add counters */}
          {/* {isAdmin && } */}
          <Tab label="All packets" hidden={!isAdmin} />
          <Tab label="New Packets" />
          <Tab label="My Packets" />
        </Tabs>

        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{ display: { xs: "none", md: "flex" } }}
          hidden
        >
          New
        </Button>
      </Box>

      <Divider />
    </>
  );
};
