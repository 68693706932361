import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
  fontFamily: "Inter, Roboto, sans-serif",
  // Default typography
  body1: {
    fontSize: 16,
  },
  body2: {
    fontSize: 14,
  },
  h2: {
    fontSize: 30,
    fontWeight: 600,
  },
  h3: {
    fontSize: 24,
    fontWeight: 500,
  },
};
