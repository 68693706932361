const SelectField = ({
  placeholder,
  name,
  handleChange,
  value,
  type,
  disabled,
  handleFocus,
  handleBlur,
  classes,
  options, // Add options prop for select element
}) => {
  return (
    <select
      className={`w-full form-select form-control outline-none p-2 border rounded-xl sm:text-md my-1 disabled:text-gray-500 ${
        classes ? classes : ""
      } `}
      name={name}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={value || ""} // Ensure value is a scalar or an empty string
      disabled={disabled}
      onChange={handleChange}
    >
      {options &&
        options.map((option , index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  );
};

export default SelectField;
