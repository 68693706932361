import MaxCharacterCount from "components/Input/MaxCharacterCount";
import { Scope } from "../types";

const CHARACTERS_LIMIT = 255;

interface TextareaCellProps {
  dataValue?: string | null;
  isEditableRow: boolean;
  formik: any;
  handleChange: (field: keyof Scope, value: any) => void;
  name: keyof Scope;
}

const TextareaCell = ({
  dataValue,
  isEditableRow,
  formik,
  handleChange,
  name,
}: TextareaCellProps) => {
  if (!isEditableRow) return <td>{dataValue || "N/A"}</td>;

  return (
    <td>
      <div>
        <textarea
          className="form-control"
          maxLength={CHARACTERS_LIMIT}
          name={name}
          value={formik.values[name]}
          onChange={(e) => handleChange(name, e.target.value)}
        />

        <MaxCharacterCount
          characterCount={formik.values[name]?.length || 0}
          maxNotesCharacters={CHARACTERS_LIMIT}
        />
      </div>
    </td>
  );
};

export default TextareaCell;
