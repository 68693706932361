import useShortageDownload from "components/CustomHooks/useShortageDownload";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const urlsMap: Record<string, string> = {
  "/user-dashboard": "User Dashboard",
  "/user-dashboard/ossr": "OSSR",
  "/user-dashboard/cb-dispute": "CB Dispute",
  "/user-dashboard/massdispute": "Dispute Tool",
  "/user-dashboard/usageHistory": "Usage History",
  "/user-dashboard/disputephrases": "Manage Phrase",
  "/user-dashboard/shortage-dispute": "Shortage Dispute",
  "/user-dashboard/invoicing-automation": "Invoicing Automation",
  "/user-dashboard/audits": "Audits",
  "/user-dashboard/customers": "Customers",
  "/user-dashboard/obar/view": "OBAR",
  "/user-dashboard/work-packets": "Work Packets",
  "/user-dashboard/users": "Users",
};

const useCurrentPage = () => {
  const location = useLocation();
  const [_, setFileLoader] = useState(false);
  const { DownlaodFile } = useShortageDownload();

  const currentPage = urlsMap[location.pathname] || "";

  const handleFileDownload = () => {
    const fileId = currentPage === "Shortage Dispute" ? 260 : 51;
    DownlaodFile("Sample", fileId, setFileLoader, "Sample File");
  };

  return {
    currentPage,
    showFileDownloadButton: ["Dispute Tool", "Shortage Dispute"].includes(
      currentPage
    ),
    onFileDownload: handleFileDownload,
  };
};

export default useCurrentPage;
