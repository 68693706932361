const textField = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: "14px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: "14px",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "14px",
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiAutocomplete-root": {
          width: "100%",
          margin: "auto",
          display: "flex",
          padding: "0 12px",
        },

        "& .Mui-disabled": {
          input: {
            // backgroundColor: "#F2F4F7",
            // color: "#98A2B3",
          },

          ".MuiOutlinedInput-notchedOutline": {
            // borderColor: "#F2F4F7",
          },
        },
      },
    },
  },
};

export default textField;
