import React from "react";
import { Table } from "react-bootstrap";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useState } from "react";

const ModalTabTable = ({activeTabKey, tableData, DownlaodFile,setFileLoader}) => {

  // const [fileLoader,setFileLoader]=useState(false)

  return (
    <>
      <Table responsive key={activeTabKey}>
        <thead>
          <tr>
            <th>Store Name</th>
            <th>Total</th>
            <th>Completed</th>
            <th>Failed</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData.map((obj, index) => {
              return (
                <>
                  <tr key={index}>
                    <td>{obj.storeName}</td>
                    <td>{obj.total}</td>
                    <td className="text-success">{obj.completed}</td>
                    <td className="text-danger">{obj.failed}</td>
                    <td>
                      <button
                        className="btn btn-success"
                        onClick={(e) => {
                          e.preventDefault();
                          DownlaodFile(obj.id, activeTabKey ,  setFileLoader, obj.storeName)
                        }}
                      >
                        <FaCloudDownloadAlt></FaCloudDownloadAlt> Download
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default ModalTabTable;
