import { Box, Skeleton, Typography } from "@mui/material";
import { FC } from "react";
import { WorkPacketMetric } from "src/types/work-packets";

interface MetricProps {
  name: WorkPacketMetric;
  value: number;
  loading?: boolean;
}

export const MetricRowCard: FC<MetricProps> = ({ name, value, loading }) => {
  const withDays = [
    WorkPacketMetric.AverageTimePacketOpen,
    WorkPacketMetric.AverageTimeUnassigned,
    WorkPacketMetric.AvgTimePacketOpen,
  ];
  const additionalText = withDays.includes(name)
    ? value > 1
      ? "days"
      : "day"
    : "";

  const formatNumber = (value: number) => {
    return `${new Intl.NumberFormat("en-US", {}).format(value)}`;
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
      paddingX={2}
      paddingY={1.5}
      borderRadius={2}
      border="1px solid #D0D5DD"
      flex={1}
    >
      <Typography color="secondary.light">{name}</Typography>

      {loading ? (
        <Skeleton variant="text" sx={{ fontSize: "24px", flex: 0.5 }} />
      ) : (
        <Typography variant="h3">
          {formatNumber(value)}
          {additionalText && (
            <Typography component="span" variant="body1">
              {` ${additionalText}`}
            </Typography>
          )}
        </Typography>
      )}
    </Box>
  );
};
