import {
  WorkPacketAction,
  WorkPacketActionName,
  WorkPacketFilter,
} from "src/types/work-packets";
import { WorkPacketType } from "../../WorkPacketType";

const recoveryStreamOptions = [
  { value: "", label: "All" },
  { value: "INVENTORY_SHORTAGES", label: "Inventory shortages" },
  { value: "PRICE_CLAIMS", label: "Price Claims" },
  { value: "ACCRUALS", label: "Accruals" },
  { value: "CHARGEBACKS", label: "Chargebacks" },
];

const packetStageOptions = [
  { value: "", label: "All" },
  { value: "Identify", label: "Identify" },
  { value: "Trigger", label: "Trigger" },
  { value: "Escalate", label: "Escalate" },
  { value: "Settle", label: "Settle" },
];

export const workPacketActions: WorkPacketAction[] = [
  /** First Dispute */
  {
    value: WorkPacketActionName.FirstDisputeIdentified,
    title: "First Dispute Identified",
    category: "First Dispute",
    color: "#ffed93",
  },
  {
    value: WorkPacketActionName.FirstDisputeFailed,
    title: "First Dispute Failed",
    category: "First Dispute",
    color: "#ff0000",
  },
  {
    value: WorkPacketActionName.FirstDisputeCreatedInReview,
    title: "First Dispute Created",
    category: "First Dispute",
    color: "#FFCD4D",
  },
  {
    value: WorkPacketActionName.FirstDisputeApproved,
    title: "First Dispute Resolved - Approved",
    category: "First Dispute",
    color: "#39D4A5",
  },
  {
    value: WorkPacketActionName.FirstDisputePartiallyApproved,
    title: "First Dispute Resolved - Partially Approved",
    category: "First Dispute",
    color: "#318FFF",
  },
  {
    value: WorkPacketActionName.FirstDisputeDenied,
    title: "First Dispute Resolved - Denied/More Info Needed",
    category: "First Dispute",
    color: "#FF820F",
  },
  {
    value: WorkPacketActionName.FirstDisputePaid,
    title: "First Dispute - VC Client Payment",
    category: "First Dispute",
    color: "#7B3DFF",
  },
  {
    value: WorkPacketActionName.FirstDisputeInvoiced,
    title: "First Dispute - C6 Invoices Client",
    category: "First Dispute",
    color: "#F155FF",
  },

  /** Second Dispute */
  {
    value: WorkPacketActionName.SecondDisputeIdentified,
    title: "Second Dispute Identified",
    category: "Second Dispute",
    color: "#ffed93",
  },
  {
    value: WorkPacketActionName.SecondDisputeFailed,
    title: "Second Dispute Failed",
    category: "Second Dispute",
    color: "#ff0000",
  },
  {
    value: WorkPacketActionName.SecondDisputeCreatedInReview,
    title: "Second Dispute Created",
    category: "Second Dispute",
    color: "#FFCD4D",
  },
  {
    value: WorkPacketActionName.SecondDisputeApproved,
    title: "Second Dispute Resolved - Approved",
    category: "Second Dispute",
    color: "#39D4A5",
  },
  {
    value: WorkPacketActionName.SecondDisputePartiallyApproved,
    title: "Second Dispute Resolved - Partially Approved",
    category: "Second Dispute",
    color: "#318FFF",
  },
  {
    value: WorkPacketActionName.SecondDisputeDenied,
    title: "Second Dispute Resolved - Denied",
    category: "Second Dispute",
    color: "#FF820F",
  },
  {
    value: WorkPacketActionName.SecondDisputePaid,
    title: "Second Dispute - VC Client Payment",
    category: "Second Dispute",
    color: "#7B3DFF",
  },
  {
    value: WorkPacketActionName.SecondDisputeInvoiced,
    title: "Second Dispute - C6 Invoices Client",
    category: "Second Dispute",
    color: "#F155FF",
  },

  /** Other Actions */
  {
    value: WorkPacketActionName.Junk,
    title: "Junk",
    category: "Other Actions",
    color: "#E03741",
  },
  {
    value: WorkPacketActionName.Duplicate,
    title: "Duplicate",
    category: "Other Actions",
    color: "#EAECF0",
  },
];

const chargebackWorkPacketActionsEditableKeys = [
  WorkPacketActionName.FirstDisputeCreatedInReview,
  WorkPacketActionName.FirstDisputeInvoiced,
  // TODO: Add Not Pursuing
  WorkPacketActionName.SecondDisputeCreatedInReview,
  WorkPacketActionName.SecondDisputeInvoiced,
  WorkPacketActionName.Junk,
  WorkPacketActionName.Duplicate,
];

const shortageWorkPacketActionsEditableKeys = [
  WorkPacketActionName.FirstDisputeCreatedInReview,
  WorkPacketActionName.FirstDisputeInvoiced,
  WorkPacketActionName.Junk,
  WorkPacketActionName.Duplicate,
];

/**
 * Used withing the WorkPacketsGrid to filter out the actions that are editable
 */
export const workPacketActionsEditable: Record<WorkPacketType, WorkPacketAction[]> = {
  [WorkPacketType.CHARGEBACKS]: workPacketActions.filter(action =>
    chargebackWorkPacketActionsEditableKeys.includes(action.value),
  ),
  [WorkPacketType.SHORTAGES]: workPacketActions.filter(action =>
    shortageWorkPacketActionsEditableKeys.includes(action.value),
  ),
};

const staticFilterOptions = {
  [WorkPacketFilter.RecoveryStream]: recoveryStreamOptions,
  [WorkPacketFilter.PacketStage]: packetStageOptions,
  [WorkPacketFilter.CurrentAction]: workPacketActions,
};

export { staticFilterOptions };
