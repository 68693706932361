import Image404 from "../404/404.png"
import "./404.css";
import { useNavigate } from "react-router-dom";
const Error404 = () => {

    let navigate = useNavigate();

    const redirect = () =>
    {
        navigate("/user-dashboard");
    }


    return ( 

        <>
    <div className="d-flex align-items-center justify-content-center vh-99">
    <img className="Error404" src={Image404} alt="" />
    </div>
    <div className="flex-container">
  <button className="btn-primary mt-2 mb-2" type="submit"
  
  onClick = {redirect}
  >
    Go BACK Home
  </button>
  </div>
   
    </>

     );
}
 
export default Error404;