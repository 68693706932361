import { useMutation, useQuery } from "@tanstack/react-query";
import { type FC, useCallback, useId, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { FiEdit } from "react-icons/fi";
import { LuTrash2 } from "react-icons/lu";
import { http } from "src/axios";
import { getParsedPermissionsList, getPermissionsList, getUsersWithPermissions, type User, type UserPermission } from "src/queries/users";
import { queryClient } from "src/query-client";
import { ADMIN_PERMISSION, getPermissionFeatureLabel, parseUserPermissions } from "../UserAddEditDialog/permission-parser";

import { Add, ErrorOutlineRounded, KeyboardArrowDown, KeyboardArrowUp, SearchOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, InputAdornment, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { DataGridPro, type GridColDef, type GridRenderCellParams, type GridSortModel, GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid-pro";

import { UserAddEditDialog } from "../UserAddEditDialog/UserAddEditDialog";
import { useUserAddEditDialogStore } from "../UserAddEditDialog/UserAddEditDialogContext";

import css from "./UsersGrid.module.css";

const EMPTY_USERS_ARRAY: readonly User[] = [];

const onUserDeleteSuccess = async () => {
  await queryClient.invalidateQueries({ queryKey: ["users"] });
  return toast.success("User successfully deleted");
};

const ActionMenuButton: FC<GridRenderCellParams<User>> = ({ row: user }) => {
  const store = useUserAddEditDialogStore();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const isMenuOpen = Boolean(menuAnchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setMenuAnchorEl(event.currentTarget);
  const closeMenu = () => setMenuAnchorEl(null);

  const { mutateAsync: performUserDelete, isPending: isDeleting } = useMutation({
    mutationKey: ["delete-user"],
    mutationFn: (user: User) => http.delete(`/api/v2/user/${user.ID}`),
    onSuccess: onUserDeleteSuccess,
  });

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const closeDeleteDialog = useCallback(() => !isDeleting && setDeleteDialogOpen(false), [isDeleting]);

  const onEditSelect = useCallback(() => {
    store.setDialogState({ mode: "edit", user });
    closeMenu();
  }, [store, user]);

  const onDeleteSelect = () => {
    setDeleteDialogOpen(true);
    closeMenu();
  };

  const onDeleteClick = useCallback(async () => {
      await performUserDelete(user);
      closeDeleteDialog();
  }, [closeDeleteDialog, performUserDelete, user]);

  const buttonId = useId();
  const menuId = useId();

  return (
    <div>
      <Button
        id={buttonId}
        aria-controls={isMenuOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        variant="outlined"
        color="secondary"
        disableElevation
        onClick={openMenu}
        endIcon={isMenuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        sx={{ backgroundColor: "white" }}
      >
        Action
      </Button>
      <Menu
        id={menuId}
        MenuListProps={{ "aria-labelledby": buttonId }}
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        elevation={0}
        classes={{ paper: css.actionsMenuPaper, list: css.actionsMenuList }}
      >
        <MenuItem onClick={onEditSelect} disableRipple>
          <FiEdit />
          Edit
        </MenuItem>
        <MenuItem onClick={onDeleteSelect} disableRipple className={css.deleteMenuItem}>
          <LuTrash2 />
          Delete
        </MenuItem>
      </Menu>
      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog} PaperProps={{ sx: { borderRadius: "16px", paddingBlock: "0.5rem" } }}>
        <DialogContent>
          <div className={css.deleteDialogContent}>
            <div className={css.deleteIconOuter}>
              <div className={css.deleteIconInner}>
                <ErrorOutlineRounded className={css.deleteIcon} />
              </div>
            </div>
            <div>
              <Typography fontWeight={500} marginBottom={1}>Delete user account</Typography>
              <Typography color="GrayText">Are you sure you want to delete this account?<br />This action cannot be undone.</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "transparent" }}>
          <Button onClick={closeDeleteDialog} disabled={isDeleting}>Cancel</Button>
          <Button
            color="error"
            variant="contained"
            sx={{ paddingInline: "2rem" }}
            onClick={onDeleteClick}
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting user..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const PermissionDisplay: FC<GridRenderCellParams<User>> = ({ row: user }) => {
  const { data: rawPermissionsList } = useQuery({ queryFn: getPermissionsList, queryKey: ["permissions-list"] });
  const { data: permissionsList } = useQuery({ queryFn: getParsedPermissionsList, queryKey: ["parsed-permissions-list", rawPermissionsList!], enabled: !!rawPermissionsList });
  const parsedUserPermissions = useMemo(
    () => permissionsList ? parseUserPermissions(permissionsList, user.PERMISSIONS.map(x => x.PERMISSION)) : null,
    [permissionsList, user],
  );

  const tooltipValue = useMemo(() => {
    if (!parsedUserPermissions) return "";
    return [
      ...Object.entries(parsedUserPermissions.readWrite).filter(([_permission, value]) => value !== "none").map(([permission, value]) => `${getPermissionFeatureLabel(permission)}: ${value}`),
      ...Object.entries(parsedUserPermissions.misc).filter(([_permission, value]) => value).map(([permission]) => permission),
    ].join(", ");
  }, [parsedUserPermissions]);

  if (!parsedUserPermissions) return (<span>...</span>);

  if (user.PERMISSIONS.some(p => p.PERMISSION === ADMIN_PERMISSION)) return (
    <span className={css.permissionDisplay}>
      <span className={css.adminUser}>Admin user</span>
    </span>
  );

  return (
    <Tooltip title={tooltipValue}>
      <div className={css.lineClampOuter}>
        <div className={css.lineClampInner}>
          {Object.entries(parsedUserPermissions.readWrite).map(([permission, permissionValue]) => {
            if (permissionValue === "none") return;
            return (
              <span className={css.permissionDisplay} key={permission}>
                <span className={css.permissionName}>{getPermissionFeatureLabel(permission)}</span>
                <span className={css.permissionValue}>{permissionValue}</span>
              </span>
            );
          })}
          {Object.entries(parsedUserPermissions.misc).filter(([_permission, value]) => value).map(([permission]) => (
            <span className={css.permissionDisplay} key={permission}>
              <span className={css.permissionName}>{permission}</span>
            </span>
          ))}
        </div>
      </div>
    </Tooltip>
  );
};

const columns = [
  {
    field: "NICKNAME",
    headerName: "Full Name",
    flex: 1,
  },
  {
    field: "EMAIL",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "PERMISSIONS",
    headerName: "User Permissions",
    flex: 2,
    renderCell: PermissionDisplay,
    display: "flex",
  } satisfies GridColDef<User, UserPermission[], string>,
  {
    field: "Actions",
    type: "actions",
    renderCell: ActionMenuButton,
    width: 120,
    cellClassName: css.actionsCell,
  },
] satisfies GridColDef<User>[];

const GridToolbar: FC = () => {
  const store = useUserAddEditDialogStore();
  const openDialog = () => store.setDialogState({ mode: "add" });

  return (
    <>
      <Box mb={1} display="flex" gap="1rem" alignItems="center">
        <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          placeholder="Search Table"
          sx={{ flexGrow: 1, padding: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }} />
        <Button color="primary" variant="contained" startIcon={<Add />} onClick={openDialog}>Add New User</Button>
      </Box>
      <UserAddEditDialog />
    </>
  );
};

export const UsersGrid: FC = () => {
  const apiRef = useGridApiRef();
  const query = useQuery({ queryKey: ["users"], queryFn: getUsersWithPermissions });
  const users = query.data ?? EMPTY_USERS_ARRAY;
  const isRefreshing = query.isRefetching;

  const [sortModel, onSortModelChange] = useState<GridSortModel>([{ field: "NICKNAME", sort: "asc" }]);

  return (
    <>
      <Box bgcolor="#fff" p={3}>
        <Box {...isRefreshing && { style: { opacity: 0.5 }, inert: '' }}>
          <DataGridPro
            apiRef={apiRef}
            rows={users}
            columns={columns}
            getRowId={row => row.ID}
            filterMode="client"
            sortingMode="client"
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            disableRowSelectionOnClick
            loading={query.isLoading}
            rowHeight={70}
            autoHeight
            hideFooter
            slots={{ toolbar: GridToolbar }}
            columnHeaderHeight={44}
            sx={{
              borderColor: "transparent",
              "& .MuiDataGrid-main": {
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
              },
              "& .MuiFormControl-root": {
                label: {
                  display: "none",
                },
                "& .MuiInputBase-root": {
                  marginTop: "0",
                },
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};
