import { useState, useEffect } from "react";

//Packages
import TotalServices from "../../../TotalServices";
import ShortageDisputeTable from "./ShortageDisputeTable";
import PaginationButtons from "../../../components/Pagination/PaginationButtons";
import CreateShortageDispute from "./CreateShortageDispute";
import Search from "../../../components/Search/Search";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import useShortageDownload from "../../../components/CustomHooks/useShortageDownload";
//Components

const ShortageDispute = () => {
  const {DownlaodFile} =useShortageDownload()
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");


  // Pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchRecord, setSearchRecord] = useState(0);
  const [NumberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(3);
  const [options, setOptions] = useState("");
  const [tableData,setTableData]=useState([])
  const [fileLoader,setFileLoader]=useState(false)
  useEffect(() => {
    document.title = "Charge Guard | Shortage Dispute";
  }, []);

  // Get Data
  const GetShortageDisputeData= async() => {
   
    try{
        setLoader(true)
        let response=await TotalServices.ListShotageDispute(NumberOfRecordsPerPage, (currentPage - 1) * NumberOfRecordsPerPage,
        
        {query:searchQuery})
    if(response.status===200){
     
    //  console.log(response);
       setTableData(response.data.user_queries)
          setTotalPages(response?.data?.pages);
          setTotalRecords(response?.data?.total_records);
          setSearchRecord((currentPage - 1) * NumberOfRecordsPerPage)      
         setGoto("")
          setOptions(
            response?.data?.stores?.map((value) => ({
              value: value[0],
              label: value[0],
            }))
          );
          setLoader(false)
    }
    }catch(e){
    }
          };

useEffect(()=>{
  GetShortageDisputeData()
},[currentPage])

  return (
    <div className="container">
     
    <CreateShortageDispute getData={GetShortageDisputeData} options={options} />
     
    <Search  setSearchQuery={setSearchQuery} searchQuery={searchQuery} currentPage={currentPage} setCurrentPage={setCurrentPage} GetData={GetShortageDisputeData} placeholder="Search Store"/>

      <br></br>

      <div className="container-sm table_main">
        {!loader ? (
          <>
            {tableData.length>=1 ? (
              <>
               <ShortageDisputeTable data={tableData} />
               
                <PaginationButtons
                  totalRecords={totalRecords}
                  setRecord={setSearchRecord}
                  record={searchRecord}
                  NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setGoto={setGoto}
                  goto={goto}
                  totalPages={totalPages}
                />
              </>
            ) : 
            <NoDataFound />
            }
          </>
        ) : 
        <TableLoader />}
      </div>
    </div>
  );
};

export default ShortageDispute;
